import { Route } from 'react-router-dom';
import { RoutesNames } from './RouteNames';
import LiveOrders from '@/components/orders/live-orders/LiveOrders';
import { ShopOwnerProductMenu } from '@/components/shop-owner-product-menu';
import HistoryOrders from '@/components/store/HistoryOrders';
import CouponsHOC from '@/containers/shopOwner/shop/coupons/CouponsHOC';
import Info from '@/components/store/shop/components/Info';
import RoutesWrapper from '@/components/routes-wrapper';
import { LiveOrdersRequests } from '@/components/orders/live-orders-requests/LiveOrdersRequests';

const OwnerRoutes = () => {
	return (
		<>
			<RoutesWrapper withLiveOrders>
				<Route index path={RoutesNames.LIVE_ORDERS} element={<LiveOrders />} />
				<Route path={RoutesNames.CATALOG} element={<ShopOwnerProductMenu />} />
				<Route path={RoutesNames.HISTORY} element={<HistoryOrders />} />
				<Route path={RoutesNames.COUPONS} element={<CouponsHOC />} />
				<Route path={RoutesNames.MY_SHOP} element={<Info />} />
			</RoutesWrapper>
			<LiveOrdersRequests />
		</>
	);
};

export default OwnerRoutes;
