import React, { useEffect, useRef, useState } from 'react';
import LoginForm from '../../components/login/LoginForm';
import { useNavigate } from 'react-router-dom';
import { toast } from '@/utils/toast';
import { jwtDecode } from 'jwt-decode';
import { useMainStore } from '@/store/main.store';
import { getGlobal, postGlobalNoAuth } from '@/utils/http';
import { RoutesNames } from '@/router/RouteNames';
import { useApplyPadding } from '@/utils/hooks/useApplyPadding';

const LoginHOC = () => {
	useApplyPadding({ type: 'login' });

	const navigate = useNavigate();
	const [loginForm, setLoginForm] = useState({ userName: '', password: '' });
	// const { user } = useSelector(state => state);

	const { user, setUser } = useMainStore();
	// const dispatch = useDispatch();
	const savingRef = useRef(false);

	useEffect(() => {
		if (user?.token) {
			navigate('/');
		}
	}, [user]);

	const onFinish = async () => {
		// dispatch(clearPersistedState());
		if (savingRef.current) {
			return;
		}
		const hideLoading = () => (savingRef.current = false);

		try {
			toast.promise(
				async () => {
					savingRef.current = true;

					const res = await postGlobalNoAuth('/auth/login', {
						email: loginForm.userName,
						password: loginForm.password,
					});
					if (!res) {
						toast.error('generic');
						return;
					}
					// const expirationTime = Date.now() + res.data.expires * 1000;

					// TODO DEN THELOUME 2 DISPATCH GIATI GAMIETAI KAI KANEI FLICKER
					const jwtDecoded = jwtDecode(res.data?.token); // OR REQUEST OBJECT WITH TOKEN + USER INSTEAD
					const { sub, exp, role } = jwtDecoded || {};

					// dispatch(
					setUser({
						id: sub,
						token: res.data.token,
						refreshToken: res.data.refreshToken,
						role,
						exp,
					});
					// );

					// setTimeout(async () => {
					// try {
					const response = await getGlobal('/my');
					// dispatch(
					setUser({
						id: sub,
						token: res.data.token,
						refreshToken: res.data.refreshToken,
						role,
						exp,
						...response.data,
					});
					hideLoading();
					// );
					// itan ekso ap tin setTimeout
					// setTimeout(() => {
					navigate(RoutesNames[role === 0 ? 'SHOPS' : 'LIVE_ORDERS']);
					// }, 2000);
					// } catch (err) {
					// 	console.log('login 2 err', err);
					// 	toast.error(err);
					// }
				},
				{
					errorCb: hideLoading,
					loading: 'Γίνεται σύνδεση...',
					success: 'Συνδεθήκατε επιτυχώς',
				},
			);
		} catch (err) {
			toast.error(err);
			console.log('login err', err);
		}

		// }, 500);
		// } catch (err) {
		// 	console.log('login 1 err', err);

		// 	toast.error(err);
		// }
	};
	return <LoginForm setLoginForm={setLoginForm} loginForm={loginForm} onFinish={onFinish} />;
};

export default LoginHOC;
