import React from 'react';
import CityForm from './CityForm';
import { MyDialog } from '../UI/MyDialog';
import { NewCitiesTable } from '@/components/tables/NewCitiesTable';

const CityView = ({
	data,
	setPageNumber,
	pageSize,
	setPageSize,
	totalPages,
	onFinish,
	open,
	setOpen,
	item,
	setItem,
	onOpenNew,
	isError,
	isLoading,
}) => {
	// const { user } = useSelector(state => state);
	return (
		<div className='flex flex-col'>
			<NewCitiesTable
				data={data}
				isError={isError}
				isLoading={isLoading}
				onOpenNew={onOpenNew}
				onRowClick={row => {
					setOpen(true);
					setItem(row.original);
				}}
			/>

			<MyDialog
				open={open}
				title='Πόλη'
				description={item?.id ? `ID: ${item?.id}` : undefined}
				onOpenChange={setOpen}
				onSubmit={onFinish}
				submitText={item?.id ? undefined : 'Προσθήκη'}
			>
				<CityForm item={item} setItem={setItem} />
			</MyDialog>
		</div>
	);
};

export default CityView;
