import axios from 'axios';
import { toast } from './toast';
import { useMainStore } from '@/store/main.store';
import { jwtDecode } from 'jwt-decode';
import { logout } from './logout';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const axiosInstance = axios.create({
	baseURL: import.meta.env.VITE_BASE_URL + 'api/v1.0',
	timeout: 7000,
});

axiosInstance.interceptors.request.use(
	config => {
		if (
			config.url.startsWith('/item-categories') ||
			config.url.startsWith('/items') ||
			config.url.startsWith('/modifiers') ||
			config.url.startsWith('/modifier-options')
		) {
			toast.error('AAA PANAGIA MOU ' + config.url);
		}

		return config;
	},
	error => {
		console.log('le status', error?.response?.status);
		if (error?.response?.status === 401) {
			toast.error('401 tha ekana logout');

			console.log('NAIIIIII');
			console.log('NAIIIIII');
			console.log('NAIIIIII');
			console.log('NAIIIIII');
			console.log('NAIIIIII');
			console.log('NAIIIIII');
			console.log('error before logout: ', error);
			logout(2);
		}
		return Promise.reject(error);
	},
);

const refresh = async args => {
	console.log('parto edo to refresh', args.token);
	// let res, data;
	// const now = new Date();
	try {
		return await axiosInstance.post('/auth/refresh-token', args);
		// toast.success(`will refresh ${now.getSeconds()}.${now.getMilliseconds()}`);
	} catch (err) {
		// toast.error('refresh problem');
		console.log(err);
	}
};

export const checkToken = async (url, shouldSkipLogout) => {
	try {
		const { user, setUser, isRefreshing, setIsRefreshing } = useMainStore.getState();

		const { token, exp, refreshToken } = user || {};

		if (!token) {
			console.log('noooo token');
			return null;
		}

		const now = Date.now();

		// console.log(now, exp, now < exp);
		if (now < exp * 1000 - 2 * 60 * 1000) {
			return token;
		}

		const refreshDecoded = jwtDecode(refreshToken);
		const { exp: expRefresh } = refreshDecoded || {};
		if (now > expRefresh * 1000 - 3 * 1000) {
			console.log('expRefresh', expRefresh, now);
			// 3 seconds gia to case to na min prolavoume
			if (!shouldSkipLogout) {
				logout(3);
			}
			return;
		}

		let c = 0;
		while (isRefreshing) {
			const now = new Date();
			const txt = `waiting ${now.getSeconds()}.${now.getMilliseconds()} ${url}`;
			// toast.blank(txt);
			console.log(txt);
			await sleep(1000);
			const tokenStoreRightNow = useMainStore.getState().user?.token;
			if (tokenStoreRightNow !== token) {
				return tokenStoreRightNow;
			}
			c++;
			if (c === 3) {
				console.log('breaking', ' --', url);
				break;
			}
		}

		setIsRefreshing(true);
		const res = await refresh({ token: refreshToken });

		console.log('RESSS REFRESH CHECK', res);
		const { token: newToken, refreshToken: newRefreshToken } = res?.data || {};
		if (!newToken) {
			console.log('refresh token problemo', res);
			// TODO 4/12/24 edo mallon iparxei themataki
			// mallon (??) mpikame me to idio account me alex kai gamithike to refresh kapos
			// na test na kanoume login mazi na doume to behaviour

			// TODO 20/3/25 TO KANO COMMENT TO LOGUT
			// TODO 20/3/25 TO KANO COMMENT TO LOGUT
			// TODO 20/3/25 TO KANO COMMENT TO LOGUT
			// TODO 20/3/25 TO KANO COMMENT TO LOGUT
			// TODO 20/3/25 TO KANO COMMENT TO LOGUT
			if (!shouldSkipLogout) {
				logout(4);
			}
			setIsRefreshing(false);

			return;
		}

		const jwtDecoded = jwtDecode(newToken);

		const { sub, exp: newExp, role } = jwtDecoded || {};

		setUser({
			...user, // to vazo auto giati sto login exoume kai to get /my
			id: sub,
			token: newToken,
			refreshToken: newRefreshToken,
			role,
			exp: newExp,
		});
		setIsRefreshing(false);
		// console.log('check ok',newToken);

		return newToken;
	} catch (err) {
		toast.error('CHECK TOKEN ERR');
		console.log('checkToken err: ', err);
	}
};

export const postGlobalNoAuth = (url, data, config = {}) => axiosInstance.post(url, data, config);

export const get = async (url, params = {}, config = {}, shouldSkipLogout = false) => {
	const token = await checkToken(url, shouldSkipLogout);
	if (!token) {
		console.log('logaro sto throw', useMainStore.getState());
		logout();
		throw new Error('Unauth');
	}

	const { user: { role } = {}, selectedShopID } = useMainStore.getState();

	return axiosInstance.get(
		role === 0 ? `/shops/${selectedShopID}${url}` : `/shops-owners/shop${url}`,
		{
			...config,
			headers: { Authorization: `Bearer ${token}` },
			params,
		},
	);
};

export const getGlobal = async (url, params = {}, config = {}) => {
	// console.log('tokkkk aaaa');

	const token = await checkToken(url);
	// console.log('tokkkk', token);
	if (!token) {
		console.log;
		console.log('logaro sto throw', useMainStore.getState());
		logout();
		throw new Error('Unauth');
	}

	return axiosInstance.get(url, {
		...config,
		headers: { Authorization: `Bearer ${token}` },
		params,
	});
};

export const postGlobal = async (url, data) => {
	const token = await checkToken();
	if (!token) {
		console.log('logaro sto throw', useMainStore.getState());
		logout();
		throw new Error('Unauth');
	}

	return axiosInstance.post(url, data, {
		headers: { Authorization: `Bearer ${token}` },
	});
};

export const putGlobal = async (url, data) => {
	const token = await checkToken();
	if (!token) {
		console.log('logaro sto throw', useMainStore.getState());
		logout();
		throw new Error('Unauth');
	}

	return axiosInstance.put(url, data, {
		headers: { Authorization: `Bearer ${token}` },
	});
};

export const patchGlobal = async (url, data) => {
	const token = await checkToken();
	if (!token) {
		console.log('logaro sto throw', useMainStore.getState());
		logout();
		throw new Error('Unauth');
	}

	return axiosInstance.patch(url, data, {
		headers: { Authorization: `Bearer ${token}` },
	});
};

export const deleteGlobal = async (url, selectedRowKeys = [], queryParams = '') => {
	const { user: { role } = {}, selectedShopID } = useMainStore.getState();
	const token = await checkToken();
	if (!token) {
		console.log('logaro sto throw', useMainStore.getState());
		logout();
		throw new Error('Unauth');
	}
	const queryString = selectedRowKeys.map(id => `${queryParams}=${id}`).join('&');
	return axiosInstance.delete(
		`${role === 0 ? `/shops/${selectedShopID}${url}` : `/shops-owners/shop${url}`}?${queryString}`,
		{
			headers: { Authorization: `Bearer ${token}` },
		},
	);
};

export const post = async (url, data) => {
	const { user: { role } = {}, selectedShopID } = useMainStore.getState();
	const token = await checkToken();
	if (!token) {
		console.log('logaro sto throw', useMainStore.getState());
		logout();
		throw new Error('Unauth');
	}

	return axiosInstance.post(
		role === 0 ? `/shops/${selectedShopID}${url}` : `/shops-owners/shop${url}`,
		data,
		{
			headers: { Authorization: `Bearer ${token}` },
		},
	);
};

export const patch = async (url, data) => {
	const { user: { role } = {}, selectedShopID } = useMainStore.getState();
	const token = await checkToken();
	if (!token) {
		console.log('logaro sto throw', useMainStore.getState());
		logout();
		throw new Error('Unauth');
	}

	return axiosInstance.patch(
		role === 0 ? `/shops/${selectedShopID}${url}` : `/shops-owners/shop${url}`,
		data,
		{
			headers: { Authorization: `Bearer ${token}` },
		},
	);
};

export const put = async (url, data) => {
	const { user: { role } = {}, selectedShopID } = useMainStore.getState();
	const token = await checkToken();
	if (!token) {
		console.log('logaro sto throw', useMainStore.getState());
		logout();
		throw new Error('Unauth');
	}

	return axiosInstance.put(
		role === 0 ? `/shops/${selectedShopID}${url}` : `/shops-owners/shop${url}`,
		data,
		{
			headers: { Authorization: `Bearer ${token}` },
		},
	);
};

// TODO UNTESTED
// TODO UNTESTED
// TODO UNTESTED
export const del = async (url, selectedRowKeys = [], queryParams = '') => {
	const { user: { role } = {}, selectedShopID } = useMainStore.getState();
	const token = await checkToken();
	if (!token) {
		console.log('logaro sto throw', useMainStore.getState());
		logout();
		throw new Error('Unauth');
	}
	// Manually build the query string
	const queryString = selectedRowKeys.map(id => `${queryParams}=${id}`).join('&');

	// console.log('tok', token);
	return axiosInstance.delete(
		`${role === 0 ? `/shops/${selectedShopID}${url}` : `/shops-owners/shop${url}`}?${queryString}`,
		{},
		{
			headers: { Authorization: `Bearer ${token}` },
		},
	);
};
