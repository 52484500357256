import { create } from 'zustand';

const initStateData = {
	// property code & name also included in user object
	liveOrdersRequests: [],
	liveOrders: [],
	visibleIds: [],
	socket: null,
	shopOpen: false,
	isSocketConnected: false,
};

export const useLiveStore = create(set => ({
	...initStateData,

	setLiveOrdersRequests: data =>
		set(() => {
			return {
				liveOrdersRequests: data,
			};
		}),

	setLiveOrders: data =>
		set(() => {
			return {
				liveOrders: data,
			};
		}),

	setVisibleIds: data =>
		set(() => {
			return {
				visibleIds: data,
			};
		}),

	setSocket: data =>
		set(() => {
			return {
				socket: data,
			};
		}),

	setShopOpen: data =>
		set(() => {
			return {
				shopOpen: data,
			};
		}),

	setIsSocketConnected: data =>
		set(() => {
			return {
				isSocketConnected: data,
			};
		}),

	resetAll: () => {
		set(() => {
			const newState = {};
			Object.keys(initStateData).forEach(key => {
				newState[key] = initStateData[key];
			});

			return {
				...newState,
			};
		});
	},
}));
