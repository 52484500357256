import { useEffect } from 'react';
import { io } from 'socket.io-client';
// import { store } from '../redux/Store';
import { useLiveStore } from '@/store/live.store';
import { useMainStore } from '@/store/main.store';
import { checkToken } from '../http';

export let socketSingleton = null;

export const useSockets = ({ onAddNewRequest }) => {
	// console.log('useSockets');

	const { user } = useMainStore();
	const { isSocketConnected, setIsSocketConnected } = useLiveStore();

	// TODO DEN CHECKARO OTI EINAI OK TO TOKEN PRIN TO CONNECT

	// const state = useSelector(s => s);
	// const lorRef = useRef(liveOrdersRequests);

	// useEffect(() => {
	// 	lorRef.current = liveOrdersRequests;
	// }, [liveOrdersRequests]);

	useEffect(() => {
		let interval = setInterval(() => {
			if (!isSocketConnected) {
				try {
					socketSingleton.connect();
				} catch (err) {
					console.log('interval socket err', err);
				}
			}
		}, 7_000);

		return () => {
			clearInterval(interval);
			interval = null;
		};
	}, []);

	useEffect(() => {
		// const state = store.getState();
		// const token = state?.user?.token;

		// console.log(socketURL, useMainStore.getState().token);
		// const authorization = `Bearer ${user?.token || ''}`;

		// console.log(authorization, import.meta.env.VITE_BASE_URL);

		// console.log('derp', user?.token);
		const socket = io(`${import.meta.env.VITE_BASE_URL}`, {
			// autoConnect: state?.user?.role === 1 && !!authorization && shopOpen,
			autoConnect: false,
			auth: {
				token: `Bearer ${user?.token || ''}`,
			},
			// extraHeaders: {
			// 	authorization,
			// 	// 'ngrok-skip-browser-warning': 'true',
			// 	// auth: authorization,
			// 	// token: authorization,
			// },
			reconnection: true,
			reconnectionAttempts: Infinity,
			reconnectionDelay: 1000, // 1 second
			reconnectionDelayMax: 5000, // 5 seconds

			// auth: {
			//     authorization: authorization,
			//     auth: authorization,
			//     token: authorization,
			// },

			// query: {
			//     // ?something=dasda
			//     'my-key': 'my-value',
			// },
		}); // ${user?.cityId}

		// const { setSocket } = useSocketStore.getState();
		// socket.io.on('error', error => {
		//     // ...
		//     console.log('error 1');
		// });
		// socket.io.on('reconnect', attempt => {
		//     // ...
		//     console.log('reconnect 2');
		// });
		// socket.io.on('reconnect_attempt', attempt => {
		//     // ...
		//     console.log('reconnect_attempt 3');
		// });
		// socket.io.on('reconnect_error', error => {
		//     // ...
		//     console.log('reconnect_error 4');
		// });
		// socket.io.on('reconnect_failed', () => {
		//     // ...
		//     console.log('reconnect_failed 5');
		// });

		socket.on('connect', () => {
			// ...
			// console.log('ggg------------  connect', `, id: ${socket?.id}`);
			console.log('ggg------------  connect', socket);
			setIsSocketConnected(true);
			// setTimeout(() => {
			// 	console.log('reccc');
			// 	// if (socket.io.engine) {
			// 	socket.io.engine.close();
			// 	// }

			// 	// socket.disconnect();
			// }, 3000);
		});

		socket.on('connect_error', async error => {
			setIsSocketConnected(false);

			if (socket.active) {
				console.log('ggg------------  connect error, will try again', error);
				// temporary failure, the socket will automatically try to reconnect
			} else {
				// console.log('tok socket', user?.token);
				console.log('ggg------------  denied, must call manually');

				// console.log('TARARA', socket.authorization);
				setTimeout(async () => {
					try {
						const token = await checkToken();
						if (token) {
							if (!socket) {
								console.log('no socket');
								return;
							}

							socket.auth = { token: `Bearer ${token}` };
							socket.connect(); // Retry connection
						}
						// the connection was denied by the server
						// in that case, `socket.connect()` must be manually called in order to reconnect
						console.log(error.message);
					} catch (err) {
						console.log('sock err', err);
					}
				}, 3_000);
			}
		});

		socket.on('reconnect_attempt', attemptNumber => {
			console.log(`Reconnection attempt #${attemptNumber}`);
		});

		socket.on('reconnect', attemptNumber => {
			console.log(`Reconnected after ${attemptNumber} attempts`);
		});

		socket.on('reconnect_failed', () => {
			console.log('Reconnection failed');
		});

		// possible reasons sto client api online
		socket.on('disconnect', (reason, details) => {
			console.log('------------  disconnect', reason, details);
			setIsSocketConnected(false);
		});

		socket.on('ping', () => {
			// console.log('...ping');
		});

		socket.on('order-created', (newOrderStr, cb) => {
			console.log('------------  NEW ORDER strrrrr', newOrderStr);

			const newOrder = JSON.parse(newOrderStr);
			console.log('------------  NEW ORDER', newOrder, cb);
			if (typeof cb === 'function') {
				cb(444);
			}

			// const idx = lorRef.current.findIndex(lo => lo.id === newOrder.id);
			// if (idx !== -1) {
			// 	console.log('new order exists', lorRef.current);
			// 	return;
			// }

			// const newLiveOrdersRequests = [...lorRef.current];

			// newLiveOrdersRequests.push(newOrder);
			// setLiveOrdersRequests(newLiveOrdersRequests);

			onAddNewRequest(newOrder);
			// onAddOrder(data);
		});

		// socket.onAny((event, ...args) => {
		// 	console.log(`------------ socket got ${event}`);
		// });

		// console.log('sss ', state);
		// dispatch(setSocket(socket));
		// console.log('setaro socket');
		socketSingleton = socket;

		return () => {
			socketSingleton = null;
			socket.off();
		};
	}, []);
};
