// import { useKeyboard } from '@/utils/hooks/useKeyboard';
// import { useWindowSize } from '@/utils/hooks/useWindowSize';

export const BottomNavigation = ({ items, selectedKey, showLabels, onClick, onLogout }) => {
	// const { isKeyboardVisible, keyboardHeight } = useKeyboard();
	// if (isKeyboardVisible) {
	// 	return null;
	// }
	// const { width, height, dvh, innerHeight } = useWindowSize();

	return (
		<div className='fixed flex flex-row justify-start  bottom-0 left-0 z-50 w-full h-16 bg-neutral-600  '>
			<div
				className={`flex flex-1 flex-row  ${showLabels ? 'gap-x-0 xs:gap-x-1' : 'gap-x-0 xs:gap-x-3'} h-full max-w-lg font-medium`}
				// style={{ columnGap: showLabels ? 8 : 10 }}
			>
				{items.map(it => {
					const isSame = it.key === selectedKey;
					const color = isSame ? 'rgb(120,197,130)' : '';
					const isSelectedClass = `text-[${isSame ? 'rgb(120,197,130)' : 'rgb(0,0,255)'}]`;

					const isSelectedTextStyle = isSame
						? {
								color: 'rgb(120,197,130)',
							}
						: {};
					return (
						<button
							key={it.label}
							type='button'
							onClick={() => onClick(it)}
							className={`cursor-pointer ${isSelectedClass} inline-flex flex-col items-center justify-center px-3 hover:bg-gray-50 group`}
						>
							{it.icon(color)}
							{showLabels && (
								<span
									className={`text-sm text-gray-400 group-hover:text-[rgb(120,197,130)]`}
									style={isSelectedTextStyle}
								>
									{it.label}
								</span>
							)}
						</button>
					);
				})}
			</div>

			<div className='absolute z-10000 right-2 bottom-0 flex flex-row justify-center h-full max-w-lg font-medium'>
				{/* <div className=' flex flex-row justify-center h-full max-w-lg font-medium'> */}
				<button
					key='logout'
					type='button'
					onClick={onLogout}
					className='cursor-pointer inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50  group'
				>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='22'
						height='22'
						viewBox='0 0 24 24'
						fill='none'
						stroke='currentColor'
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round'
						className='text-sm text-gray-400  group-hover:text-[black] '
					>
						<path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4' />
						<polyline points='16 17 21 12 16 7' />
						<line x1='21' x2='9' y1='12' y2='12' />
					</svg>
				</button>
			</div>
		</div>
	);
};
