import { useEffect, useRef, useState } from 'react';
// import { Dropdown, Select } from 'antd';
// import {
// 	ORDER_DECLINE_REASONS_OPTIONS,
// 	ORDER_DELIVERY_ESTIMATE_OPTIONS,
// } from '../../../utils/constants';
// import { Button } from '@/components/UI/Button/index.jsx';

export const LiveOrderRequest = ({
	order,
	// liveOrdersRequests,
	// onOpen,
	// onClose,
	onRemoveOrderRequest,
	// onAccept,
	// onDecline,
	onOpenFullScreen,
}) => {
	const [timer, setTimer] = useState(null);
	// const [estimatedMinutes, setEstimatedMinutes] = useState(
	// 	ORDER_DELIVERY_ESTIMATE_OPTIONS[5].value,
	// );

	const intervalRef = useRef();

	useEffect(() => {
		const calcTime = () => {
			if (!order) {
				return;
			}
			const secondsLeft = (new Date(order.orderIgnoredDate) - new Date()) / 1000;
			// console.log(secondsLeft);
			setTimer(secondsLeft > 0 ? Math.floor(secondsLeft) : '');
			// console.log({ order });
			if (!(secondsLeft > 0)) {
				// onClose(order.id, lorRef.current);
				onRemoveOrderRequest(order.id);
			}
		};
		calcTime();
		intervalRef.current = setInterval(calcTime, 1000);
		return () => {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		};
	}, [order]);

	return (
		<div
			onClick={() => onOpenFullScreen(order.id)}
			className='flex flex-row w-50 min-w-50 gap-x-3 px-3 py-1.5 rounded-xl border-2 text-xl text-amber-100 cursor-pointer'
		>
			<div>#{order.id}</div>
			<div>{order.price}€</div>

			{/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button onClick={() => onAccept(order, estimatedMinutes)}>Αποδοχή</Button>
					<Select
						value={estimatedMinutes}
						// notFoundContent={loading ? <Spin size='small' /> : null}
						style={{ width: '100%' }}
						// onChange={value => {
						// 	setParentData({ ...parentData, [name]: value?.id || value });
						// }}
						onChange={v => setEstimatedMinutes(v)}
					>
						{ORDER_DELIVERY_ESTIMATE_OPTIONS.map(item => (
							<Option key={item.id} value={item.id}>
								{item.value}
							</Option>
						))}
					</Select>
				</div>
				<div className='flex flex-col'>
					<Dropdown.Button
						menu={{
							ORDER_DECLINE_REASONS_OPTIONS,
							onClick: onDecline,
						}}
						onClick={() => onDecline(order, undefined)}
					>
						Απόρριψη
					</Dropdown.Button>
				</div>
			</div> */}

			<div>{timer}</div>
		</div>
	);
};
