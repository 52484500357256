import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
import UploadImage from '../UI/UploadImage';
import { Switch } from '@/components/UI/Switch';

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
	required: '${label} is required!',
	types: {
		email: '${label} is not a valid email!',
	},
	number: {
		range: '${label} must be a valid mobile number! (10 digits)',
	},
};
/* eslint-enable no-template-curly-in-string */

const CuisineForm = ({ edit, onFinish, cuisineData, setCuisineData }) => {
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue({
			name: cuisineData?.name,
			available: cuisineData?.available,
			files: cuisineData?.files,
		});
	}, [cuisineData]);

	return (
		<Form
			name='Κουζίνες'
			form={form}
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				alignItems: 'center',
			}}
			validateMessages={validateMessages}
		>
			<Form.Item
				style={{ width: '100%' }}
				name={['name']}
				label='Όνομα'
				rules={[
					{
						required: true,
					},
				]}
			>
				<Input
					value={cuisineData?.name}
					onChange={({ target: { value } }) => {
						setCuisineData({
							...cuisineData,
							name: value,
						});
					}}
					style={{
						marginRight: 12,
						marginBottom: 12,
						width: 200,
					}}
				/>
			</Form.Item>
			<Form.Item
				style={{ width: '100%', marginBottom: 10 }}
				name={['available']}
				label='Διαθέσιμο'
			>
				<Switch
					checked={cuisineData?.available}
					onCheckedChange={value => {
						setCuisineData({
							...cuisineData,
							available: value,
						});
					}}
				/>
			</Form.Item>
			<Form.Item
				style={{ width: '100%' }}
				name={['files']}
				label='Εικόνα'
				rules={[
					{
						required: true,
					},
				]}
			>
				<UploadImage
					parentData={cuisineData}
					setParentData={setCuisineData}
					array={['main']}
				/>
			</Form.Item>
		</Form>
	);
};
export default CuisineForm;
