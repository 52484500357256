import { Activity, Wifi, WifiOff } from 'lucide-react';
import { useLiveStore } from '@/store/live.store';
import { useMainStore } from '@/store/main.store';
// import { useInternetConnectionStatus } from '@/utils/hooks/useInternetConnectionStatus';

export const OnlineStatus = () => {
	const { user } = useMainStore();
	// const onlineStatus = useInternetConnectionStatus();
	const { shopOpen, isSocketConnected } = useLiveStore();

	const colorOnline = 'oklch(0.9 0.36 130)';
	const colorOffline = 'oklch(0.63 0.23 20)';

	return (
		<div className='flex justify-center items-center gap-x-2 mr-2'>
			{/* <div
				className='w-5 h-5 rounded-4xl border-2  '
				style={{
					backgroundColor: onlineStatus ? colorOnline : colorOffline,
				}}
			/> */}
			{/* {onlineStatus ? (
				<Wifi color={colorOnline} size={24} />
			) : (
				<WifiOff color={colorOffline} size={20} />
			)} */}

			{user?.role === 1 && (
				// <div
				// 	className='w-5 h-5 rounded-4xl border-2 border-black '
				// 	style={{
				// 		backgroundColor: !shopOpen
				// 			? 'transparent'
				// 			: isSocketConnected
				// 				? colorOnline
				// 				: colorOffline,
				// 		opacity: !shopOpen ? 0.2 : 1,
				// 	}}
				// />
				<Activity
					size={20}
					color={
						!shopOpen
							? 'rgb(180,180,180)'
							: isSocketConnected
								? colorOnline
								: colorOffline
					}
					style={{ opacity: !shopOpen ? 0.8 : 1 }}
				/>
			)}
		</div>
	);
};
