import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { SuperTable } from './SuperTable';
import { TableLoadingError } from '@/components/tables/TableLoadingError';
import { HeaderTable } from './HeaderTable';
import { pricify } from '@/utils/pricify';

export const HistoryOrdersTable = ({ data, isError, isLoading, onRowClick }) => {
	const columns = useMemo(() => {
		return [
			{
				accessorKey: 'id',
				header: 'ID',
			},
			{
				header: 'Διεύθυνση',
				cell: ({ row }) => (
					<div>
						{row.original?.address?.street} {row.original?.address?.streetNumber}
					</div>
				),
			},
			{
				header: 'Πελάτης',
				cell: ({ row }) => (
					<div>
						{row.original?.user?.firstName} {row.original?.user?.lastName}
					</div>
				),
			},
			{
				header: 'Τιμή',
				cell: ({ row }) => <div>{pricify(row.original.price)}</div>,
			},
			{
				header: 'Ημερομηνία',
				cell: ({ row }) => {
					const date = DateTime.fromISO(row.original.createdAt);
					return (
						<div className='flex flex-row items-center gap-x-1'>
							<div className='w-9'>{date.toFormat('HH:mm')}</div>
							<div className='w-[2px] self-stretch bg-neutral-300'></div>
							<div className='w-16'>{date.toFormat('dd-MM-yy')}</div>
						</div>
					);
				},
			},
		];
	}, []);

	return (
		<TableLoadingError isError={isError} isLoading={isLoading}>
			<HeaderTable title='Ιστορικό παραγγελιών' />

			<SuperTable
				data={data}
				columns={columns}
				searchKeys={['id', 'address.street']}
				onRowClick={onRowClick}
			/>
		</TableLoadingError>
	);
};
