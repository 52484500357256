import React, { useEffect, useRef, useState } from 'react';
import CityView from '../../components/cities/CityView';
import { toast } from '@/utils/toast';
import { useMainStore } from '@/store/main.store';
import { putGlobal } from '@/utils/http';
import { useKeyQuery } from '@/utils/hooks/useKeyQuery';

const initialState = {};

const CityHOC = () => {
	const { user } = useMainStore();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(60);
	const [totalPages, setTotalPages] = useState(0);

	const [open, setOpen] = useState(false);

	const [item, setItem] = useState(initialState);

	const savingRef = useRef(false);

	// const queryClient = useQueryClient();

	// const {
	// 	isPending,
	// 	error,
	// 	data: dataCities,
	// } = useQuery({
	// 	queryKey: [QUERY_KEY],
	// 	queryFn: () => {
	// 		return getGlobal('/cities', {
	// 			pageNumber,
	// 			pageSize,
	// 			// language: user.language,
	// 		});
	// 	},
	// });

	// const { data: cities } = dataCities?.data || {};

	const {
		data: { data: cities } = {},
		isError,
		isPending,
		invalidateKeyQueries,
	} = useKeyQuery({
		key: 'cities',
		global: true,
	});

	useEffect(() => {
		if (open && !item?.id) {
			setItem(null);
		}
	}, [open]);

	// useEffect(() => {
	// 	getGlobal('/cities', {
	// 		pageNumber,
	// 		pageSize,
	// 		language: user.language,
	// 	})
	// 		.then(response => {
	// 			response && dispatch(setCities(response.data.data));
	// 			setTotalPages(response.data.totalPages);
	// 		})
	// 		.catch(err => {});
	// }, [pageNumber, pageSize]);

	const onFinish = () => {
		if (savingRef.current) {
			return;
		}

		try {
			const hideLoading = () => (savingRef.current = false);

			toast.promise(
				async () => {
					savingRef.current = true;

					const data = {
						area: {
							type: 0,
							circleCenter: {
								lat: item.lat,
								lng: item.lng,
							},
							radius: parseInt(item.radius),
						},
						name: item.name,
						language: user.language,
					};

					await putGlobal(`/cities/${item.id}`, data);
					setOpen(false);
					setItem(initialState);

					invalidateKeyQueries();

					hideLoading();
				},
				{ errorCb: hideLoading },
			);
		} catch (err) {
			console.log('city onfinish ', err);
			toast.error(err);
		}
	};

	const onOpenNew = () => {
		setItem(initialState);
		setOpen(true);
	};

	return (
		<CityView
			data={(cities || [])?.map(it => ({
				id: it.id,
				lat: it.area.circleCenter.lat,
				lng: it.area.circleCenter.lng,
				radius: it.area.radius,
				name: it.name,
			}))}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
			onFinish={onFinish}
			open={open}
			setOpen={setOpen}
			item={item}
			setItem={setItem}
			onOpenNew={onOpenNew}
			isError={isError}
			isLoading={isPending}
		/>
	);
};

export default CityHOC;
