import React, { useEffect, useRef, useState } from 'react';
// import { useSelector } from 'react-redux';
import ItemCategoryTable from '../../../../components/store/shop/itemCategories/ItemCategoryView';
import { toast } from '@/utils/toast';
import { useMainStore } from '@/store/main.store';
import { post, put } from '@/utils/http';
import { useKeyQuery } from '@/utils/hooks/useKeyQuery';
import { findMaxOrder } from '@/utils/findMaxOrder';

const initialState = {};

const ItemCategoriesHOC = ({
	hideTable,
	itemId,
	open: customOpen,
	setOpen: customSetOpen,
	onFinishSuccessCb,
}) => {
	const { user } = useMainStore();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(200);
	const [totalPages, setTotalPages] = useState(0);
	const [item, setItem] = useState({});
	const [open, setOpen] = useState(false);

	const savingRef = useRef(false);

	// const queryClient = useQueryClient();

	// const {
	// 	isPending,
	// 	error,
	// 	data: dataItemCategories,
	// } = useQuery({
	// 	queryKey: [QUERY_KEY],
	// 	queryFn: () =>
	// 		get('/item-categories', {
	// 			pageNumber,
	// 			pageSize,
	// 			language: user.language,
	// 		}),
	// });

	// const { data: itemCategories } = dataItemCategories?.data || {};

	const {
		data: { data: itemCategories } = {},
		isError,
		isPending,
		invalidateKeyQueries,
	} = useKeyQuery({
		key: 'item-categories',
		global: false,
		pageSize,
	});

	useEffect(() => {
		if (itemId && itemCategories) {
			const item = itemCategories.find(it => it.id === itemId);
			setItem(item);
		}
	}, [itemId, itemCategories]);

	useEffect(() => {
		if (open && !item?.id) {
			setItem(initialState);
		}
	}, [open]);

	const onFinish = () => {
		if (savingRef.current) {
			return;
		}

		if (!item?.name) {
			toast.error('Το όνομα είναι κενό');
			return;
		}

		const action = item.id ? put : post;
		const url = item.id ? `/item-categories/${item.id}` : `/item-categories`;

		const hideLoading = () => (savingRef.current = false);

		toast.promise(
			async () => {
				savingRef.current = true;
				await action(url, {
					name: item.name,
					language: user.language,
					order: item.id ? item.order : findMaxOrder(itemCategories) + 1,
				});
				setOpen(!open);
				setItem({});

				if (typeof onFinishSuccessCb === 'function') {
					onFinishSuccessCb();
				}

				// queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

				invalidateKeyQueries();

				hideLoading();
			},
			{ errorCb: hideLoading },
		);
	};

	const onOpenNew = () => {
		setItem(initialState);
		setOpen(true);
	};

	return (
		<ItemCategoryTable
			hideTable={hideTable}
			data={itemCategories || []}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
			item={item}
			setItem={setItem}
			onFinish={onFinish}
			onOpenNew={onOpenNew}
			open={customOpen || open}
			setOpen={customSetOpen || setOpen}
			isError={isError}
			isLoading={isPending}
		/>
	);
};

export default ItemCategoriesHOC;
