import { Input } from 'antd';
import { MultiSelect } from '@/components/UI/MultiSelect';
import { Switch } from '@/components/UI/Switch';

const ModifierForm = ({ item, setItem, modifierOptions }) => {
	// const { modifierOptions, setModifierOptions } = useDataStore();

	// const [optionsModifierOptions, setOptionsModifierOptions] = useState([]);

	// const [loading, setLoading] = useState(true);
	// useEffect(() => {
	// 	// user?.role === 0 ? fetchDataForAdmin() : fetchDataForUserShop();
	// 	fetchData();
	// }, []);

	// const params = { shop: item?.shop, modifier: item?.id };

	// const fetchData = async () => {
	// 	let newPath = `/modifier-options?`;

	// 	try {
	// 		Object.keys(params).forEach(key => {
	// 			if (!params[key]) {
	// 				return;
	// 			}
	// 			newPath += `&${key}=${params[key]}`;
	// 		});

	// 		// setLoading(true);
	// 		// const action = isAdmin ? getGlobal : get;
	// 		// TODO PANAGIA MOU
	// 		const action = get;
	// 		const res = await action(newPath, {
	// 			// pageNumber,
	// 			// 1,
	// 			// ...(params && { params }),
	// 			// ...(search && { name: search }),
	// 			pageSize: 999,
	// 			// shop: user?.shop,
	// 			// language: user?.language,
	// 		});

	// 		const { data } = res || {};
	// 		if (!data?.data) {
	// 			toast.error('generic');
	// 			return;
	// 		}
	// 		// const values = constructValues(data?.data);
	// 		setOptionsModifierOptions(data?.data);
	// 		// setTotalPages(data.totalPages);
	// 		// setData(prevData => uniqBy([...prevData, ...data.data], 'id'));
	// 		// setHasMore(data.pageNumber < data.totalPages);
	// 	} catch (error) {
	// 		console.log(error);
	// 		toast.error(error?.response?.data?.message);
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	// const fetchDataForUserShop = async () => {
	// 	setLoading(true);
	// 	try {
	// 		const res = await get(basePath, {
	// 			pageNumber,
	// 			...(params && { params }),
	// 			...(search && { name: search }),
	// 			pageSize: 30,
	// 			language: user?.language,
	// 		});
	// 		const { data } = res || {};
	// 		if (!data) {
	// 			toast.error('generic');
	// 			return;
	// 		}
	// 		setTotalPages(data.totalPages);
	// 		setData(prevData => uniqBy([...prevData, ...data.data], 'id'));
	// 		setHasMore(data.pageNumber < data.totalPages);
	// 		// console.log(response.data.data);
	// 	} catch (error) {
	// 		toast.error(error.response.data.message);
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	// const words = !searchText ? undefined : replaceTonous(searchText.trim()).split(/\s+/);

	// const filteredData = !searchText
	// 	? optionsModifierOptions
	// 	: optionsModifierOptions
	// 			.map(c => {
	// 				if (words.some(word => replaceTonous(c.name).includes(word))) {
	// 					return c;
	// 				}
	// 			})
	// 			.filter(it => !!it);

	// const filteredData = getSearchFilteredData(optionsModifierOptions, 'name', searchText);

	// console.log('edooo', searchText);
	return (
		<div className='flex flex-col justify-center items-start'>
			<div className='flex flex-col mt-3' style={{ width: '100%' }}>
				<span>Όνομα</span>
				<Input
					value={item?.name || ''}
					onChange={({ target: { value } }) => {
						setItem({
							...item,
							name: value,
						});
					}}
				/>
			</div>

			<div className='w-full flex flex-col mt-3'>
				<span>Εσωτερικό Όνομα</span>
				<Input
					value={item?.internalName || ''}
					onChange={({ target: { value } }) => {
						setItem({
							...item,
							internalName: value,
						});
					}}
				/>
			</div>

			<div className='flex flex-row items-center gap-x-3 mt-4 mb-4'>
				<span>Τύπος: {item?.type ? 'Μια επιλογή' : 'Πολλές επιλογές'} </span>
				<Switch
					style={{ marginTop: 4 }}
					// unCheckedChildren='Πολλές Επιλογές'
					// checkedChildren='Μια'
					checked={item?.type}
					onCheckedChange={checked => {
						setItem({ ...item, type: checked });
					}}
				/>
			</div>

			<div className='flex flex-row items-center gap-x-3 mb-4'>
				<span>Υποχρεωτικό: {item?.required ? 'Ναι' : 'Όχι'} </span>
				<Switch
					// checkedChildren='Ναι'
					// unCheckedChildren='Όχι'
					style={{ marginTop: 4 }}
					checked={item?.required}
					onCheckedChange={checked => {
						setItem({ ...item, required: checked });
					}}
				/>
			</div>
			<div className='flex flex-col' style={{ marginBottom: 22, width: '100%' }}>
				<MultiSelect
					placeholder='Επίλεξε υλικά'
					options={modifierOptions?.map(it => ({
						label: it.name,
						value: it.id,
					}))}
					onValueChange={value => {
						setItem({
							...item,
							options: value,
						});
					}}
					defaultValue={item?.options?.map?.(it => it.id)}
					value={item?.options?.map?.(it => it.id)}
					variant='inverted'
					animation={2}
					maxCount={3}
				/>
			</div>
		</div>
	);
};
export default ModifierForm;
