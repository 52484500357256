import { Input } from 'antd';
import React from 'react';
import PhoneInput from 'react-phone-number-input';

const User = ({ shopData, setShopData }) => {
	return (
		<div className='flex flex-row flex-wrap gap-2'>
			<div className='flex flex-col' style={{ marginRight: 6 }}>
				<br />
				<h3>Όνομα</h3>
				<Input
					style={{ width: 200 }}
					value={shopData?.user?.firstName}
					onChange={({ target: { value } }) => {
						setShopData({
							...shopData,
							user: {
								...shopData.user,
								firstName: value,
							},
						});
					}}
				/>
			</div>
			<br />
			<div className='flex flex-col' style={{ marginRight: 6 }}>
				<h3>Επώνυμο</h3>
				<Input
					style={{ width: 200 }}
					value={shopData?.user?.lastName}
					onChange={({ target: { value } }) => {
						setShopData({
							...shopData,
							user: {
								...shopData.user,
								lastName: value,
							},
						});
					}}
				/>
			</div>
			<br />
			<div className='flex flex-col' style={{ marginRight: 6 }}>
				<h3>Email</h3>
				<Input
					type='email'
					style={{ width: 200 }}
					value={shopData?.user?.email}
					onChange={({ target: { value } }) => {
						setShopData({
							...shopData,
							user: {
								...shopData.user,
								email: value,
							},
						});
					}}
				/>
			</div>
			<br />
			<div className='flex flex-col' style={{ marginRight: 6 }}>
				<h3>Τηλέφωνο</h3>
				<PhoneInput
					value={shopData?.user?.phone}
					defaultCountry='GR'
					style={{
						width: 200,
						border: '1px solid #cecece',
						borderRadius: 6,
						marginTop: 2,
						height: 30,
					}}
					onChange={value => {
						setShopData({
							...shopData,
							user: {
								...shopData.user,
								phone: value,
							},
						});
					}}
				/>
			</div>
		</div>
	);
};

export default User;
