import { Route } from 'react-router-dom';
import { RoutesNames } from './RouteNames';
import ShopsHOC from '@/containers/shops/ShopsHOC';
import { AdminShopOverview } from '@/components/store/shop/AdminShopOverview';
import CuisinesHOC from '@/containers/cuisines/CuisineHOC';
import CityHOC from '@/containers/cities/CityHOC';
import RoutesWrapper from '@/components/routes-wrapper';
import UsersHOC from '@/containers/users/UsersHOC';

const AdminRoutes = () => {
	return (
		<RoutesWrapper>
			<Route index path={RoutesNames.SHOPS} element={<ShopsHOC />} />
			<Route path={RoutesNames.SHOP} element={<AdminShopOverview />} />
			<Route path={RoutesNames.USERS} element={<UsersHOC />} />
			<Route path={RoutesNames.CUISINES} element={<CuisinesHOC />} />
			<Route path={RoutesNames.CITIES} element={<CityHOC />} />
		</RoutesWrapper>
	);
};

export default AdminRoutes;
