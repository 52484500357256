import { SuperTable } from './SuperTable';
import { useMemo } from 'react';
import { Switch } from '@/components/UI/Switch';
import { Tag } from '@/components/UI/Tag';
import { TableLoadingError } from './TableLoadingError';
import { HeaderTable } from './HeaderTable';
import { useMainStore } from '@/store/main.store';

export const NewCouponsTable = ({ data, isError, isLoading, onOpenNew, onActiveChange }) => {
	const { user } = useMainStore();

	const columns = useMemo(
		() => [
			{
				header: 'ID',
				cell: ({ row }) => (
					<div
						onClick={e => e.stopPropagation()}
						className='flex justify-center items-center '
					>
						{row.original.id}
					</div>
				),
			},
			{
				header: 'Κωδικός',
				cell: ({ row }) => (
					<Tag colorPreset={row.original.active ? 'green' : 'red'}>
						{row.original.code}
					</Tag>
				),
			},
			{
				header: 'Έκπτωση',
				cell: ({ row }) => (
					<span>{`${row.original.discount} ${row.original.type === 0 ? '€' : '%'}`}</span>
				),
			},
			{
				accessorKey: 'minimumOrder',
				header: 'Ελ. παραγγελία',
			},
			{
				header: 'Ενεργό',
				cell: ({ row }) => {
					return (
						<Switch
							checked={row.original.active}
							onCheckedChange={() => {
								onActiveChange(row.original.id);
							}}
						/>
					);
				},
			},
		],
		[],
	);

	return (
		<TableLoadingError isError={isError} isLoading={isLoading}>
			<HeaderTable onOpenNew={onOpenNew} title={user?.role !== 0 && 'Κουπόνια'} />
			<SuperTable data={data} columns={columns} searchKeys={['code']} />
		</TableLoadingError>
	);
};
