import React from 'react';
import ItemCategoryForm from './ItemCategoryForm';
import { MyDialog } from '@/components/UI/MyDialog';
import { NewItemCategoriesTable } from '@/components/tables/NewItemCategoriesTable';

const ItemCategoryTable = ({
	hideTable,
	data,
	setPageNumber,
	pageSize,
	setPageSize,
	totalPages,
	item,
	setItem,
	onFinish,
	onOpenNew,
	open,
	setOpen,
	isError,
	isLoading,
}) => {
	return (
		<div className='flex flex-col'>
			{!hideTable && (
				<>
					<NewItemCategoriesTable
						data={data}
						isError={isError}
						isLoading={isLoading}
						onOpenNew={onOpenNew}
						onRowClick={row => {
							setOpen(true);
							setItem(row.original);
						}}
					/>
				</>
			)}

			<MyDialog
				open={hideTable || open}
				title='Κατηγορία'
				description={item?.id ? `ID: ${item?.id}` : undefined}
				onOpenChange={setOpen}
				onSubmit={onFinish}
				submitText={item?.id ? undefined : 'Προσθήκη'}
			>
				<ItemCategoryForm item={item} setItem={setItem} />
			</MyDialog>
		</div>
	);
};

export default ItemCategoryTable;
