import { Image } from '@unpic/react';
import { SuperTable } from './SuperTable';
import { useMemo } from 'react';
import { Checkbox } from '@/components/UI/checkbox';
import { TableLoadingError } from './TableLoadingError';
import { HeaderTable } from './HeaderTable';
import { useMainStore } from '@/store/main.store';

export const NewItemsTable = ({ data, isError, isLoading, onOpenNew, onRowClick }) => {
	const { user } = useMainStore();

	const columns = useMemo(() => {
		return [
			{
				id: 'select',
				header: ({ table }) => (
					<div className='flex justify-center items-center '>
						<Checkbox
							checked={
								table.getIsAllPageRowsSelected() ||
								(table.getIsSomePageRowsSelected() && 'indeterminate')
							}
							onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
							aria-label='Select all'
						/>
					</div>
				),
				cell: ({ row }) => (
					<div
						onClick={e => e.stopPropagation()}
						className='flex justify-center items-center '
					>
						<Checkbox
							checked={row.getIsSelected()}
							onCheckedChange={value => row.toggleSelected(!!value)}
							aria-label='Select row'
						/>
					</div>
				),
				enableSorting: false,
				enableHiding: false,
			},
			{
				header: 'ID',
				accessorKey: 'id',
			},
			{
				header: 'Εικόνα',
				cell: ({ row }) => {
					const logo = row.original?.files?.find(f => f.type === 'main');
					if (!logo) {
						return null;
					}
					return (
						<div className='flex justify-center items-center w-12 h-8 max-h-8'>
							<Image
								src={`${import.meta.env.VITE_SPACE_URL}${logo?.name}`}
								className='max-h-10'
								// layout='fixed'
								objectFit='cover'
								// width={200}
								// height={100}
								aspectRatio={1}
							/>
						</div>
					);
				},
			},
			{
				header: 'Όνομα',
				accessorKey: 'name',
			},
			{
				header: 'Τιμή',
				accessorKey: 'price',
			},
		];
	}, []);

	return (
		<TableLoadingError isError={isError} isLoading={isLoading}>
			<HeaderTable onOpenNew={onOpenNew} title={user?.role !== 0 && 'Προϊόντα'} />

			<SuperTable
				data={data}
				columns={columns}
				searchKeys={['name']}
				deletePath='/items'
				onRowClick={onRowClick}
			/>
		</TableLoadingError>
	);
};
