import { RoutesNames } from '@/router/RouteNames';

const icons = {
	Κουζίνες: color => (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke={color || 'currentColor'}
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
			class='text-sm text-gray-400  group-hover:text-[#79d185] '
		>
			<path d='M4 13c3.5-2 8-2 10 2a5.5 5.5 0 0 1 8 5' />
			<path d='M5.15 17.89c5.52-1.52 8.65-6.89 7-12C11.55 4 11.5 2 13 2c3.22 0 5 5.5 5 8 0 6.5-4.2 12-10.49 12C5.11 22 2 22 2 20c0-1.5 1.14-1.55 3.15-2.11Z' />
		</svg>
	),

	Χρήστες: color => (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke={color || 'currentColor'}
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
			class='text-sm text-gray-400  group-hover:text-[#79d185] '
		>
			<path d='M18 21a8 8 0 0 0-16 0' />
			<circle cx='10' cy='8' r='5' />
			<path d='M22 20c0-3.37-2-6.5-4-8a5 5 0 0 0-.45-8.3' />
		</svg>
	),
	Πόλεις: color => (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke={color || 'currentColor'}
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
			class='text-sm text-gray-400  group-hover:text-[#79d185] '
		>
			<path d='M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z' />
			<path d='M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2' />
			<path d='M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2' />
			<path d='M10 6h4' />
			<path d='M10 10h4' />
			<path d='M10 14h4' />
			<path d='M10 18h4' />
		</svg>
	),

	Παραγγελίες: color => (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke={color || 'currentColor'}
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
			class='text-sm text-gray-400  group-hover:text-[#79d185] '
		>
			<path d='M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4Z' />
			<path d='M3 6h18' />
			<path d='M16 10a4 4 0 0 1-8 0' />
		</svg>
	),
	Κατάλογος: color => (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke={color || 'currentColor'}
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
			class='text-sm text-gray-400  group-hover:text-[#79d185] '
		>
			<path d='M3 2v7c0 1.1.9 2 2 2h4a2 2 0 0 0 2-2V2' />
			<path d='M7 2v20' />
			<path d='M21 15V2a5 5 0 0 0-5 5v6c0 1.1.9 2 2 2h3Zm0 0v7' />
		</svg>
	),
	Ιστορικό: color => (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke={color || 'currentColor'}
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
			class='text-sm text-gray-400  group-hover:text-[#79d185] '
		>
			<path d='M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1Z' />
			<path d='M14 8H8' />
			<path d='M16 12H8' />
			<path d='M13 16H8' />
		</svg>
	),
	Κουπόνια: color => (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke={color || 'currentColor'}
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
			class='text-sm text-gray-400  group-hover:text-[#79d185] '
		>
			<path d='M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z' />
			<path d='M13 5v2' />
			<path d='M13 17v2' />
			<path d='M13 11v2' />
		</svg>
	),
	Κατάστημα: color => (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke={color || 'currentColor'}
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
			// class='lucide lucide-store'
			class={`text-sm text-gray-400 ${color}  group-hover:text-[#79d185] `}
		>
			<path d='m2 7 4.41-4.41A2 2 0 0 1 7.83 2h8.34a2 2 0 0 1 1.42.59L22 7' />
			<path d='M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8' />
			<path d='M15 22v-4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v4' />
			<path d='M2 7h20' />
			<path d='M22 7v3a2 2 0 0 1-2 2a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 16 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 12 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 8 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 4 12a2 2 0 0 1-2-2V7' />
		</svg>
	),
};

export const navigationRoutes = {
	admin: [
		{
			label: 'Καταστήματα',
			key: RoutesNames.SHOPS,
			icon: c => icons['Κατάστημα'](c),
		},
		{
			label: 'Χρήστες',
			key: RoutesNames.USERS,
			icon: c => icons['Χρήστες'](c),
		},
		{
			label: 'Κουζίνες',
			key: RoutesNames.CUISINES,
			icon: c => icons['Κουζίνες'](c),
		},
		{
			label: 'Πόλεις',
			key: RoutesNames.CITIES,
			icon: c => icons['Πόλεις'](c),
		},
	],
	shopOwner: [
		{
			label: 'Παραγγελίες',
			key: RoutesNames.LIVE_ORDERS,
			icon: icons['Παραγγελίες'],
		},
		{
			label: 'Κατάλογος',
			key: RoutesNames.CATALOG,
			icon: icons['Κατάλογος'],
		},
		{
			label: 'Ιστορικό',
			key: RoutesNames.HISTORY,
			icon: icons['Ιστορικό'],
		},
		{
			label: 'Κουπόνια',
			key: RoutesNames.COUPONS,
			icon: icons['Κουπόνια'],
		},
		{
			label: 'Κατάστημα',
			key: RoutesNames.MY_SHOP,
			icon: icons['Κατάστημα'],
		},
	],
};
