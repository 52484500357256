// if (import.meta.env.DEV) await import('./scan');
// import './tailwind.css';
import './index.css';
import Router from './router';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './utils/query-client';
import 'react-phone-number-input/style.css';
import './spinners.css';

const App = () => {
	return (
		<BrowserRouter>
			<QueryClientProvider client={queryClient}>
				<Router />
				<Toaster />
			</QueryClientProvider>
		</BrowserRouter>
	);
};

export default App;
