import { useApplyPadding } from './useApplyPadding';
import { useWindowSize } from './useWindowSize';

export const useRouteStuff = ({ type }) => {
	useApplyPadding({ type });

	const { width, height, dvh, innerHeight } = useWindowSize();
	const h = height - (width < 740 ? 64 : 50) - 1;
	return {
		dvh,
		innerHeight,
		style: {
			display: 'flex',
			flexDirection: 'column',
			// height: 'auto',
			// padding: 24,
			// paddingLeft: 24,
			// paddingRight: 24,
			// paddingTop: 16,
			overflowX: 'auto',
			// maxHeight: 1008,
			// maxHeight: 1034,
			maxHeight: h > 0 ? h : '100%',
			// maxHeight: '100%',

			overflowY: 'auto',
		},
	};
};
