import React, { useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import PhoneInput from 'react-phone-number-input';
import Address from './Address';
import UploadImage from '../../../UI/UploadImage';
import User from './User';
import { getRandomColor } from '@/utils/getRandomColor';
import { Button } from '@/components/UI/Button/index.jsx';
import { toast } from '@/utils/toast';
import { useMainStore } from '@/store/main.store';
import { get, post, postGlobal, put } from '@/utils/http';
import { MultiSelect } from '@/components/UI/MultiSelect';
import { useKeyQuery } from '@/utils/hooks/useKeyQuery';
import { useWindowSize } from '@/utils/hooks/useWindowSize';
import Schedule from './Schedule';

const { TextArea } = Input;

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
	required: '${label} is required!',
	types: {
		email: '${label} is not a valid email!',
	},
	number: {
		range: '${label} must be a valid mobile number! (10 digits)',
	},
};

const initialState = {
	user: {},
	address: {},
	name: '',
	phone: '',
	cuisines: [],
	files: [],
	comment: '',
	schedule: [
		['08:00', '22:00'],
		['08:00', '22:00'],
		['08:00', '22:00'],
		['08:00', '22:00'],
		['08:00', '22:00'],
		['08:00', '22:00'],
		['08:00', '22:00'],
	],
};

const Info = ({ existingShopData }) => {
	// const { user } = useSelector(state => state);
	const { user } = useMainStore();

	const isNew = window.location.pathname.split('/')[2] === 'add';

	const { width } = useWindowSize();
	const [loadingInit, setLoadingInit] = useState(!existingShopData && !isNew);

	// const [optionsCuisines, setOptionsCuisines] = useState([]);

	const [shopData, setShopData] = useState(() => existingShopData || initialState);

	const savingRef = useRef(false);

	// const queryClient = useQueryClient();

	// useEffect(() => {
	// 	// queryClient.invalidateQueries({ refetchType: 'all' });
	// 	queryClient.removeQueries();
	// }, []);

	// useEffect(() => {
	// 	if (isNew || shopData?.name) {
	// 		setLoadingInit(false);
	// 		return;
	// 	}

	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	// TODO DEN EXOUME CACHE OMOS ETSI MAN MOU
	// 	getInitialData();
	// 	// get('')
	// 	// 	.then(response => {
	// 	// 		setShopData({
	// 	// 			...response.data,
	// 	// 			schedule: response.data.schedule.map(item =>
	// 	// 				item.length === 0 ? ['0000', '0000'] : item,
	// 	// 			),
	// 	// 			scheduleOpen: response.data.schedule.map(item => item.length !== 0),
	// 	// 			areas: response.data.areas.map(item => ({
	// 	// 				...item,
	// 	// 				minimumOrder: !item.minimumOrder
	// 	// 					? item.minimumOrder
	// 	// 					: item.minimumOrder / 100,
	// 	// 				deliveryCost: !item.deliveryCost
	// 	// 					? item.deliveryCost
	// 	// 					: item.deliveryCost / 100,
	// 	// 				fillColor: getRandomColor(),
	// 	// 			})),
	// 	// 		});
	// 	// 		setLoadingInit(false);

	// 	// 		// console.log('haaaa', response?.data?.status);
	// 	// 	})
	// 	// 	.catch(err => {
	// 	// 		console.log('shop info err', err);
	// 	// 		toast.error(err);
	// 	// 	});
	// }, []);

	// const getInitialData = async () => {
	// 	try {
	// 		const res = await get('');

	// 		if (!res?.data) {
	// 			throw Error('-');
	// 		}
	// 		setShopData({
	// 			...res.data,
	// 			schedule: res.data.schedule.map(item =>
	// 				item.length === 0 ? ['0000', '0000'] : item,
	// 			),
	// 			scheduleOpen: res.data.schedule.map(item => item.length !== 0),
	// 			areas: res.data.areas.map(item => ({
	// 				...item,
	// 				minimumOrder: !item.minimumOrder ? item.minimumOrder : item.minimumOrder / 100,
	// 				deliveryCost: !item.deliveryCost ? item.deliveryCost : item.deliveryCost / 100,
	// 				fillColor: getRandomColor(),
	// 			})),
	// 		});
	// 		setLoadingInit(false);
	// 	} catch (err) {
	// 		console.log('INFO effect shopdata err', err);
	// 		toast.error(err);
	// 	}
	// };

	const {
		data: shopDataFetched = {},
		// isError,
		// isPending,
		// invalidateKeyQueries,
	} = useKeyQuery({
		key: '',
		// url: '/',
		global: false,
		enabled: user?.role !== 0,
	});

	useEffect(() => {
		if (!shopDataFetched?.id) {
			return;
		}

		setShopData({
			...shopDataFetched,
			schedule: shopDataFetched.schedule.map(item =>
				item.length === 0 ? ['0000', '0000'] : item,
			),
			scheduleOpen: shopDataFetched.schedule.map(item => item.length !== 0),
			areas: shopDataFetched.areas.map(item => ({
				...item,
				minimumOrder: !item.minimumOrder ? item.minimumOrder : item.minimumOrder / 100,
				deliveryCost: !item.deliveryCost ? item.deliveryCost : item.deliveryCost / 100,
				fillColor: getRandomColor(),
			})),
		});
		setLoadingInit(false);
	}, [shopDataFetched]);

	const {
		data: { data: cuisines } = {},
		isError,
		isPending,
		// invalidateKeyQueries,
	} = useKeyQuery({
		key: 'cuisines',
		global: true,
	});

	const onInfoUpdate = () => {
		// den stelno user, cuisines, files, comment
		// const isAdd = window.location.pathname.split('/')[2] === 'add';

		const hideLoading = () => (savingRef.current = false);

		try {
			toast.promise(
				async () => {
					savingRef.current = true;

					const action = isNew ? postGlobal : put;
					await action(isNew ? '/shops' : '', {
						...shopData,
						user: {
							...shopData.user,
							language: user.language,
						},
						files: shopData?.files?.map(file => file.id),
						cuisines: shopData?.cuisines?.[0]?.id
							? shopData?.cuisines?.map(cuisine => cuisine.id)
							: shopData?.cuisines,
						// user: {
						// 	...shopData.user,
						// 	language: user.language,
						// },
						schedule: shopData.schedule.map((day, index) => {
							// if (day[0] === '0000' && day[1] === '0000') {
							// 	return [];
							// }
							if (!shopData.scheduleOpen?.[index]) {
								return [];
							}
							return day.map(time => time.replace(':', ''));
						}),
						scheduleOpen: undefined,
						comment: shopData.comment,
						areas: !shopData.areas
							? shopData.areas
							: shopData.areas.map(item => {
									const pmo = Number(
										`${item.minimumOrder}`?.replaceAll(',', '.'),
									);
									if (!isFinite(pmo)) {
										// toast.error('Η τιμή πρέπει να είναι νούμερο');
										throw new Error('Η τιμή πρέπει να είναι νούμερο');
									}
									const pdc = Number(
										`${item.deliveryCost}`?.replaceAll(',', '.'),
									);
									if (!isFinite(pdc)) {
										// toast.error('Η τιμή πρέπει να είναι νούμερο');
										throw new Error('Η τιμή πρέπει να είναι νούμερο');
									}
									return {
										...item,
										minimumOrder: Math.round(pmo * 100),
										deliveryCost: Math.round(pdc * 100),
									};
								}),
					});

					hideLoading();

					// toast.success('Shop updated successfully');
				},
				{
					success: isNew ? 'Το κατάστημα δημιουργήθηκε!' : undefined,
					errorCb: hideLoading,
				},
			);
		} catch (err) {
			console.log('onInfoUpdate err', err);
			toast.error(err);
		}
	};

	const connectWithMollie = async () => {
		try {
			const res = await get(
				'/connect/',
				{},
				{
					headers: {
						'Access-Control-Allow-Origin': '*',
					},
				},
			);

			// console.log('reee', res);
			if (res?.data?.url) {
				window.location.replace(res.data.url);
			}
			// navigate();
		} catch (err) {
			console.log('connectWithMollie err:', err);
		}
	};

	const onboarding = async () => {
		try {
			const res = await get(
				'/onboarding/',
				{},
				{
					headers: {
						'Access-Control-Allow-Origin': '*',
					},
				},
			);

			if (!res?.data) {
				return;
			}
			const { canReceivePayments, canReceiveSettlements } = res?.data || {};

			// TO ENABLED PAYMENTS EINAI OTAN KAI TA 2 EINAI TRUE
			// TO PAYMENTSCONNECTED OTAN EXOUME KANEI TIN SINDESI ME TI MOLLIE
			const payments = `Payments: ${canReceivePayments ? 'Ναι' : 'Οχι'}`;
			toast[canReceivePayments ? 'success' : 'error'](payments);

			const settlements = `Settlements: ${canReceiveSettlements ? 'Ναι' : 'Οχι'}`;
			toast[canReceivePayments ? 'success' : 'error'](settlements);

			// if (res?.data?.url) {
			// 	window.location.replace(res.data.url);
			// }
			// navigate();
		} catch (err) {
			console.log('onboarding err:', err);
		}
	};

	const resendWelcomeEmailForPassword = async () => {
		try {
			const res = await post(
				'/set-password/',
				{},
				{
					headers: {
						'Access-Control-Allow-Origin': '*',
					},
				},
			);

			console.log('reeee', res);
			// if (res?.data?.url) {
			// 	window.location.replace(res.data.url);
			// }
			// navigate();
		} catch (err) {
			console.log('resendWelcomeEmailForPassword err:', err);
		}
	};

	const [form] = Form.useForm();
	const isAdmin = user?.role === 0;

	// const filteredData = getSearchFilteredData(cuisinesOptions, 'name', searchText);

	if (loadingInit) {
		return null;
	}

	return (
		<>
			{/* {!shopData?.paymentsConnected && (
				<Button variant='outline' onClick={connectWithMollie}>
					Connect with Mollie
				</Button>
			)} */}
			{isAdmin ? (
				!isNew ? (
					<>
						<div className='w-full flex flex-row flex-wrap items-center gap-3 mt-3 mb-6'>
							{shopData?.paymentsConnected && <span>Πληρωμές οκ</span>}

							<Button variant='outline' onClick={resendWelcomeEmailForPassword}>
								Resend Password Email
							</Button>
						</div>
					</>
				) : null
			) : !shopData?.paymentsConnected ? (
				<Button variant='outline' onClick={connectWithMollie}>
					Connect with Mollie
				</Button>
			) : null}

			<Button variant='outline' onClick={onboarding}>
				Check Mollie status
			</Button>

			<Form
				form={form}
				name='shop'
				className='w-full'
				style={{
					paddingBottom: isAdmin ? '3.75rem' : 60,
				}}
				// style={{
				// 	width: '100%',
				// }}
				validateMessages={validateMessages}
			>
				<div className='flex flex-row flex-wrap gap-3 mt-1 mb-4 '>
					<div className='flex flex-col' style={{ marginRight: 6 }}>
						<h4>
							<span style={{ color: 'red' }}>*</span>
							Ονομα
						</h4>
						<Input
							style={{ width: 200 }}
							value={shopData?.name}
							onChange={({ target: { value } }) => {
								setShopData({
									...shopData,
									name: value,
								});
							}}
						/>
					</div>

					<div className='flex flex-col' style={{ marginRight: 6 }}>
						<h4>
							<span style={{ color: 'red' }}>*</span>
							Τηλέφωνο
						</h4>
						<PhoneInput
							value={shopData?.phone}
							defaultCountry='GR'
							style={{
								width: 200,
								border: '1px solid #cecece',
								borderRadius: 6,
								height: 24,
							}}
							onChange={value => {
								setShopData({ ...shopData, phone: value });
							}}
						/>
					</div>
					<div className='flex flex-col' style={{ marginRight: 6 }}>
						<Schedule setShopData={setShopData} shopData={shopData} />
					</div>
				</div>
				<Address shopData={shopData} setShopData={setShopData} />

				{user?.role === 0 && (
					<div className='flex flex-col mb-4'>
						{/* <h3>{translations.comment}</h3> */}
						<h3>Σημειώσεις</h3>
						<TextArea
							rows={4}
							style={{ width: 260 }}
							value={shopData?.comment}
							onChange={({ target: { value } }) => {
								setShopData({
									...shopData,
									comment: value,
								});
							}}
						/>
					</div>
				)}

				{isAdmin && (
					<>
						<UploadImage
							parentData={shopData}
							setParentData={setShopData}
							array={['logo', 'cover']}
						/>

						<div className='text-2xl mt-10 mb-3'>Κουζίνες</div>

						{!isPending && (
							<MultiSelect
								className='w-110 max-w-[85vw]'
								placeholder='Κουζίνες'
								// options={optionsModifiers?.map(it => ({
								options={cuisines?.map(it => ({
									label: it.name,
									value: it.id,
								}))}
								onValueChange={value => {
									setShopData({
										...shopData,
										cuisines: cuisines.filter(it =>
											value.find(v => v === it.id),
										),
									});
								}}
								defaultValue={shopData?.cuisines?.map?.(it => it.id)}
								value={shopData?.cuisines?.map?.(it => ({
									value: it.id,
									label: it.name,
								}))}
								variant='inverted'
								animation={2}
								maxCount={3}
							/>
						)}

						<div className='text-2xl mt-10 mb-3'>Πληροφορίες Διαχειριστή</div>

						<User shopData={shopData} setShopData={setShopData} />
					</>
				)}
			</Form>
			{isAdmin && (
				<div
					className='fixed w-full flex flex-row flex-wrap justify-center items-center gap-3 py-3 bg-slate-200  left-0'
					style={{ bottom: width < 740 ? '3.75rem' : 0 }}
				>
					<Button onClick={onInfoUpdate}>Αποθήκευση Καταστήματος</Button>
				</div>
			)}
		</>
	);
};

export default Info;
