import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import LoginHOC from '../containers/auth/LoginHOC';
import { useCustomNavigation } from '@/utils/hooks/useCustomNavigation';
import { useMainStore } from '@/store/main.store';
import AdminRoutes from './AdminRoutes';
import OwnerRoutes from './OwnerRoutes';
// TODO TA KANO COMMENT GIA NA DOUME TO PROVLIMA OTAN MPAINOUME STO SITE KAI LEEI CAN
// CANNOT LOAD DYNAMICALLY LOADED CONTECT (CORRUPRTED CONTENT)
// const AdminRoutes = React.lazy(() => import('./AdminRoutes.jsx'));
// const OwnerRoutes = React.lazy(() => import('./OwnerRoutes.jsx'));

const Router = () => {
	const { user } = useMainStore();

	// const navigate = useNavigate();
	// const location = useLocation();
	const { type: navigationType, element: navigationElement } = useCustomNavigation();

	// useEffect(() => {
	// 	// setSelectedShopID(parseInt(location.pathname.split('/')[2]));
	// 	// setUser({
	// 	// 	...user,
	// 	// 	selectedShopID: !location?.pathname?.includes('/shop')
	// 	// 		? null
	// 	// 		: parseInt(location.pathname.split('/')[2]),
	// 	// });
	// }, [location.pathname]);

	// useEffect(() => {
	// 	if (!user && location.pathname !== '/') {
	// 		// toast.blank('I WILL NAVIGATE, NOTIFY ME IF YOU SEE THIS PLZZZ AAAA');
	// 		toast.error('tha ekana logout APO ROUTER INDEX');
	// 		// navigate('/');
	// 	}
	// }, [user]);

	return (
		<ConfigProvider
			theme={{
				token: {
					fontSize: 14,
					// fontFamily: "'Noto Sans', sans-serif",
					fontFamily: 'averta-regular',
					colorPrimary: '#3ae867',
					colorText: 'rgb(40,40,40)',
					colorTextDescription: 'rgb(40,40,40)',
					colorFillContent: 'grey',
					colorTextDisabled: 'grey',
					colorIcon: 'rgb(40,40,40)',
					colorIconHover: 'rgb(40,40,40)',
				},
				components: {
					Input: {
						colorBgContainer: 'transparent',
						colorBorder: 'rgb(40,40,40)',
						colorTextPlaceholder: 'rgb(40,40,40)',
					},
					Typography: {
						colorText: 'rgb(40,40,40)',
						colorTextHeading: 'rgb(40,40,40)',
						fontSizeHeading1: 28,
					},
				},
			}}
		>
			{navigationType === 'menu' && navigationElement}

			{!user?.token && !user?.language ? (
				<Routes>
					<Route path='*' element={<LoginHOC />} />
				</Routes>
			) : user?.role === 0 ? (
				<Suspense>
					<AdminRoutes />
				</Suspense>
			) : user?.role === 1 ? (
				<Suspense>
					<OwnerRoutes />
				</Suspense>
			) : null}

			{navigationType === 'bottom-navigation' && navigationElement}

			{/* {user?.role === 1 && <SingleFullScreenOrder />} */}
		</ConfigProvider>
	);
};

export default Router;
