import React, { useEffect, useRef, useState } from 'react';
import UsersTable from '../../components/users/UserView';
import { toast } from '@/utils/toast';
import { post, putGlobal } from '@/utils/http';
import { useKeyQuery } from '@/utils/hooks/useKeyQuery';

const initialState = {
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	role: 2,
};

const UsersHOC = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(62);
	const [totalPages, setTotalPages] = useState(0);

	const [open, setOpen] = useState(false);

	const [item, setItem] = useState({});

	const savingRef = useRef(false);

	const {
		data: { data: users } = {},
		isError,
		isLoading,
		invalidateKeyQueries,
	} = useKeyQuery({
		key: 'users',
		global: true,
	});

	useEffect(() => {
		if (open && !item?.id) {
			setItem(initialState);
		}
	}, [open]);

	const onFinish = () => {
		if (savingRef.current) {
			return;
		}

		if (!item?.firstName) {
			toast.error('Το όνομα είναι κενό');
			return;
		}
		if (!item?.lastName) {
			toast.error('Το lastName είναι κενό');
			return;
		}
		if (!item?.email) {
			toast.error('Το email είναι κενό');
			return;
		}
		if (!item?.phone) {
			toast.error('Το κινητό είναι κενό');
			return;
		}

		try {
			const hideLoading = () => (savingRef.current = false);

			toast.promise(
				async () => {
					savingRef.current = true;

					const data = {
						firstName: item.firstName,
						lastName: item.lastName,
						email: item.email,
						phone: item.phone,
						language: 'el',
						role: item.role,
					};

					await putGlobal(`/users/${item.id}`, data);
					setOpen(false);
					setItem(null);

					invalidateKeyQueries();

					hideLoading();
				},
				{ errorCb: hideLoading },
			);
		} catch (err) {
			console.log(err);
			toast.error(err);
		}
	};

	const onOpenNew = () => {
		setItem(initialState);
		setOpen(true);
	};

	const resendWelcomeEmailForPassword = async () => {
		try {
			const res = await post(
				'/set-password/',
				{},
				{
					headers: {
						'Access-Control-Allow-Origin': '*',
					},
				},
			);

			console.log('reeee', res);
			// if (res?.data?.url) {
			// 	window.location.replace(res.data.url);
			// }
			// navigate();
		} catch (err) {
			console.log('resendWelcomeEmailForPassword err:', err);
		}
	};

	return (
		<UsersTable
			data={users || []}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
			onFinish={onFinish}
			onOpenNew={onOpenNew}
			open={open}
			setOpen={setOpen}
			item={item}
			setItem={setItem}
			resendWelcomeEmailForPassword={resendWelcomeEmailForPassword}
			isError={isError}
			isLoading={isLoading}
		/>
	);
};

export default UsersHOC;
