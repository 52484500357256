import t from 'react-hot-toast';

const styleSuccess = {
	duration: 3500,
	icon: '🚀',
};

const styleBlank = {
	duration: 3500,
	icon: '🧐',
};

const styleError = {
	duration: 4500,
	icon: '💀',
};

export const toast = {
	success: text => {
		try {
			t.success(text, {
				...styleSuccess,
			});
		} catch (err) {
			t.error('toast err ss', ...styleError);
			console.log(err);
		}
	},
	promise: (fun, { loading, success, errorCb } = {}) => {
		if (typeof fun !== 'function') {
			return;
		}
		t.promise(
			fun,
			{
				loading: loading || '', // 'Saving...',
				success: success || 'Η αλλαγή έγινε!', // : <b>Settings saved!</b>,
				// error: err => console.log(v), // : <b>Could not save.</b>,
				error: err => {
					if (typeof errorCb === 'function') {
						errorCb();
					}
					console.log('inside toast wrapper err', err, fun);
					toast.error(err);
					return '';
				},
			},
			{
				style: {
					minWidth: '120px',
				},
				success: styleSuccess,
				error: styleError,
			},
		);
	},
	blank: text => {
		t(text, {
			...styleBlank,
		});
	},
	loading: text => {
		t.loading(text, {
			...styleBlank,
			duration: 400000,
		});
	},
	error: (obj = {}) => {
		// console.log('ooo', obj?.response?.data?.message);
		let text;
		try {
			if (obj?.response?.data?.message) {
				text = obj?.response?.data?.message;
			} else if (obj?.message) {
				text = obj.message;
			} else if (typeof obj === 'string') {
				text = obj;
			} else {
				text = `Los problemos${typeof obj.error === 'string' || obj?.code ? ':' : ''}${
					typeof obj.error === 'string' ? ` ${obj.error}` : ''
				}${obj?.code ? ` (${obj.code})` : ''}`;
			}
			t.error(text, {
				...styleError,
			});
		} catch (err) {
			t.error('toast err e', { ...styleError });

			console.log(err);
		}
	},
};
