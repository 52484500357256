import { Image } from '@unpic/react';
import { ArrowUpDown } from 'lucide-react';
import { Button } from '@/components/UI/Button';
import { Checkbox } from '@/components/UI/checkbox';
import { SuperTable } from './SuperTable';
import { useMemo } from 'react';
import { TableLoadingError } from './TableLoadingError';
import { HeaderTable } from './HeaderTable';

export const NewShopsTable = ({ data, isError, isLoading, onOpenNew, onRowClick }) => {
	const columns = useMemo(() => {
		return [
			{
				id: 'select',
				header: ({ table }) => (
					<div className='flex justify-center items-center'>
						<Checkbox
							checked={
								table.getIsAllPageRowsSelected() ||
								(table.getIsSomePageRowsSelected() && 'indeterminate')
							}
							onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
							aria-label='Select all'
						/>
					</div>
				),
				cell: ({ row }) => (
					<div
						onClick={e => e.stopPropagation()}
						className='flex justify-center items-center '
					>
						<Checkbox
							checked={row.getIsSelected()}
							onCheckedChange={value => row.toggleSelected(!!value)}
							aria-label='Select row'
						/>
					</div>
				),
				enableSorting: false,
				enableHiding: false,
			},
			{
				accessorKey: 'id',
				header: 'ID',
			},
			{
				accessorKey: 'logo',
				header: 'Logo',
				cell: ({ row }) => {
					const logo = row.original?.files?.find(f => f.type === 'logo');
					if (!logo) {
						return null;
					}
					return (
						<div className='flex justify-center items-center w-12 h-12'>
							<Image
								src={`${import.meta.env.VITE_SPACE_URL}${logo?.name}`}
								// layout='fixed'
								// objectFit='cover'
								width={200}
								height={200}
								aspectRatio={1}
							/>
						</div>
					);
				},
			},
			{
				id: 'name',
				cell: ({ row }) => {
					return (
						<div className='max-w-[25vw]'>
							<div className=' whitespace-normal'>{row.original.name}</div>
						</div>
					);
				},
				header: ({ column }) => {
					return (
						<Button
							variant='ghost'
							onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
							className='!px-0'
						>
							Όνομα
							<ArrowUpDown className='ml-2 h-4 w-4' />
						</Button>
					);
				},
			},
			{
				accessorKey: 'phone',
				header: 'Τηλέφωνο',
			},
		];
	}, []);

	return (
		<TableLoadingError isError={isError} isLoading={isLoading}>
			<HeaderTable onOpenNew={onOpenNew} title='Καταστήματα' />
			<SuperTable
				data={data}
				columns={columns}
				searchKeys={['name', 'phone']}
				deletePath='/shops'
				onRowClick={onRowClick}
			/>
		</TableLoadingError>
	);
};
