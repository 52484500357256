import React from 'react';
// import { UsersColumns } from '../../columns/UsersColumns';
// import MyTable from '../UI/MyTable';
import UserForm from './UserForm';
import { MyDialog } from '../UI/MyDialog';
import { NewUsersTable } from '../tables/NewUsersTable';

const UsersView = ({
	data,
	pageNumber,
	setPageNumber,
	pageSize,
	setPageSize,
	totalPages,
	onFinish,
	open,
	setOpen,
	item,
	setItem,
	resendWelcomeEmailForPassword,
	isError,
	isLoading,
	onOpenNew,
}) => {
	return (
		<div className='flex flex-col'>
			<NewUsersTable
				data={data}
				isError={isError}
				isLoading={isLoading}
				onOpenNew={onOpenNew}
				onRowClick={row => {
					setOpen(true);
					setItem(row.original);
				}}
			/>
			<MyDialog
				open={open}
				title='Χρήστης'
				description={item?.id ? `ID: ${item?.id}` : undefined}
				onOpenChange={setOpen}
				onSubmit={onFinish}
				submitText={item?.id ? undefined : 'Προσθήκη'}
			>
				<UserForm
					item={item}
					setItem={setItem}
					resendWelcomeEmailForPassword={resendWelcomeEmailForPassword}
				/>
			</MyDialog>
		</div>
	);
};

export default UsersView;
