import { useEffect, useRef } from 'react';

export const useSound = file => {
	const audioRef = useRef(null);
	const isPlayingRef = useRef(false);

	useEffect(() => {
		// Initialize the audio element
		audioRef.current = new Audio(file);
		audioRef.current.loop = true; // Enable looping

		// Cleanup on unmount
		return () => {
			if (audioRef.current) {
				audioRef.current.pause();
				audioRef.current.src = '';
				isPlayingRef.current = false;
			}
		};
	}, []);

	const play = () => {
		if (audioRef.current) {
			audioRef.current.play();
			isPlayingRef.current = true;
		}
	};

	const stop = () => {
		if (audioRef.current) {
			audioRef.current.pause();
			audioRef.current.currentTime = 0;
			isPlayingRef.current = false;
		}
	};

	return { play, stop, isPlayingRef };
};
