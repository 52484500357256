import { useEffect, useRef } from 'react';
import { useSockets } from './useSockets';
import { toast } from '../toast';
import { useLiveStore } from '@/store/live.store';
import { get, patch } from '@/utils/http';
import { socketSingleton } from './useSockets';

export const useLiveOrders = () => {
	// console.log('useLiveOrders');

	const {
		liveOrders,
		setLiveOrders,
		liveOrdersRequests,
		setLiveOrdersRequests,
		visibleIds,
		setVisibleIds,
		setShopOpen,
	} = useLiveStore();

	const lorRef = useRef(liveOrdersRequests);

	useEffect(() => {
		lorRef.current = liveOrdersRequests;
	}, [liveOrdersRequests]);

	useEffect(() => {
		fetchRequests();
		fetchPendingOrders();
	}, []);

	useEffect(() => {
		getStatus();
	}, []);

	const getStatus = async () => {
		try {
			const res = await get('/status');

			console.log('attempt gia sex socket');

			const doAction = () => {
				if (res?.data) {
					// dispatch(setShopOpen(true));
					setShopOpen(true);

					setTimeout(() => {
						socketSingleton?.connect?.();
					}, 1_500);
				} else {
					// dispatch(setShopOpen(false));
					setShopOpen(false);

					setTimeout(() => {
						socketSingleton?.disconnect?.();
					}, 1_500);
				}
			};

			try {
				doAction();
			} catch (err) {
				console.log('do action err: ', err);
				toast.error(err);
				doAction();
			}
		} catch (err) {
			console.log('err getStatus', err);
			toast.error(err);
		}
	};

	const fetchRequests = async () => {
		try {
			const res = await get(`/orders?status=0`);
			if (res?.data?.data) {
				setLiveOrdersRequests(res.data.data);
				setVisibleIds([...visibleIds, ...res.data.data.map(it => it.id)]);
			}
		} catch (err) {
			console.log('fetchRequests err:', err);
			toast.error(err);
		}
	};

	const fetchPendingOrders = async () => {
		try {
			const res = await get(`/orders?status=1`);
			if (res?.data?.data) {
				setLiveOrders(res.data.data);
			}
		} catch (err) {
			console.log('fetchPendingOrders err:', err);
			toast.error(err);
		}
	};
	// console.log('sa', socketSingleton);

	const onRemoveOrderRequest = (id, refOrders) => {
		const orders = refOrders || liveOrdersRequests;
		const idx = orders.findIndex(lor => lor.id === id);
		if (idx === -1) {
			return;
		}
		console.log('was ', idx, orders);

		const newLiveOrdersRequests = [...orders];
		newLiveOrdersRequests.splice(idx, 1);
		console.log('is ', newLiveOrdersRequests);

		setLiveOrdersRequests(newLiveOrdersRequests);
	};

	const onAccept = async (order, estimatedMinutes) => {
		try {
			await patch(`/orders/${order.id}/accept`, {
				estimatedMinutes,
				// name: item.name,
				// language: user.language,
			});
			// console.log(res);

			const idx = liveOrdersRequests.findIndex(lo => lo.id === order.id);
			console.log('idx', idx);
			if (idx === -1) {
				onRemoveOrderRequest(order.id);

				return;
			}

			const newLiveOrders = [...liveOrders];
			newLiveOrders.push(liveOrdersRequests[idx]);
			setLiveOrders(newLiveOrders);

			onRemoveOrderRequest(order.id);
			// setOpen(!open);
			// setItem({});
			toast.success('Success!');
		} catch (err) {
			console.log('ERRORRRR onaccept', err);
			if (err.response?.data?.code === 404) {
				toast.error('Η παραγγελία δεν υπάρχει ή έχει απαντηθεί');
				onRemoveOrderRequest(order.id);
				return;
			}
			toast.error(err);
		}
	};

	const onDecline = async (order, reasonStr, options = {}) => {
		// const { key } = reasonObj || {};
		// console.log(reasonObj);
		// return;
		// const reason = key && `${key}`;
		// console.log(order, reason);

		try {
			await patch(`/orders/${order.id}/decline`, {
				reason: reasonStr,
			});
			// console.log(response);
			onRemoveOrderRequest(order.id);

			// setOpen(!open);
			// setItem({});
			toast.success('Success!');

			if (typeof options.cb === 'function') {
				options.cb();
			}
		} catch (err) {
			console.log('ERRORRRR ondecline', err);

			if (err.response?.data?.code === 404) {
				toast.error('Η παραγγελία δεν υπάρχει ή έχει απαντηθεί');
				onRemoveOrderRequest(order.id);
				return;
			}
			// console.log('ERRORRRR', err.response?.data?.code);
			toast.error(err);
		}
	};

	const onOpenFullScreen = async orderId => {
		console.log('open full', orderId);
		try {
			const idx = liveOrdersRequests.findIndex(lor => lor.id === orderId);
			if (idx === -1) {
				return;
			}

			const newLiveOrdersRequests = [...liveOrdersRequests];
			// newLiveOrdersRequests[idx].seen = false;

			setLiveOrdersRequests(newLiveOrdersRequests);

			addToVisibleOrders(orderId);
		} catch (err) {
			console.log('ERRORRRR onOpenFullScreen', err);

			toast.error(err);
		}
	};

	const onSee = async orderId => {
		console.log('aa order', orderId);
		try {
			const idx = liveOrdersRequests.findIndex(
				lor => console.log('c', lor.id, orderId, lor.id === orderId) || lor.id === orderId,
			);
			if (idx === -1) {
				return;
			}

			const newLiveOrdersRequests = [...liveOrdersRequests];
			newLiveOrdersRequests[idx].seen = true;

			console.log('ooooooolo', newLiveOrdersRequests);

			setLiveOrdersRequests(newLiveOrdersRequests);

			const newVisibleIds = [...visibleIds];
			newVisibleIds.splice(newVisibleIds.findIndex(it => it === orderId));

			console.log('old', visibleIds, newVisibleIds);
			setVisibleIds(newVisibleIds);
		} catch (err) {
			console.log('ERRORRRR onSee', err);

			toast.error(err);
		}
	};

	const onAddNewRequest = newOrder => {
		const idx = liveOrders.findIndex(lo => lo.id === newOrder.id);
		if (idx !== -1) {
			console.log('new order exists', idx, liveOrders?.[idx]);
			return;
		}

		const newLiveOrdersRequests = [...liveOrders];
		newLiveOrdersRequests.push(newOrder);
		setLiveOrdersRequests(newLiveOrdersRequests);

		addToVisibleOrders(newOrder.id);
	};

	const addToVisibleOrders = orderId => {
		setVisibleIds([...visibleIds, orderId]);
	};

	useSockets({ onAddNewRequest });

	return {
		liveOrdersRequests,
		liveOrders,
		visibleIds,
		setVisibleIds,
		onRemoveOrderRequest,
		onAccept,
		onDecline,
		onOpenFullScreen,
		onSee,
	};
};
