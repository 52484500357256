import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
import UploadImage from '../../../UI/UploadImage';
import { Switch } from '@/components/UI/Switch';
import { MultiSelect } from '@/components/UI/MultiSelect';
import { MyNewSelect } from '@/components/UI/MyNewSelect';

const { TextArea } = Input;

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
	required: '${label} is required!',
	types: {
		email: '${label} is not a valid email!',
	},
	number: {
		range: '${label} must be a valid mobile number! (10 digits)',
	},
};
const ItemForm = ({ item, setItem, itemCategories, modifiers }) => {
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue({
			name: item?.name,
			description: item?.description,
			price: item?.price,
			available: item?.available,
			files: item?.files,
			category: item?.category,
			modifiers: item?.modifiers,
		});
	}, [item]);

	// const { get } = api();

	// // const [searchTextItemCategories, setSearchTextItemCategories] = useState('');
	// const [searchTextModifiers, setSearchTextModifiers] = useState('');
	// // const { modifierOptions, setModifierOptions } = useDataStore();

	// const [optionsItemCategories, setOptionsItemCategories] = useState([]);
	// const [optionsModifiers, setOptionsModifiers] = useState([]);

	// const [loadingItemCategories, setLoadingItemCategories] = useState(true);

	// console.log('item.category', item.category);
	return (
		<Form
			form={form}
			// {...layout}
			className='flex flex-col self-start max-w-full relative !z-[303203020]  '
			name='Item'
			validateMessages={validateMessages}
			layout='vertical'
		>
			<div className='flex flex-row flex-wrap justify-between gap-x-3'>
				<Form.Item
					style={{ position: 'relative', marginBottom: 6, zIndex: 999999999 }}
					name={['category']}
					label='Κατηγορία'
				>
					<div className='flex items-center max-w-44 relative !z-[303203023] '>
						<MyNewSelect
							withSearch
							onSelect={value => {
								setItem({
									...item,
									category: value,
								});
							}}
							// value={loadingItemCategories ? null : item?.category}
							value={item?.category ? `${item.category}` : item?.category}
							options={itemCategories?.map(it => ({
								label: it.name,
								value: `${it.id}`,
							}))}
						/>
					</div>
				</Form.Item>
				<div className='flex justify-end items-center gap-x-2'>
					<Form.Item
						// style={{ marginBottom: 10 }}
						name={['price']}
						label='Τιμή'
						className='w-20'
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input
							value={item?.price}
							// formatter={val => (val ? val.replace(',', '.') : '')}
							// parser={val => val.replace(',', '.')}
							onChange={e => {
								const value = e.target.value;

								setItem({
									...item,
									price: value,
								});
							}}
							style={{
								marginRight: 12,
								// marginBottom: 12,
								// width: '100%',
							}}
						/>
					</Form.Item>
					<Form.Item className='w-18' name={['available']} label='Διαθέσιμο'>
						<Switch
							checked={item?.available}
							onCheckedChange={value => {
								setItem({ ...item, available: value });
							}}
						/>
					</Form.Item>
				</div>
			</div>

			<Form.Item
				name={['name']}
				style={{ marginBottom: 6 }}
				label='Όνομα'
				rules={[
					{
						required: true,
					},
				]}
			>
				<Input
					value={item?.name}
					onChange={({ target: { value } }) => {
						setItem({ ...item, name: value });
					}}
					style={{
						marginRight: 12,
						width: '100%',
					}}
				/>
			</Form.Item>

			<Form.Item style={{ marginBottom: 14 }} name={['description']} label='Περιγραφή'>
				<TextArea
					autoSize='textarea'
					value={item?.description}
					onChange={({ target: { value } }) => {
						setItem({ ...item, description: value });
					}}
					style={{
						height: 20,
						marginRight: 12,
						// marginBottom: 12,
						width: '100%',
					}}
				/>
			</Form.Item>

			<Form.Item style={{ marginBottom: 10 }} name={['modifiers']} label='Επιλογές'>
				<MultiSelect
					placeholder='Επίλεξε γκρουπ'
					// options={optionsModifiers?.map(it => ({
					options={modifiers?.map(it => ({
						label: it.name,
						value: it.id,
					}))}
					onValueChange={value => {
						setItem({
							...item,
							modifiers: value,
							// modifiers: modifiers.filter(it => value.find(v => v === it.id)),
						});
					}}
					defaultValue={item?.modifiers?.map?.(it => it.id)}
					value={item?.modifiers?.map?.(it => it.id)}
					variant='inverted'
					animation={2}
					maxCount={3}
				/>
			</Form.Item>

			<Form.Item name={['files']} label='Εικόνα'>
				<UploadImage parentData={item} setParentData={setItem} array={['main']} />
			</Form.Item>
		</Form>
	);
};
export default ItemForm;
