import { useWindowSize } from '@/utils/hooks/useWindowSize';
import { Button } from '../UI/Button';
import { cn } from '@/lib/utils';

export const HeaderTable = ({ title, onOpenNew, extraButtons }) => {
	const { width } = useWindowSize();
	return (
		<div
			className={cn(
				width < 740 && title
					? 'justify-between'
					: width < 740 && !title
						? 'justify-end '
						: 'justify-end',
				'flex items-center gap-x-4 py-4',
			)}
		>
			{width < 740 && title && <h2 className='font-bold text-2xl'>{title}</h2>}
			<div className='flex flex-row gap-x-4'>
				{onOpenNew && <Button onClick={onOpenNew}>Προσθήκη</Button>}
				{extraButtons && <span>{extraButtons}</span>}
			</div>
		</div>
	);
};
