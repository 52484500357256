import { useEffect, useState } from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';
import { Button } from '../Button';
import { Popover, PopoverContent, PopoverTrigger } from '../popover';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '../command';
import { cn } from '@/lib/utils';
import { getSearchFilteredData } from '@/utils/filterFunctions';

export const MyNewSelect = ({ options, value, onSelect, withSearch }) => {
	const [open, setOpen] = useState(false);
	const [searchText, setSearchText] = useState('');

	useEffect(() => {
		if (!open) {
			setSearchText('');
		}
	}, [open]);

	const handleInputKeyDown = event => {
		setSearchText(event);
	};

	// const filteredOptions = getSearchFilteredData(options, 'label', searchText);

	const filteredOptions = !searchText
		? options
		: getSearchFilteredData(options, 'label', searchText);

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button variant='outline' role='combobox' className='w-[200px] justify-between'>
					{value ? options?.find(option => option.value === value)?.label : ''}
					<ChevronsUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
				</Button>
			</PopoverTrigger>
			<PopoverContent className='w-[200px] p-0 z-[3000000000]'>
				<Command
					filter={v => filteredOptions.some(fit => fit.label === v)}
					// listbox={{ filter: () => true }}
				>
					{withSearch && (
						<CommandInput
							value={searchText}
							placeholder='Αναζήτηση...'
							onValueChange={handleInputKeyDown}
						/>
					)}
					<CommandList>
						<CommandEmpty></CommandEmpty>
						<CommandGroup>
							{options?.map?.(option => (
								<CommandItem
									key={option.value}
									value={option.value}
									className='cursor-pointer'
									onSelect={value => {
										onSelect(
											filteredOptions?.find(
												it =>
													// console.log('ccc', value, it.value) ||
													it.value == value,
											)?.value,
										);
										setOpen(false);
									}}
								>
									<Check
										className={cn(
											'mr-2 h-4 w-4',
											value === option.value ? 'opacity-100' : 'opacity-0',
										)}
									/>
									{option.label}
								</CommandItem>
							))}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
};
