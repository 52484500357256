import React, { useEffect, useRef, useState } from 'react';
import ItemsView from '../../../../components/store/shop/items/ItemsView';
import { toast } from '@/utils/toast';
import { useMainStore } from '@/store/main.store';
import { post, put } from '@/utils/http';
import { useKeyQuery } from '@/utils/hooks/useKeyQuery';

const initialState = {
	name: '',
	category: '',
	price: '',
	description: '',
	available: true,
	files: [],
	modifiers: [],
};

const ItemsHOC = ({
	hideTable,
	itemId,
	open: customOpen,
	setOpen: customSetOpen,
	onFinishSuccessCb,
}) => {
	const { user } = useMainStore();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(700);
	const [totalPages, setTotalPages] = useState(0);
	const [item, setItem] = useState(initialState);
	const [open, setOpen] = useState(false);

	const savingRef = useRef(false);

	const {
		data: { data: items } = {},
		isError,
		// isFetching, // auto mallon to kanei kai sto invalidate
		isPending, // auto oxi
		invalidateKeyQueries,
	} = useKeyQuery({
		key: 'items',
		global: false,
		pageSize,
	});
	const { data: { data: itemCategories } = {} } = useKeyQuery({
		key: 'item-categories',
		global: false,
	});
	const { data: { data: modifiers } = {} } = useKeyQuery({ key: 'modifiers', global: false });

	useEffect(() => {
		if (!itemId || !items) {
			return;
		}
		const item = items.find(it => it.id === itemId);
		if (!item) {
			console.log(
				'COULDNT FIND ITEM',
				itemId,
				'length is',
				items?.length,
				items?.length && items.map(it => it.id),
			);
			return;
		}
		setItem({ ...item, price: item.price / 100 });
	}, [itemId, items]);

	useEffect(() => {
		if (open && !item?.id) {
			setItem(initialState);
		}
	}, [open]);

	const onFinish = () => {
		if (savingRef.current) {
			return;
		}

		try {
			const p = Number(`${item.price}`?.replaceAll(',', '.'));
			if (!isFinite(p)) {
				toast.error('Η τιμή πρέπει να είναι νούμερο');
				return;
			}

			const hideLoading = () => (savingRef.current = false);

			toast.promise(
				async () => {
					savingRef.current = true;

					const data = {
						name: item.name,
						shop: user?.shop || undefined,
						language: user.language,
						category: item.category ? +item.category : item.category,
						price: Math.round(p * 100),
						available: !!item.available,
						files: !item?.files ? [] : item.files?.map(file => file.id),
						// files: item.id
						// 	? item.files?.[0]?.id
						// 		? item.files?.map(file => file.id)
						// 		: item?.files
						// 	: item.files?.map(file => file.id) || [],
						modifiers: !item.modifiers
							? []
							: item.modifiers?.[0]?.id
								? item.modifiers.map(it => it.id)
								: item.modifiers,
						description: item.description || '',
					};

					console.log('eeee data', data);
					// return;
					const action = item.id ? put : post;
					const url = `/items/${item?.id || ''}`;

					await action(url, data);
					setOpen(false);
					setItem({ initialState });

					if (typeof onFinishSuccessCb === 'function') {
						onFinishSuccessCb();
					}

					// queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

					invalidateKeyQueries();

					hideLoading();
				},
				{ errorCb: hideLoading },
			);
		} catch (err) {
			console.log('itemshoc finish', err);
			toast.error(err);
		}
	};

	const onOpenNew = () => {
		setItem(initialState);
		setOpen(true);
	};

	return (
		<ItemsView
			hideTable={hideTable}
			data={Array.isArray(items) ? items.map(it => ({ ...it, price: it.price / 100 })) : []}
			itemCategories={itemCategories}
			modifiers={modifiers}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
			item={item}
			setItem={setItem}
			onFinish={onFinish}
			onOpenNew={onOpenNew}
			open={customOpen || open}
			setOpen={customSetOpen || setOpen}
			isError={isError}
			isLoading={isPending}
		/>
	);
};

export default ItemsHOC;
