import { useState, useLayoutEffect } from 'react';

export const useWindowSize = () => {
	const [size, setSize] = useState({
		width: undefined,
		height: undefined,
	});

	const [dvh, setDvh] = useState(-1);
	const [innerHeight, setInnerHeight] = useState(-1);

	useLayoutEffect(() => {
		const handleResize = () => {
			setSize({
				width: window.innerWidth,
				// height: window.visualViewport?.height || window.innerHeight,
				height: parseInt(window.visualViewport?.height),
				// height: parseInt(window.innerHeight),
			});
			setDvh(parseInt(window.visualViewport?.height));
			setInnerHeight(parseInt(window.innerHeight));
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return { ...size, dvh, innerHeight };
};
