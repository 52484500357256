import { Input } from 'antd';
import { Switch } from '@/components/UI/Switch';

const ModifierOptionsForm = ({ edit, item, setItem, onFinish, user }) => {
	return (
		<div
			className='flex flex-col justify-around items-start gap-x-2'
			style={{ marginBottom: 12 }}
		>
			<div className='w-full flex flex-col'>
				<span>
					<sup style={{ marginRight: 4, color: 'red' }}>*</sup>
					Όνομα
				</span>
				<Input
					value={item?.name || null}
					onChange={e => {
						setItem({
							...item,
							name: e.target.value,
						});
					}}
				/>
			</div>
			<div className='w-full flex flex-col'>
				<span>
					<sup style={{ marginRight: 4, color: 'red' }}>*</sup>
					Εσωτερικό Όνομα
				</span>
				<Input
					value={item?.internalName || null}
					onChange={e => {
						setItem({
							...item,
							internalName: e.target.value,
						});
					}}
				/>
			</div>
			<div className='flex flex-col'>
				<span>Τιμή</span>
				<Input
					value={item?.price}
					onChange={e => {
						const value = e.target.value;

						setItem({
							...item,
							price: value,
						});
					}}
				/>
			</div>

			<div className='flex flex-col'>
				<span>Διαθέσιμο</span>
				<Switch
					checked={item?.available || null}
					onCheckedChange={value => {
						setItem({
							...item,
							available: value,
						});
					}}
				/>
			</div>
		</div>
	);
};
export default ModifierOptionsForm;
