import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogClose,
	DialogPortal,
} from '@/components/UI/Dialog';
import { Button } from '../Button';
import { cn } from '@/lib/utils';
import { DialogOverlay } from '../Dialog';

export const MyDialog = ({
	open,
	onOpenChange,
	title,
	description,
	onSubmit,
	submitText = 'Αποθήκευση',
	// content,
	children,
}) => {
	return (
		<Dialog open={open} onOpenChange={onOpenChange}>
			{/* <DialogPortal> */}
			{/* <DialogOverlay className='DialogOverlay'> */}
			<DialogContent>
				<DialogHeader className='flex mb-2 flex-row sm:flex-col items-center gap-x-3'>
					<DialogTitle>{title}</DialogTitle>
					{description && <DialogDescription>{description}</DialogDescription>}
				</DialogHeader>
				<div className='relative z-[303203020]'>{children}</div>
				{/* <div className='max-h-4/6 overflow-y-auto relative z-[303203020]'>{content}</div> */}

				<DialogFooter className='flex flex-row justify-center sm:justify-end gap-x-4 mt-2'>
					<DialogClose className='cursor-pointer' asChild>
						<Button className='cursor-pointer' variant='outline'>
							Κλείσιμο
						</Button>
					</DialogClose>
					{onSubmit && (
						<Button
							className='cursor-pointer'
							onClick={onSubmit}
							type='button'
							variant='default'
						>
							{submitText}
						</Button>
					)}
				</DialogFooter>
			</DialogContent>
			{/* </DialogOverlay> */}
			{/* </DialogPortal> */}
		</Dialog>
	);
};
