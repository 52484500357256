import { SuperTable } from './SuperTable';
import { useMemo } from 'react';
import { TableLoadingError } from './TableLoadingError';
import { HeaderTable } from './HeaderTable';

export const NewUsersTable = ({ data, isError, isLoading, onOpenNew, onRowClick }) => {
	const columns = useMemo(() => {
		return [
			// {
			// 	id: 'select',
			// 	header: ({ table }) => (
			// 		<div className='flex justify-center items-center '>
			// 			<Checkbox
			// 				checked={
			// 					table.getIsAllPageRowsSelected() ||
			// 					(table.getIsSomePageRowsSelected() && 'indeterminate')
			// 				}
			// 				onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
			// 				aria-label='Select all'
			// 			/>
			// 		</div>
			// 	),
			// 	cell: ({ row }) => (
			// 		<div
			// 			onClick={e => e.stopPropagation()}
			// 			className='flex justify-center items-center '
			// 		>
			// 			<Checkbox
			// 				checked={row.getIsSelected()}
			// 				onCheckedChange={value => row.toggleSelected(!!value)}
			// 				aria-label='Select row'
			// 			/>
			// 		</div>
			// 	),
			// 	enableSorting: false,
			// 	enableHiding: false,
			// },

			{
				header: 'ID',
				accessorKey: 'id',
			},
			{
				header: 'Όνομα',
				cell: ({ row }) => (
					<div className='flex '>
						{`${row.original.firstName} ${row.original.lastName}`}
					</div>
				),
			},
			{
				header: 'Email',
				accessorKey: 'email',
			},
			{
				header: 'Role',
				accessorKey: 'role',
			},
		];
	}, []);

	return (
		<TableLoadingError isError={isError} isLoading={isLoading}>
			<HeaderTable onOpenNew={onOpenNew} title='Χρήστες' />

			<SuperTable
				data={data}
				columns={columns}
				searchKeys={['name']}
				// deletePath='/users'
				onRowClick={onRowClick}
			/>
		</TableLoadingError>
	);
};
