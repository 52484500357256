import { SuperTable } from './SuperTable';
import { useMemo } from 'react';
import { Image } from '@unpic/react';
import { Checkbox } from '@/components/UI/checkbox';
import { TableLoadingError } from './TableLoadingError';
import { HeaderTable } from './HeaderTable';

export const NewCuisinesTable = ({
	data,
	isError,
	isLoading,
	onRowClick,
	onOpenNew,
	extraButtons,
}) => {
	const columns = useMemo(
		() => [
			{
				id: 'select',
				header: ({ table }) => (
					<div className='flex justify-center items-center '>
						<Checkbox
							checked={
								table.getIsAllPageRowsSelected() ||
								(table.getIsSomePageRowsSelected() && 'indeterminate')
							}
							onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
							aria-label='Select all'
						/>
					</div>
				),
				cell: ({ row }) => (
					<div
						onClick={e => e.stopPropagation()}
						className='flex justify-center items-center '
					>
						<Checkbox
							checked={row.getIsSelected()}
							onCheckedChange={value => row.toggleSelected(!!value)}
							aria-label='Select row'
						/>
					</div>
				),
				enableSorting: false,
				enableHiding: false,
			},
			{
				header: 'ID',
				accessorKey: 'id',
			},
			{
				accessorKey: 'logo',
				header: 'Εικόνα',
				cell: ({ row }) => {
					const logo = row.original?.files?.find(f => f.type === 'main');
					if (!logo) {
						return null;
					}
					return (
						<div className='flex justify-center items-center w-8 h-8'>
							<Image
								src={`${import.meta.env.VITE_SPACE_URL}${logo?.name}`}
								// layout='fixed'
								// objectFit='cover'
								width={200}
								height={200}
								aspectRatio={1}
							/>
						</div>
					);
				},
			},
			{
				header: 'Όνομα',
				accessorKey: 'name',
			},
		],
		[],
	);

	return (
		<TableLoadingError isError={isError} isLoading={isLoading}>
			<HeaderTable title='Κουζίνες' onOpenNew={onOpenNew} extraButtons={extraButtons} />
			<SuperTable
				data={data}
				columns={columns}
				searchKeys={['name']}
				deletePath='/cuisines'
				onRowClick={onRowClick}
			/>
		</TableLoadingError>
	);
};
