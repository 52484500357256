import { useWindowSize } from '@/utils/hooks/useWindowSize';
import { MyTabs } from '../MyTabs';
import { MyNewSelect } from '../MyNewSelect';

export const ResponsiveTabs = ({ selectedKey, onChange, tabList }) => {
	const { width } = useWindowSize();

	if (width < 740) {
		const item = tabList.find(it => it.value === selectedKey);
		return (
			<div className='flex flex-col'>
				{/* <div>{item?.label}</div> */}
				<MyNewSelect
					onSelect={value => {
						onChange(value);
					}}
					value={selectedKey}
					options={tabList}
				/>

				{item?.children}
			</div>
		);
	}
	return (
		<MyTabs
			defaultActiveKey={selectedKey}
			cnTabs='w-full mt-2 !bg-transparent'
			cnTabsList='gap-x-1 mb-3'
			onChange={onChange}
			tabList={tabList}
			withChildren
			hideList={tabList.length === 1}
		/>
	);
};
