import React from 'react';
import ModifierForm from './ModifierForm';
import { MyDialog } from '@/components/UI/MyDialog';
import { NewModifiersTable } from '@/components/tables/NewModifiersTable';

const ModifierView = ({
	hideTable,
	data,
	modifierOptions,
	setPageNumber,
	pageSize,
	setPageSize,
	totalPages,
	item,
	setItem,
	onFinish,
	onOpenNew,
	open,
	setOpen,
	isError,
	isLoading,
}) => {
	return (
		<div className='flex flex-col'>
			{!hideTable && (
				<>
					<NewModifiersTable
						data={data}
						isError={isError}
						isLoading={isLoading}
						onOpenNew={onOpenNew}
						onRowClick={row => {
							setOpen(true);
							setItem(row.original);
						}}
					/>
				</>
			)}

			<MyDialog
				open={hideTable || open}
				title='Γκρουπ Υλικών'
				description={item?.id ? `ID: ${item?.id}` : undefined}
				onOpenChange={setOpen}
				onSubmit={onFinish}
				submitText={item?.id ? undefined : 'Προσθήκη'}
			>
				<ModifierForm item={item} setItem={setItem} modifierOptions={modifierOptions} />
			</MyDialog>
		</div>
	);
};

export default ModifierView;
