import { toast } from './toast';

const list = {
	ά: 'α',
	έ: 'ε',
	ό: 'ο',
	ώ: 'ω',
	ύ: 'υ',
	ί: 'ι',
	ή: 'η',

	Ά: 'α',
	Έ: 'ε',
	Ό: 'ο',
	Ώ: 'ω',
	Ύ: 'υ',
	Ί: 'ι',
	Ή: 'η',
};

export const replaceTonous = str => {
	let newStr = '';
	for (let i = 0; i < str.length; i++) {
		const ltr = str.charAt(i).toLowerCase();
		newStr += list[ltr] || ltr;
	}

	return newStr;
};

export const getSearchFilteredData = (options, key, searchText) => {
	const words = !searchText ? options : replaceTonous(searchText.trim()).split(/\s+/);

	return !searchText
		? options
		: options
				?.map(c => {
					if (
						words.some(word =>
							// console.log('daaaaa', replaceTonous(c[key])) ||

							replaceTonous(c[key]).includes(word),
						)
					) {
						return c;
					}
				})
				.filter(it => !!it);
};

// UNTESTED
export const getSearchFilteredDataArray = (options, keys, searchText) => {
	try {
		const words = !searchText ? options : replaceTonous(searchText.trim()).split(/\s+/);

		return !searchText
			? options
			: options
					?.map(c => {
						if (
							words.some(word =>
								keys.some(key => {
									const keySplit = key.split('.');
									let finalKey =
										keySplit?.length === 2
											? c[keySplit[0]][keySplit[1]]
											: c[key];

									if (
										!(
											typeof finalKey === 'string' ||
											typeof finalKey === 'number'
										)
									) {
										return false;
									}

									return replaceTonous(
										typeof finalKey === 'string' ? finalKey : `${finalKey}`,
									).includes(word);
								}),
							)
						) {
							return c;
						}
					})
					.filter(it => !!it);
	} catch (err) {
		toast.err(err);
		console.log('search error:', err);
	}
};
