import { useQuery, useQueryClient } from '@tanstack/react-query';
import { get, getGlobal } from '../http';

export const useKeyQuery = ({ key, global, pageSize, enabled = true, url }) => {
	const queryClient = useQueryClient();
	const { error, data, refetch, isPending, isFetching, isLoading, isRefetching, isError } =
		useQuery({
			queryKey: [key],
			queryFn: () => {
				const action = global ? getGlobal : get;
				return action(url || `/${key}`, {
					// pageNumber,
					pageSize: pageSize || 132,
					// language: user.language,
				});
			},
			staleTime: 10 * 1000,
			enabled,
		});

	const invalidateKeyQueries = () => queryClient.invalidateQueries({ queryKey: [key] });

	return {
		error,
		isError,
		data: data?.data,
		refetch,
		isPending,
		isFetching,
		isLoading,
		isRefetching,
		invalidateKeyQueries,
	};
};
