import React, { useRef, useState } from 'react';
import { Typography } from 'antd';
import { patch } from '@/utils/http.js';
import { MyDialog } from '../UI/MyDialog/index.jsx';
import SimpleSortableList from '../UI/sortable-list/SortableList.jsx';
import { toast } from '@/utils/toast.js';
import { tagColors } from '@/utils/tag-colors.js';

const { Text } = Typography;

const entityProperties = {
	categories: {
		styles: tagColors.blue,
	},

	category: {
		styles: tagColors.green,
	},

	item: {
		styles: tagColors.red,
	},

	modifier: {
		styles: tagColors.purple,
	},
};

export const ReorderModalTree = ({ openedMobileReorderNode, refetch, close }) => {
	const { type, item } = openedMobileReorderNode || {};
	// console.log(openedMobileReorderNode);
	const children =
		type === 'categories'
			? item
			: type === 'category'
				? item.items
				: type === 'item'
					? item.modifiers
					: type === 'modifier'
						? item.options
						: [];
	// console.log('good children', item);

	const [modifiedCopy, setModifiedCopy] = useState(() => children);

	const savingRef = useRef(false);

	const onSaveReorder = async () => {
		if (savingRef.current) {
			return;
		}
		try {
			// console.log('modifiedCopy ', modifiedCopy);

			const itemsMapped = modifiedCopy.map(c => c.id);

			let action;

			switch (type) {
				case 'categories':
					action = async () =>
						await patch('/item-categories/reorder', {
							itemCategories: itemsMapped,
						});
					break;
				case 'category':
					action = async () =>
						await patch('/items/reorder', {
							categoryId: item.id,
							items: itemsMapped,
						});
					break;
				case 'item':
					action = async () =>
						await patch('/modifiers/reorder', {
							itemId: item.id,
							modifiers: itemsMapped,
						});
					break;
				case 'modifier':
					action = async () =>
						await patch('/modifier-options/reorder', {
							modifierId: item.id,
							options: itemsMapped,
						});
					break;
			}

			if (typeof action === 'function') {
				const hideLoading = () => (savingRef.current = false);

				toast.promise(
					async () => {
						savingRef.current = true;
						await action();

						refetch();
						hideLoading();
						close();
					},
					{ errorCb: hideLoading },
				);
			}
		} catch (err) {
			toast.error(err);
			console.log('save onSaveReorder err', err);
		}
	};

	return (
		<MyDialog
			open
			onOpenChange={close} // this is ok
			title={type === 'categories' ? 'Αλλαγή σειράς Κατηγοριών' : item?.name || item?.label}
			onSubmit={onSaveReorder}
		>
			<Text>{item?.label}</Text>

			{/* styles={entity} */}
			<SimpleSortableList
				items={modifiedCopy}
				setItems={setModifiedCopy}
				styles={entityProperties[type]?.styles || {}}
			/>
		</MyDialog>
	);
};
