import React, { useState } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Image } from '@unpic/react';

const SortableItem = ({ item, imgType, styles }) => {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: item.id,
	});

	const style = {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		columnGap: 8,
		transform: CSS.Transform.toString(transform),
		transition,
		padding: '6px 12px',
		// padding: '12px',
		// paddingVertical: '6px',
		margin: '5px',
		// border: '2px solid #ccc',
		borderWidth: 1,
		borderRadius: 8,
		backgroundColor: '#f9f9f9',
		cursor: 'grab',
		...styles,
	};

	let img;
	if (imgType) {
		img = item?.files?.find(f => f.type === imgType);
	}
	return (
		<div ref={setNodeRef} style={style} {...attributes} {...listeners}>
			{img ? (
				<Image
					src={`${import.meta.env.VITE_SPACE_URL}${img?.name}`}
					// layout='fixed'
					// objectFit='cover'
					width={32}
					height={32}
					aspectRatio={1}
				/>
			) : imgType ? (
				<div className='w-8 h-8' />
			) : null}
			{item?.name || item?.label}
		</div>
	);
};

const SimpleSortableList = ({ items, setItems, styles, imgType }) => {
	// const [items, setItems] = useState(['Item 1', 'Item 2', 'Item 3', 'Item 4']);

	const handleDragEnd = event => {
		const { active, over } = event;

		if (active.id !== over.id) {
			setItems(items => {
				const oldIndex = items.findIndex(it => it.id === active.id);
				const newIndex = items.findIndex(it => it.id === over.id);

				return arrayMove(items, oldIndex, newIndex);
			});
		}
	};

	return (
		<DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
			<SortableContext items={items} strategy={verticalListSortingStrategy}>
				<div className='flex flex-col gap-y-0.5 max-h-[50vh] overflow-y-auto px-2'>
					{items.map(item => (
						<SortableItem key={item.id} item={item} styles={styles} imgType={imgType} />
					))}
				</div>
			</SortableContext>
		</DndContext>
	);
};

export default SimpleSortableList;
