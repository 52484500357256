import { Input, Typography } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, MarkerF, Circle, DrawingManager } from '@react-google-maps/api';
import { geocodeByAddress } from 'react-google-places-autocomplete';

const { Text } = Typography;

const defaultLat = 37.9838;
const defaultLng = 23.7275;

const CityForm = ({ item, setItem }) => {
	const [marker, setMarker] = useState({
		position: {
			lat: defaultLat,
			lng: defaultLng,
		},
		label: { color: 'white', text: 'O' },
	});
	const [circle, setCircle] = useState(null); // Circle data state
	const [mapLoaded, setMapLoaded] = useState(false);

	const loadGoogleMapsScript = callback => {
		if (typeof window.google === 'undefined') {
			const script = document.createElement('script');
			script.src = `https://maps.googleapis.com/maps/api/js?key=${
				import.meta.env.VITE_GGL_API_KEY
			}&libraries=places,drawing`;
			script.async = true;
			script.defer = true;
			script.onload = callback;
			document.head.appendChild(script);
		} else {
			callback?.();
		}
	};

	useEffect(() => {
		loadGoogleMapsScript(() => setMapLoaded(true));
	}, []);

	useEffect(() => {
		if (!item?.lat || !item?.lng) {
			return;
		}
		setMarker(prev => ({
			...prev,
			position: { lat: item.lat, lng: item.lng },
		}));
		setCircle({
			center: { lat: item.lat, lng: item.lng },
			radius: item.radius,
		});
	}, [item]);

	const geocodeAddress = useCallback(address => {
		return geocodeByAddress(address).then(results => {
			if (results.length > 0) {
				const location = results[0].geometry.location;
				const lat = location.lat();
				const lng = location.lng();

				return { lat, lng };
			}
			throw new Error('No geocoding results found.');
		});
	}, []);

	const mapClicked = useCallback(
		async event => {
			const lat = event.latLng.lat();
			const lng = event.latLng.lng();

			if (lat !== marker.position.lat || lng !== marker.position.lng) {
				setItem(prev => ({
					...prev,
					lat,
					lng,
				}));
				setMarker(prev => ({
					...prev,
					position: { lat, lng },
				}));
			}
		},
		[setItem, marker.position],
	);

	const onCircleComplete = useCallback(
		circleObj => {
			const center = circleObj.getCenter();
			const radius = circleObj.getRadius();

			setItem(prev => ({
				...prev,
				radius: radius,
				lat: center.lat(),
				lng: center.lng(),
			}));

			setCircle({
				center: {
					lat: center.lat(),
					lng: center.lng(),
				},
				radius: radius,
			});

			circleObj.setMap(null); // Remove the drawn circle from the map
		},
		[setItem],
	);

	// Function to handle geocoding based on street, streetNumber, and area
	const handleAddressGeocoding = useCallback(() => {
		const { street, streetNumber, area } = item;
		if (!street || !streetNumber || !area) {
			return;
		}
		const addressString = `${street} ${streetNumber}, ${area}`;
		geocodeAddress(addressString)
			?.then(({ lat, lng }) => {
				setItem(prev => ({
					...prev,
					lat,
					lng,
				}));
				setMarker(prev => ({
					...prev,
					position: { lat, lng },
				}));
			})
			.catch(error => {
				console.error('Error in geocoding: ', error);
			});
	}, [geocodeAddress, item, setItem]);

	return (
		<div className='flex flex-col'>
			<Text>Όνομα</Text>
			<Input
				value={item?.name || ''}
				style={{ marginRight: 6, marginBottom: 50 }}
				onChange={({ target: { value } }) => {
					setItem({
						...item,
						name: value,
					});
				}}
			/>
			{/* <br />
			<Text>{translations.street}</Text>
			<Input
				value={item?.street || ''}
				style={{ marginRight: 6, width: '50svw' }}
				onChange={({ target: { value } }) => {
					setItem({
						...item,
						street: value,
					});
				}}
				onBlur={handleAddressGeocoding} // Trigger geocoding when user leaves input field
			/>
			<br />
			<Text>{translations.streetNumber}</Text>
			<Input
				value={item?.streetNumber || ''}
				style={{ marginRight: 6, width: '50svw' }}
				onChange={({ target: { value } }) => {
					setItem({
						...item,
						streetNumber: value,
					});
				}}
				onBlur={handleAddressGeocoding} // Trigger geocoding when user leaves input field
			/>
			<br />
			<Text>{translations.area}</Text>
			<Input
				value={item?.area || ''}
				style={{ marginRight: 6, width: '50svw' }}
				onChange={({ target: { value } }) => {
					setItem({
						...item,
						area: value,
					});
				}}
				onBlur={handleAddressGeocoding} // Trigger geocoding when user leaves input field
			/>
			<br />
			<br /> */}

			{mapLoaded ? (
				<GoogleMap
					mapContainerStyle={{
						minWidth: 300,
						minHeight: 300,
						width: '100%',
						height: '100%',
					}}
					center={marker.position}
					zoom={10}
					onClick={mapClicked}
				>
					<MarkerF
						position={marker.position}
						label={marker.label}
						draggable
						onDragEnd={event => {
							const lat = event.latLng.lat();
							const lng = event.latLng.lng();
							setItem(prev => ({
								...prev,
								lat,
								lng,
							}));
							setMarker(prev => ({
								...prev,
								position: { lat, lng },
							}));
						}}
					/>
					{circle && (
						<Circle
							center={{ lat: item.lat, lng: item.lng }}
							radius={item.radius}
							options={{
								fillColor: '#FF0000',
								fillOpacity: 0.2,
								strokeWeight: 1,
							}}
						/>
					)}

					{window.google && window.google.maps && (
						<DrawingManager
							onCircleComplete={onCircleComplete}
							options={{
								drawingControl: true,
								drawingControlOptions: {
									position: window.google.maps.ControlPosition.TOP_CENTER,
									drawingModes: [window.google.maps.drawing.OverlayType.CIRCLE],
								},
								circleOptions: {
									fillOpacity: 0.3,
									strokeWeight: 1,
									clickable: true,
									editable: true,
									zIndex: 1,
								},
							}}
						/>
					)}
				</GoogleMap>
			) : (
				<div>Loading Map...</div>
			)}
		</div>
	);
};

export default CityForm;
