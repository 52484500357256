import React from 'react';
import ModifierOptionsForm from './ModifierOptionsForm';
import { MyDialog } from '@/components/UI/MyDialog';
import { NewModifierOptionsTable } from '@/components/tables/NewModifierOptionsTable';

const ModifierOptionsView = ({
	hideTable,
	data,
	setPageNumber,
	pageSize,
	setPageSize,
	totalPages,
	item,
	setItem,
	onFinish,
	onOpenNew,
	open,
	setOpen,
	isError,
	isLoading,
}) => {
	return (
		<div className='flex flex-col'>
			{!hideTable && (
				<>
					<NewModifierOptionsTable
						data={data}
						isError={isError}
						isLoading={isLoading}
						onOpenNew={onOpenNew}
						onRowClick={row => {
							setOpen(true);
							setItem(row.original);
						}}
					/>
					{/* <MyTable
						data={data.map(it => ({ ...it, price: it.price / 100 }))}
						setOpen={setOpen}
						setItem={setItem}
						modalFlag={true}
						// rowKey='id'
						columns={ModifierOptionsColumn}
						setPageNumber={setPageNumber}
						pageSize={pageSize}
						setPageSize={setPageSize}
						editPath='/modifier-options/edit/'
						basePath='/modifier-options'
						// setReduxAction={setModifierOptions}
						totalPages={totalPages}
						// user={user}
						draggable={true}
					/> */}
				</>
			)}
			<MyDialog
				open={hideTable || open}
				title='Υλικό'
				description={item?.id ? `ID: ${item?.id}` : undefined}
				onOpenChange={setOpen}
				onSubmit={onFinish}
				submitText={item?.id ? undefined : 'Προσθήκη'}
			>
				<ModifierOptionsForm item={item} setItem={setItem} />
			</MyDialog>
		</div>
	);
};

export default ModifierOptionsView;
