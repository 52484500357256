export const generatePassword = (length = 10) => {
	const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	const lowercase = 'abcdefghijklmnopqrstuvwxyz';
	const digits = '0123456789';
	const specialChars = '!@#$%^&*()-_=+[]{}|;:,.<>?';

	let password = '';

	// Ensure at least one uppercase letter
	password += uppercase[Math.floor(Math.random() * uppercase.length)];

	// Ensure at least one lowercase letter
	password += lowercase[Math.floor(Math.random() * lowercase.length)];

	// Ensure at least six digits
	for (let i = 0; i < 4; i++) {
		password += digits[Math.floor(Math.random() * digits.length)];
	}

	// Ensure at least one special character
	password += specialChars[Math.floor(Math.random() * specialChars.length)];

	// Fill the rest with random characters from all sets
	const allChars = uppercase + lowercase + digits + specialChars;
	while (password.length < length) {
		password += allChars[Math.floor(Math.random() * allChars.length)];
	}

	// Shuffle the password to avoid predictable patterns
	password = password
		.split('')
		.sort(() => 0.5 - Math.random())
		.join('');

	return password;
};
