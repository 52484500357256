import React, { useEffect, useRef, useState } from 'react';
import ModifierView from '../../../../components/store/shop/modifiers/ModifierView';
import { toast } from '@/utils/toast';
import { useMainStore } from '@/store/main.store';
import { post, put } from '@/utils/http';
import { useKeyQuery } from '@/utils/hooks/useKeyQuery';

const initialState = {
	name: '',
	internalName: '',
	required: false,
	type: 0,
	options: [],
};

const ModifiersHOC = ({
	hideTable,
	itemId,
	open: customOpen,
	setOpen: customSetOpen,
	onFinishSuccessCb,
}) => {
	const { user } = useMainStore();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(135);
	const [totalPages, setTotalPages] = useState(0);
	const [item, setItem] = useState({
		options: [],
	});
	const [open, setOpen] = useState(false);

	const savingRef = useRef(false);

	// const queryClient = useQueryClient();

	// const {
	// 	isPending,
	// 	error,
	// 	data: dataModifiers,
	// } = useQuery({
	// 	queryKey: [QUERY_KEY],
	// 	queryFn: () => {
	// 		return get('/modifiers', {
	// 			pageNumber,
	// 			pageSize,
	// 			language: user.language,
	// 		});
	// 	},
	// });

	// const { data: dataModifierOptions } = useQuery({
	// 	queryKey: ['modifier-options'],
	// 	queryFn: () =>
	// 		get('/modifier-options', {
	// 			pageNumber,
	// 			pageSize,
	// 			language: user.language,
	// 		}),
	// });

	// const { data: modifiers } = dataModifiers?.data || {};
	// const { data: modifierOptions } = dataModifierOptions?.data || {};

	const {
		data: { data: modifiers } = {},
		isError,
		isLoading,
		invalidateKeyQueries,
	} = useKeyQuery({
		key: 'modifiers',
		global: false,
		pageSize,
	});
	const { data: { data: modifierOptions } = {} } = useKeyQuery({
		key: 'modifier-options',
		global: false,
	});

	useEffect(() => {
		if (itemId && modifiers) {
			const item = modifiers.find(it => it.id === itemId);
			setItem(item);
		}
	}, [itemId, modifiers]);

	useEffect(() => {
		if (open && !item?.id) {
			setItem(initialState);
		}
	}, [open]);

	const onFinish = () => {
		if (savingRef.current) {
			return;
		}

		if (!item?.name) {
			toast.error('Το όνομα είναι κενό');
			return;
		}

		try {
			const hideLoading = () => (savingRef.current = false);

			toast.promise(
				async () => {
					savingRef.current = true;

					const data = {
						name: item.name,
						internalName: item.internalName,
						language: user.language,
						type: item.type ? 1 : 0,
						required: !!item.required,
						// options: item.options?.[0]?.id ? item.options.map(option => option.id) : [],
						options: !item.options
							? []
							: item.options?.[0]?.id
								? item.options.map(it => it.id)
								: item.options,
					};

					const action = item?.id ? put : post;
					const url = `/modifiers/${item?.id || ''}`;

					await action(url, data);
					setOpen(false);
					setItem({ initialState });

					if (typeof onFinishSuccessCb === 'function') {
						onFinishSuccessCb();
					}
					// queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

					invalidateKeyQueries();

					hideLoading();
				},
				{ errorCb: hideLoading },
			);
		} catch (err) {
			console.log('modifier finish err', err);

			toast.error(err);
		}
	};

	const onOpenNew = () => {
		setItem(initialState);
		setOpen(true);
	};

	return (
		<ModifierView
			hideTable={hideTable}
			data={modifiers}
			modifierOptions={modifierOptions}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
			item={item}
			setItem={setItem}
			onFinish={onFinish}
			onOpenNew={onOpenNew}
			open={customOpen || open}
			setOpen={customSetOpen || setOpen}
			// customSetOpen={customSetOpen}
			isError={isError}
			isLoading={isLoading}
		/>
	);
};

export default ModifiersHOC;
