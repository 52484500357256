import React, { useEffect, useState } from 'react';
import { MyDialog } from '../UI/MyDialog';
import { useKeyQuery } from '@/utils/hooks/useKeyQuery';
import { HistoryOrdersTable } from '../tables/HistoryOrdersTable';
import { useMainStore } from '@/store/main.store';

const HistoryOrders = () => {
	const { user, selectedShopID } = useMainStore();
	const [item, setItem] = useState({});
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (open && !item?.id) {
			setItem(null);
		}
	}, [open]);

	const {
		data: { data: historyOrders } = {},
		isError,
		isPending,
		invalidateKeyQueries,
	} = useKeyQuery({
		key: 'history-orders',
		global: user?.role === 0,
		url: `/orders?status=4${selectedShopID ? `&shop=${selectedShopID}` : ''}`,
	});

	return (
		<div className='flex flex-col'>
			<HistoryOrdersTable
				data={historyOrders}
				isError={isError}
				isLoading={isPending}
				onRowClick={row => {
					setOpen(true);
					setItem(row.original);
				}}
			/>

			<MyDialog
				open={open}
				title='Παραγγελία'
				description={item?.id ? `ID: ${item?.id}` : undefined}
				onOpenChange={setOpen}
				// onSubmit={onFinish}
				submitText={item?.id ? undefined : 'Προσθήκη'}
			>
				{/* <ItemCategoryForm item={item} setItem={setItem} /> */}
			</MyDialog>
		</div>
	);
};
export default HistoryOrders;
