import * as React from 'react';
import { Command as CommandPrimitive } from 'cmdk';
import { SearchIcon } from 'lucide-react';

import { cn } from '@/lib/utils';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from '@/components/UI/dialog';

function Command({ className, ...props }) {
	return (
		<CommandPrimitive
			data-slot='command'
			className={cn(
				'bg-white text-slate-950 flex h-full w-full flex-col overflow-hidden rounded-md dark:bg-slate-950 dark:text-slate-50',
				className,
			)}
			{...props}
		/>
	);
}

function CommandDialog({
	title = 'Command Palette',
	description = 'Search for a command to run...',
	children,
	...props
}) {
	return (
		<Dialog {...props}>
			<DialogHeader className='sr-only'>
				<DialogTitle>{title}</DialogTitle>
				<DialogDescription>{description}</DialogDescription>
			</DialogHeader>
			<DialogContent className='overflow-hidden p-0'>
				<Command className='[&_[cmdk-group-heading]]:text-slate-500 **:data-[slot=command-input-wrapper]:h-12 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group]]:px-2 [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5 dark:[&_[cmdk-group-heading]]:text-slate-400'>
					{children}
				</Command>
			</DialogContent>
		</Dialog>
	);
}

function CommandInput({ className, ...props }) {
	return (
		<div
			data-slot='command-input-wrapper'
			className='flex h-9 items-center gap-2 border-b px-3'
		>
			<SearchIcon className='size-4 shrink-0 opacity-50' />
			<CommandPrimitive.Input
				data-slot='command-input'
				className={cn(
					'placeholder:text-slate-500 flex h-10 w-full rounded-md bg-transparent py-3 text-sm outline-hidden disabled:cursor-not-allowed disabled:opacity-50 dark:placeholder:text-slate-400',
					className,
				)}
				{...props}
			/>
		</div>
	);
}

function CommandList({ className, ...props }) {
	return (
		<CommandPrimitive.List
			data-slot='command-list'
			className={cn('max-h-[300px] scroll-py-1 overflow-x-hidden overflow-y-auto', className)}
			{...props}
		/>
	);
}

function CommandEmpty({ ...props }) {
	return (
		<CommandPrimitive.Empty
			data-slot='command-empty'
			className='py-6 text-center text-sm'
			{...props}
		/>
	);
}

function CommandGroup({ className, ...props }) {
	return (
		<CommandPrimitive.Group
			data-slot='command-group'
			className={cn(
				'text-slate-950 [&_[cmdk-group-heading]]:text-slate-500 overflow-hidden p-1 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium dark:text-slate-50 dark:[&_[cmdk-group-heading]]:text-slate-400',
				className,
			)}
			{...props}
		/>
	);
}

function CommandSeparator({ className, ...props }) {
	return (
		<CommandPrimitive.Separator
			data-slot='command-separator'
			className={cn('bg-slate-200 -mx-1 h-px dark:bg-slate-800', className)}
			{...props}
		/>
	);
}

function CommandItem({ className, ...props }) {
	return (
		<CommandPrimitive.Item
			data-slot='command-item'
			className={cn(
				"data-[selected=true]:bg-slate-100 data-[selected=true]:text-slate-900 [&_svg:not([class*='text-'])]:text-slate-500 relative flex cursor-default items-center gap-2 rounded-sm px-2 py-1.5 text-sm outline-hidden select-none data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50 [&_svg]:pointer-events-none [&_svg]:shrink-0 [&_svg:not([class*='size-'])]:size-4  ",
				className,
			)}
			{...props}
		/>
	);
}

function CommandShortcut({ className, ...props }) {
	return (
		<span
			data-slot='command-shortcut'
			className={cn(
				'text-slate-500 ml-auto text-xs tracking-widest dark:text-slate-400',
				className,
			)}
			{...props}
		/>
	);
}

export {
	Command,
	CommandDialog,
	CommandInput,
	CommandList,
	CommandEmpty,
	CommandGroup,
	CommandItem,
	CommandShortcut,
	CommandSeparator,
};
