import { SuperTable } from './SuperTable';
import { useMemo } from 'react';
import { TableLoadingError } from './TableLoadingError';
import { HeaderTable } from './HeaderTable';

export const NewCitiesTable = ({ data, isError, isLoading, onOpenNew, onRowClick }) => {
	const columns = useMemo(() => {
		return [
			{
				accessorKey: 'id',
				header: 'ID',
			},
			{
				accessorKey: 'name',
				header: 'Όνομα',
			},
		];
	}, []);

	return (
		<TableLoadingError isError={isError} isLoading={isLoading}>
			<HeaderTable title='Πόλεις' onOpenNew={onOpenNew} />
			<SuperTable
				data={data}
				columns={columns}
				searchKeys={['name']}
				onRowClick={onRowClick}
			/>
		</TableLoadingError>
	);
};
