import { tagColors } from '@/utils/tag-colors';

export const Tag = ({ colorPreset, children }) => (
	<div
		className='inline-block text-xs px-2 py-0.5'
		style={{
			...{ borderWidth: 1, borderRadius: 4 },
			...(!tagColors?.[colorPreset] ? {} : { ...tagColors?.[colorPreset] }),
		}}
	>
		{children}
	</div>
);
