import { useEffect, useRef, useState } from 'react';
import {
	MapPinHouse,
	MessageCircleWarning,
	MessageSquareText,
	Minimize,
	User,
	Wallet,
} from 'lucide-react';
import { useSound } from '../../../utils/hooks/useSound';
import { ORDER_DECLINE_REASONS_OPTIONS, ORDER_DELIVERY_ESTIMATE_OPTIONS } from '@/utils/constants';
import { Button } from '@/components/UI/Button';
import { MyNewSelect } from '@/components/UI/MyNewSelect';
import boopSfx from '../../../assets/sounds/boop.mp3';

export const SingleFullScreenOrder = ({
	order,
	onAccept,
	onDecline,
	onSee,
	onRemoveOrderRequest,
}) => {
	// const visible = true; // liveOrdersRequests?.some?.(o => !o.seen);
	const { play, stop, isPlayingRef } = useSound(boopSfx);

	useEffect(() => {
		try {
			if (!order) {
				stop();
				return;
			}
			console.log('seen: ', order.id, order);
			if (!isPlayingRef.current) {
				// if (visibleIds?.length && liveOrdesrsRequests.length) {
				if (!order.seen) {
					try {
						play();
					} catch (err) {
						console.log('play err:', err);
						setTimeout(() => {
							play();
						}, 1_000);
					}
				}
				// }s
			} else {
				stop();
			}
		} catch (err) {
			console.log('play/stop err:', err);
		}
	}, [order]);

	const [timer, setTimer] = useState(null);
	const [estimatedMinutes, setEstimatedMinutes] = useState(
		`${ORDER_DELIVERY_ESTIMATE_OPTIONS[5].value}`,
	);

	const [declineReason, setDeclineReason] = useState(`${ORDER_DECLINE_REASONS_OPTIONS[0].key}`);

	const intervalRef = useRef();

	useEffect(() => {
		const calcTime = () => {
			if (!order) {
				return;
			}
			const secondsLeft = (new Date(order.orderIgnoredDate) - new Date()) / 1000;
			// console.log(secondsLeft);
			setTimer(secondsLeft > 0 ? Math.floor(secondsLeft) : '');
			// console.log({ order });
			if (!(secondsLeft > 0)) {
				// onClose(order.id, lorRef.current);
				onRemoveOrderRequest(order.id);
			}
		};
		calcTime();
		intervalRef.current = setInterval(calcTime, 1000);
		return () => {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		};
	}, [order]);

	if (!order) {
		return null;
	}

	// console.log('qqq', liveOrdersRequests?.[0]);
	// const { width, height } = useWindowSize();
	// const h = height - (width < 740 ? 64 : 50) - 1;

	const { id, user, address, price, items, paymentType, comment, deliveryCost } = order || {};

	const handleDecline = () => {
		console.log(ORDER_DECLINE_REASONS_OPTIONS, declineReason);
		const reason = ORDER_DECLINE_REASONS_OPTIONS.find(it => it.key == declineReason);
		onDecline(order, !reason ? '' : reason.key, {
			cb: () => {
				setDeclineReason(`${ORDER_DECLINE_REASONS_OPTIONS[0].key}`);
			},
		});
	};

	const { modifiers } = items || {};

	const modifiersList = modifiers?.map(mod => {
		return {
			name: mod.name,
			options: mod.options.map(opt => ({
				name: opt.name,
			})),
		};
	});

	// let optionsString = '';

	// if ()
	// for (let i = 0; i < modifiers.length; i++) {
	// 		optionsString += `${options[i]}${i !== options.length - 1 ? ', ' : ''}`;
	// }

	// optionsString += it?.comment || '';

	// const date = new Date(createdAt);
	// const hours = String(date.getHours()).padStart(2, '0');
	// const minutes = String(date.getMinutes()).padStart(2, '0');
	// const formattedTime = `${hours}:${minutes}`;

	return (
		<div className='absolute inset-0 h-dvh max-w-dvh flex flex-row justify-center items-center gap-x-3 overflow-x-hidden z-[232323232] animate-full-screen-order opacity-0 bg-white  '>
			<Minimize
				onClick={() => onSee(order.id)}
				className='cursor-pointer absolute bottom-8 right-8 text-slate-500'
				size={36}
			/>

			<div className='flex flex-col items-center bg-amber-400 gap-y-20 p-20 border-2 rounded-3xl '>
				<div className='flex flex-col gap-y-6 p-2 justify-between px-4 p-y-1  border border-color-[#e8e8e8] rounded-xl  text-slate-700 '>
					<div>{id}</div>

					<div className='flex flex-row '>
						<MapPinHouse size={34} className='w-12' />
						<div className='text-3xl'>
							{address?.street} {address?.streetNumber}
						</div>
					</div>

					<div className='flex flex-row g'>
						<User size={34} className='w-12' />
						<div className='text-3xl'>
							{user?.firstName} {user?.lastName}
						</div>
					</div>

					<div className='flex flex-row '>
						<Wallet size={34} className='w-12' />
						{/* <div className='text-3xl'>{paymentType === 0 ? 'Μετρητά' : 'Κάρτα'}</div> */}
						<div className='text-3xl'>
							{`${price / 100}`}
							<span className='text-lg'>
								€{' '}
								<span className='ml-0.5'>
									{paymentType === 0 ? 'μετρητά' : 'κάρτα'}
								</span>
							</span>
						</div>
					</div>

					<div className='flex flex-col gap-y-2 mb-2'>
						{items?.map?.((item, index) => (
							<div key={index} className='flex flex-col mb-2 text-3xl'>
								<div className='ml-2 mb-1'>
									{' '}
									{item.quantity}x {item.name}
								</div>
								{item.modifiers?.map(mod => (
									<div
										key={mod.id}
										className='flex flex-col gap-y-1.5 rounded-lg py-1 px-2 bg-sky-200 ml-10'
									>
										<div className='text-lg'>{mod.name}</div>
										<div className='flex flex-col gap-y-1'>
											{mod.options.map(opt => (
												<div key={opt.id} className='text-base'>
													{opt.name}
												</div>
											))}
										</div>
									</div>
								))}
								{item.comment && (
									<div className='flex flex-row items-center gap-x-1 ml-10'>
										<MessageCircleWarning size={26} />
										<div>{item.comment}</div>
									</div>
								)}
							</div>
						))}
					</div>

					{comment && typeof comment === 'string' && comment.trim() && (
						<div className='flex flex-row gap-x-1'>
							<MessageSquareText size={38} />
							<div className='text-3xl'>{comment.trim()}</div>
						</div>
					)}
				</div>
				<div className='flex flex-row gap-x-20'>
					<div className='flex flex-col items-center gap-y-3'>
						<Button
							className='w-44 h-20 text-3xl bg-green-600 hover:bg-green-700'
							onClick={() => onAccept(order, estimatedMinutes)}
						>
							Αποδοχή
						</Button>
						<div className='flex items-center max-w-46 relative !z-[8803203023] '>
							<MyNewSelect
								// withSearch
								onSelect={v => setEstimatedMinutes(v)}
								// value={loadingItemCategories ? null : item?.category}
								value={estimatedMinutes}
								options={ORDER_DELIVERY_ESTIMATE_OPTIONS.map(it => ({
									...it,
									label: `${it.label} λεπτά`,
									value: `${it.value}`,
								}))}
							/>
						</div>
					</div>
					<div className='flex flex-col items-center gap-y-3'>
						<Button
							className='w-44 h-20 text-2xl bg-neutral-800'
							onClick={handleDecline}
						>
							Απόρριψη
						</Button>
						<div className='flex flex-col items-center max-w-50 relative !z-[8803203023] '>
							<MyNewSelect
								// withSearch
								onSelect={v => console.log('ddd', v) || setDeclineReason(v)}
								// value={loadingItemCategories ? null : item?.category}
								value={declineReason}
								options={ORDER_DECLINE_REASONS_OPTIONS.map(it => ({
									...it,
									value: `${it.key}`,
								}))}
							/>
						</div>
					</div>
				</div>
				{timer}
			</div>
		</div>
	);
};
