import React, { useEffect, useState } from 'react';
import TreeCompo from '../../components/tree';
import { Tag } from '@/components/UI/Tag';
import { DragOutlined } from '@ant-design/icons';
// import { isMobile, isDesktop } from 'react-device-detect';
import { useKeyQuery } from '@/utils/hooks/useKeyQuery';

const TreeHOC = () => {
	const [open, setOpen] = useState(false);
	const [selectedID, setSelectedID] = useState(null);
	const [depth, setDepth] = useState(null);
	// const [addData, setAddData] = useState(null);

	const [treeData, setTreeData] = useState([]);

	const [openedMobileReorderNode, setOpenedMobileReorderNode] = useState(null);

	useEffect(() => {
		if (open) {
			return;
		}
		setSelectedID(null);
		// setAddData(null);
		setDepth(null);
	}, [open]);

	// useEffect(() => {
	// 	console.log('add data is', addData);
	// }, [addData]);

	const { data: tree, refetch } = useKeyQuery({ key: 'tree', global: false });

	useEffect(() => {
		if (!Array.isArray(tree) || !tree?.length) {
			return;
		}
		const td = tree.map?.(item => ({
			...item,
			items: item.items.map(it => ({
				...it,
				price: it.price / 100,
				modifiers: it.modifiers.map(m => ({
					...m,
					options: m.options.map(o => ({
						...o,
						price: o.price / 100,
					})),
				})),
			})),
		}));

		const newTreeData = buildTreeData(
			td,
			// itemCategories.data,
			// items.data.map(it => ({ ...it, price: it.price / 100 })),
		);

		setTreeData(newTreeData);
	}, [tree]);

	const onEditClick = (depth, item) => {
		setDepth(depth);
		setOpen(true);
		setSelectedID(item.id);
		// setAddData(item);
	};

	const buildTreeData = categories =>
		categories.map(category => ({
			title: (
				<div
					onClick={e => {
						e.stopPropagation();
						onEditClick(1, category);
					}}
					className='flex flex-row justify-between items-center gap-x-2'
				>
					<Tag
						// style={{ minWidth: 50, maxWidth: 550, width: '100%' }}
						colorPreset='blue'
					>
						{category.name}
					</Tag>
					<DragOutlined
						onClick={e => {
							e.stopPropagation();
							setOpenedMobileReorderNode({
								type: 'category',
								item: category,
							});
						}}
					/>
				</div>
			),
			label: category.name,
			key: `category-${category.id}`,
			id: category.id,
			children: category.items
				// ?.filter(item => item.category === category.id)
				.map(item => ({
					title: (
						<div
							onClick={e => {
								e.stopPropagation();
								onEditClick(2, item);
							}}
							className='flex flex-row justify-between items-center gap-x-2'
						>
							<Tag
								// style={{ minWidth: 150, maxWidth: 550, width: '100%' }}
								colorPreset={item.available ? 'green' : 'lightgrey'}
							>
								<div className='flex justify-between'>
									{/* <span>{item.id}</span> */}
									<span>{item.name}</span>
									<span className='pl-2.5'>{item.price}€</span>
								</div>
							</Tag>
							<DragOutlined
								onClick={e => {
									e.stopPropagation();
									setOpenedMobileReorderNode({
										type: 'item',
										item,
									});
								}}
							/>
						</div>
					),
					key: `item-${category.id}-${item.id}`,
					label: item.name,
					children: item.modifiers.map(modifier => ({
						title: (
							<div
								onClick={e => {
									e.stopPropagation();
									onEditClick(3, modifier);
								}}
								className='flex flex-row justify-between items-center gap-x-2'
							>
								<Tag
									// style={{ minWidth: 50, maxWidth: 550, width: '100%' }}
									colorPreset='red'
								>
									{modifier.internalName
										? `**${modifier.internalName}`
										: modifier.name}
								</Tag>
								<DragOutlined
									onClick={e => {
										e.stopPropagation();
										setOpenedMobileReorderNode({
											type: 'modifier',
											item: modifier,
										});
									}}
								/>
							</div>
						),
						key: `modifier-${category.id}-${item.id}-${modifier.id}`,
						// label: modifier.internalName ? `**${modifier.internalName}` : modifier.name,
						children: modifier.options.map(modifierOption => ({
							title: (
								<div
									onClick={e => {
										e.stopPropagation();
										onEditClick(4, modifierOption);
									}}
									className='flex flex-row justify-between items-center gap-x-2'
								>
									<Tag
										// style={{ minWidth: 200, maxWidth: 550, width: '100%' }}
										colorPreset={
											modifierOption.available ? 'purple' : 'lightgrey'
										}
									>
										<div className='flex justify-between'>
											{/* <span>{modifierOption.id}</span> */}
											<span>
												{modifierOption.internalName
													? `**${modifierOption.internalName}`
													: modifierOption.name}
											</span>
											<span className='pl-2'>{modifierOption.price}€</span>
										</div>
									</Tag>
								</div>
							),
							label: modifierOption.internalName
								? `**${modifierOption.internalName}`
								: modifierOption.name,
							key: `modifierOption-${category.id}-${item.id}-${modifier.id}-${modifierOption.id}`,
						})),
					})),
				})),
		}));

	return (
		<TreeCompo
			data={treeData}
			// open={open}
			setOpen={setOpen}
			selectedID={selectedID}
			// setData={setTreeData}
			depth={depth}
			// setDepth={setDepth}
			// addData={addData}
			// setAddData={setAddData}
			refetch={refetch}
			openedMobileReorderNode={openedMobileReorderNode}
			closeMobileReorderNode={() => {
				setOpenedMobileReorderNode(null);
			}}
			openCategoriesMobileReorder={() => {
				setOpenedMobileReorderNode({
					type: 'categories',
					item: treeData,
				});
			}}
		/>
	);
};

export default TreeHOC;
