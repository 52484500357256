import React from 'react';
import { Input } from 'antd';

const ItemCategoryForm = ({ item, setItem }) => {
	return (
		<Input
			autoFocus
			value={item?.name}
			onChange={({ target: { value } }) => {
				setItem({
					...item,
					name: value,
				});
			}}
			placeholder=''
		/>
	);
};

export default ItemCategoryForm;
