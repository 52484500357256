import React, { useEffect, useRef, useState } from 'react';
import CuisineTable from '../../components/cuisines/CuisineView';
import { ReorderModalCuisines } from '../../components/cuisines/ReorderModalCuisines';
import { Button } from '@/components/UI/Button/index.jsx';
import { toast } from '@/utils/toast';
import { useMainStore } from '@/store/main.store';
import { postGlobal, putGlobal } from '../../utils/http';
import { useKeyQuery } from '@/utils/hooks/useKeyQuery';

const initialState = { name: '', files: [] };

const CuisinesHOC = () => {
	const { user } = useMainStore();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(90);
	const [totalPages, setTotalPages] = useState(0);
	const [item, setItem] = useState(initialState);
	const [open, setOpen] = useState(false);

	const [openedMobileReorderNode, setOpenedMobileReorderNode] = useState(false);

	const savingRef = useRef(false);

	const {
		data: { data: cuisines } = {},
		isError,
		isPending,
		invalidateKeyQueries,
	} = useKeyQuery({
		key: 'cuisines',
		global: true,
	});

	useEffect(() => {
		if (open && !item?.id) {
			setItem(initialState);
		}
	}, [open]);

	const onFinish = () => {
		if (savingRef.current) {
			return;
		}

		if (!item?.name) {
			toast.error('Το όνομα είναι κενό');
			return;
		}

		const action = item?.id ? putGlobal : postGlobal;
		const url = item.id ? `/cuisines/${item.id}` : `/cuisines`;

		const hideLoading = () => (savingRef.current = false);

		toast.promise(
			async () => {
				savingRef.current = true;
				await action(url, {
					...item,
					language: user.language,
					files: Array.isArray(item.files) ? item.files.map(el => el.id) : [],
				});
				setOpen(!open);
				setItem({});
				invalidateKeyQueries();

				hideLoading();
			},
			{ errorCb: hideLoading },
		);
	};

	const onOpenNew = () => {
		setItem(initialState);
		setOpen(true);
	};

	return (
		<>
			{openedMobileReorderNode && (
				<ReorderModalCuisines
					isOpen={openedMobileReorderNode}
					// openedMobileReorderNode={openedMobileReorderNode}
					onOpenChange={setOpenedMobileReorderNode}
					// close={() => setOpenedMobileReorderNode(false)}
					data={cuisines}
					// onSuccessCb={() => {
					// 	queryClient.invalidateQueries({ queryKey: ['cuisines'] });
					// }}
					onSuccessCb={invalidateKeyQueries}
				/>
			)}

			<CuisineTable
				data={cuisines}
				open={open}
				setOpen={setOpen}
				item={item}
				setItem={setItem}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				pageSize={pageSize}
				setPageSize={setPageSize}
				totalPages={totalPages}
				onFinish={onFinish}
				onOpenNew={onOpenNew}
				isError={isError}
				isLoading={isPending}
				extraButtons={
					<Button variant='outline' onClick={() => setOpenedMobileReorderNode(true)}>
						Αλλαγή σειράς
					</Button>
				}
			/>
		</>
	);
};

export default CuisinesHOC;
