import { useQueries } from '@tanstack/react-query';
import ItemCategoriesHOC from '@/containers/shopOwner/shop/itemCategories/ItemCategoriesHOC';
import ItemsHOC from '@/containers/shopOwner/shop/items/ItemsHOC';
import ModifiersOptionsHOC from '@/containers/shopOwner/shop/modifierOptions/ModifierOptionsHOC';
import ModifiersHOC from '@/containers/shopOwner/shop/modifiers/ModifiersHOC';
import TreeHOC from '@/containers/tree/TreeHOC';
import { get } from '@/utils/http';
import { useMainStore } from '@/store/main.store';
import { ResponsiveTabs } from '../UI/responsive-tabs';

const STALE_TIME = 60 * 1000;

const ENTITIES = [
	{ value: 'preview', label: 'Προβολή', children: <TreeHOC /> },
	{ value: 'item-categories', label: 'Κατηγορίες', children: <ItemCategoriesHOC /> },
	{ value: 'items', label: 'Προϊόντα', children: <ItemsHOC /> },
	{ value: 'modifiers', label: 'Γκρουπ Υλικών', children: <ModifiersHOC /> },
	{ value: 'modifier-options', label: 'Υλικά', children: <ModifiersOptionsHOC /> },
];

const pageSizeParam = '?pageSize=169';

export const ShopOwnerProductMenu = () => {
	// const [selectedMenuKey, setSelectedMenuKey] = useState(ENTITIES[0].key);
	const { ownerTabShop, setOwnerTabShop } = useMainStore();
	// setOwnerTabShop(ENTITIES[0].key);

	// TODO table dimensions: 533 - 752
	// useEffect(() => {
	// 	if (!ownerTabShop || ownerTabShop === '/') {
	// 		setOwnerTabShop(ENTITIES[0].key);
	// 	}
	// }, []);

	useQueries({
		queries: [
			{
				queryKey: [ENTITIES[1].value],
				queryFn: () => get(`/${ENTITIES[1].value}${pageSizeParam}`),
				staleTime: STALE_TIME,
			},
			{
				queryKey: [ENTITIES[2].value],
				queryFn: () => get(`/${ENTITIES[2].value}${pageSizeParam}`),
				staleTime: STALE_TIME,
			},
			{
				queryKey: [ENTITIES[3].value],
				queryFn: () => get(`/${ENTITIES[3].value}${pageSizeParam}`),
				staleTime: STALE_TIME,
			},
			{
				queryKey: [ENTITIES[4].value],
				queryFn: () => get(`/${ENTITIES[4].value}${pageSizeParam}`),
				staleTime: STALE_TIME,
			},
		],
	});

	return (
		<div className='flex flex-col'>
			{/* <div className='flex flex-row items-center gap-x-6 mb-3'>
				{ENTITIES.map(ent => (
					<div
						key={ent.key}
						onClick={() => setOwnerTabShop(ent.key)}
						className='py-1 text-blue-400 cursor-pointer'
						style={ent.key === ownerTabShop ? { color: 'red' } : undefined}
					>
						{ent.label}
					</div>
				))}
			</div>
			{ENTITIES.find(ent => ent.key === ownerTabShop)?.component} */}
			<ResponsiveTabs
				// defaultActiveKey={adminTabShop}
				selectedKey={ownerTabShop}
				onChange={key => {
					setOwnerTabShop(key);
				}}
				tabList={ENTITIES}
			/>
		</div>
	);
};
