import * as React from 'react';
import {
	getSortedRowModel,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/UI/table';
import { Input } from 'antd';
import { getSearchFilteredDataArray } from '@/utils/filterFunctions';
import { deleteGlobal } from '@/utils/http';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from '@/utils/toast';
import { Button } from '@/components/UI/Button';

export function SuperTable({ columns, data: initialData, onRowClick, searchKeys, deletePath }) {
	// console.log('aaaa', initialData);
	const [sorting, setSorting] = React.useState([]);
	// const [columnFilters, setColumnFilters] = React.useState([]);
	const [searchText, setSearchText] = React.useState('');

	// const [data, setData] = React.useState(initialData);
	const [rowSelection, setRowSelection] = React.useState({});

	const filteredData = React.useMemo(
		() =>
			!searchKeys?.length
				? initialData
				: getSearchFilteredDataArray(initialData, searchKeys, searchText),
		[initialData, searchText],
	);
	// const filteredData = getSearchFilteredData(data, 'name', searchText);

	// console.log(columns);
	// React.useEffect(() => {
	// 	setData(initialData);
	// }, [initialData]);

	const handleRowClick = React.useCallback(
		row => {
			if (onRowClick) onRowClick(row);
		},
		[onRowClick],
	);

	const table = useReactTable({
		data: filteredData,
		columns,
		getCoreRowModel: getCoreRowModel(),
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		// onColumnFiltersChange: setColumnFilters,
		// getFilteredRowModel: getFilteredRowModel(),
		onRowSelectionChange: deletePath && setRowSelection,
		state: {
			sorting,
			// columnFilters,
			rowSelection,
		},
	});

	const queryClient = useQueryClient();

	const savingRef = React.useRef(false);

	// const table = React.useMemo(
	// 	() =>
	// 		useReactTable({
	// 			data: filteredData,
	// 			columns,
	// 			getCoreRowModel: getCoreRowModel(),
	// 			onSortingChange: setSorting,
	// 			getSortedRowModel: getSortedRowModel(),
	// 			state: {
	// 				sorting,
	// 			},
	// 		}),
	// 	[filteredData, columns, sorting],
	// );

	const onDelete = async () => {
		try {
			// const deleteFunction = deleteGlobal; // modalFlag ? del : deleteGlobal;

			// const keys = Object.keys(rowSelection)
			// 	.map(key => (rowSelection[key] ? key : undefined))
			// 	.filter(it => !!it);
			const keys = table.getFilteredSelectedRowModel().rows.map(it => it.original.id);
			// return;
			// console.log(table.getFilteredSelectedRowModel().rows);

			// console.log(
			// 	https://back.sliceapp.gr/api/v1.0/shops/92/modifier-options?id=217
			// 	https://back-dev.sliceapp.gr/api/v1.0/shops/6/modifier-options?id=13
			// );

			await deleteGlobal(deletePath, keys, 'id');

			const hideLoading = () => (savingRef.current = false);

			toast.promise(
				async () => {
					savingRef.current = true;
					await deleteGlobal(deletePath, keys, 'id');

					queryClient.invalidateQueries([deletePath]);

					hideLoading();
				},
				{ errorCb: hideLoading },
			);

			toast.success();
			// rowSelection.length > 1
			// 	? `IDs : ${rowSelection}` + ' διαγράφτηκαν'
			// 	: `ID : ${rowSelection}` + ' διαγράφτηκε',
		} catch (err) {
			toast.error(err);

			console.log('supertable delete err', err);
		}
	};

	return (
		<div>
			<div className='h-14 flex flex-row items-center mb-3 pr-1'>
				<div className='flex flex-1 items-center'>
					{searchKeys?.length > 0 && (
						<Input
							placeholder='Αναζήτηση...'
							// value={table.getColumn('name')?.getFilterValue() ?? ''}
							value={searchText}
							// onChange={event => table.getColumn('name')?.setFilterValue(event.target.value)}
							onChange={event => setSearchText(event.target.value)}
							className='max-w-sm'
						/>
					)}
				</div>

				<div className='flex flex-1 flex-row justify-center items-center'>
					{deletePath && (
						<>
							{table.getFilteredSelectedRowModel().rows.length > 0 && (
								<div className='flex-1 text-sm text-muted-foreground'>
									{table.getFilteredSelectedRowModel().rows.length} από{' '}
									{table.getFilteredRowModel().rows.length}{' '}
									{/* {table.getFilteredSelectedRowModel().rows.length === 1
								? 'επιλεγμένο'
								: 'επιλεγμένα'} */}
								</div>
							)}
							{table.getFilteredSelectedRowModel().rows.length > 0 && (
								<Button onClick={onDelete} variant='destructive'>
									Διαγραφή
								</Button>
							)}
						</>
					)}
				</div>
			</div>
			<div className='rounded-md border'>
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map(headerGroup => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map(header => {
									return (
										<TableHead key={header.id}>
											{header.isPlaceholder
												? null
												: flexRender(
														header.column.columnDef.header,
														header.getContext(),
													)}
										</TableHead>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map(row => (
								<TableRow
									key={row.id}
									data-state={row.getIsSelected() && 'selected'}
									onClick={() => handleRowClick(row)}
									className='bg-green-100 hover:bg-emerald-300 '
									style={{ cursor: onRowClick ? 'pointer' : 'default' }}
								>
									{row.getVisibleCells().map(cell => (
										<TableCell key={cell.id}>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext(),
											)}
										</TableCell>
									))}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={columns.length} className='h-24 text-center'>
									Δεν βρέθηκαν αποτελέσματα
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
		</div>
	);
}
