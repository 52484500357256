import { useEffect, useState } from 'react';
import { useWindowSize } from '@/utils/hooks/useWindowSize';
import { navigationRoutes } from '@/components/UI/navigation/navigationRoutes';
import { BottomNavigation } from '@/components/UI/navigation/bottom-navigation';
import { useLocation, useNavigate } from 'react-router-dom';
import { LogOut } from 'lucide-react';
import { RoutesNames } from '@/router/RouteNames';
import { useMainStore } from '@/store/main.store';
import { logout } from '../logout';
import LogoImg from '@/assets/LogoSmall';
import { MyTabs } from '@/components/UI/MyTabs';

export const useCustomNavigation = () => {
	const { width } = useWindowSize();
	const { user } = useMainStore();
	const navigate = useNavigate();

	const location = useLocation();

	const [current, setCurrent] = useState(() => `/${location?.pathname?.split('/')[1]}`);

	useEffect(() => {
		setCurrent(`/${location?.pathname?.split('/')[1]}`);
	}, [location]);

	const handleChangeMenuItem = e => {
		navigate(e.key);
		setCurrent(e.key);
	};

	useEffect(() => {
		if (!user?.token || current !== '/') {
			return;
		}

		// console.log('continue --');
		handleChangeMenuItem({ key: RoutesNames[user.role === 0 ? 'SHOPS' : 'LIVE_ORDERS'] });
	}, [user, current]);

	const onItemPress = e => {
		// console.log('onitpress', e);
		const routeFound = navigationRoutes[isAdmin ? 'admin' : 'shopOwner'].find(
			it => it.key === (e.key || e),
		);
		// console.log(navigationRoutes[isAdmin ? 'admin' : 'shopOwner'], routeFound);
		window.scrollTo({ top: 0, behavior: 'smooth' });
		handleChangeMenuItem(routeFound);
	};

	const onLogout = () => {
		logout(1);
		// navigate('/');
		// setUser(null);
		// setCurrent('/');
		// queryClient.removeQueries();
	};

	const showBottomNavigationBar = width < 740;
	const showLabels = width >= 680;

	const type = !user?.token ? null : showBottomNavigationBar ? 'bottom-navigation' : 'menu';
	const isAdmin = user?.role === 0;

	let foo = null;
	let bar = 'Give me a beer!';

	foo ??= bar;

	if (type === 'bottom-navigation') {
		return {
			type,
			element: (
				<BottomNavigation
					items={navigationRoutes[isAdmin ? 'admin' : 'shopOwner']}
					selectedKey={current}
					showLabels={showLabels}
					onClick={onItemPress}
					onLogout={onLogout}
				/>
			),
		};
	}
	if (type === 'menu') {
		return {
			type,
			element: (
				<div
					className='fixed top-0 z-10 w-full h-[50px] flex justify-between items-center pl-6  bg-white border-b'
					// style={{ width: '100%', position: 'fixed', top: 0, zIndex: 10 }}
				>
					<LogoImg />

					<MyTabs
						defaultActiveKey={current}
						currentValue={current}
						onChange={e => onItemPress(e)}
						cnTabs='w-full !bg-transparent'
						cnTabsList='bg-transparent ml-5'
						cnTabsTrigger=' '
						cnTabsTriggerSelected='!bg-green-100'
						tabList={navigationRoutes[isAdmin ? 'admin' : 'shopOwner'].map(it => ({
							label: it.label,
							value: it.key,
						}))}
					/>

					<LogOut
						color='rgb(90,90,90)'
						className='cursor-pointer p-3  bg-no'
						size={50}
						onClick={onLogout}
					/>
				</div>
			),
		};
	}
	return {
		type: null,
		element: null,
	};
};
