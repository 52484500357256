import React from 'react';
import { Input, Typography } from 'antd';
import { MyDialog } from '@/components/UI/MyDialog';
import { NewCouponsTable } from '@/components/tables/NewCouponsTable';
import { useMainStore } from '@/store/main.store';
const { Text } = Typography;

const CouponsTable = ({
	data,
	setPageNumber,
	pageSize,
	setPageSize,
	totalPages,
	item,
	setItem,
	onFinish,
	onOpenNew,
	open,
	setOpen,
	onActiveChange,
	isError,
	isLoading,
}) => {
	const { user } = useMainStore();
	return (
		<div className='flex flex-col'>
			<NewCouponsTable
				data={data}
				onActiveChange={onActiveChange}
				onOpenNew={user?.role === 0 && onOpenNew}
				isError={isError}
				isLoading={isLoading}
			/>

			<MyDialog
				open={open}
				title={item?.id ? 'Επεξεργασία Κουπονιού' : 'Προσθήκη Κουπονιού'}
				onOpenChange={setOpen}
				onSubmit={onFinish}
			>
				<div className='flex flex-col'>
					<Text>Ελάχιστη Παραγγελία</Text>
					<Input
						value={item?.minimumOrder}
						onChange={({ target: { value } }) => {
							setItem({
								...item,
								minimumOrder: value,
							});
						}}
						style={{
							marginRight: 12,
							marginBottom: 12,
						}}
					/>
				</div>

				<div className='flex flex-col'>
					<Text>Έκπτωση</Text>
					<Input
						autoFocus
						value={item?.discount}
						onChange={({ target: { value } }) => {
							setItem({
								...item,
								discount: value,
							});
						}}
						style={{
							marginRight: 12,
							marginBottom: 12,
						}}
					/>
				</div>
			</MyDialog>
		</div>
	);
};

export default CouponsTable;
