export const findMaxOrder = items => {
	if (!items) {
		return;
	}
	if (!items?.length) {
		return 1;
	}
	let max = items[0]?.order;

	for (let i = 1; i < items.length; i++) {
		if (items[i].order > max) {
			max = items[i].order;
		}
	}

	return max ?? 1;
};
