import React from 'react';
import { Input } from 'antd';
import Map from './Map';

const Address = ({ shopData, setShopData }) => {
	return (
		<div className='flex flex-col'>
			<div className='flex flex-row flex-wrap  gap-x-2 gap-y-1'>
				<div className='flex flex-col gap-y-1'>
					<h4>Οδός</h4>
					<Input
						value={shopData?.address?.street}
						style={{ marginRight: 6, width: '15rem' }}
						onChange={({ target: { value } }) => {
							setShopData({
								...shopData,
								areas: [],
								address: {
									...shopData.address,
									street: value,
								},
							});
						}}
					/>
				</div>
				<div className='flex flex-col gap-y-1'>
					<h4>Αριθμός</h4>
					<Input
						style={{ marginRight: 6, width: '6rem' }}
						value={shopData?.address?.streetNumber}
						onChange={({ target: { value } }) => {
							setShopData({
								...shopData,
								areas: [],
								address: {
									...shopData.address,
									streetNumber: value,
								},
							});
						}}
					/>
				</div>

				<div className='flex flex-col gap-y-1'>
					<h4>Περιοχή</h4>
					<Input
						style={{ marginRight: 6, width: '11rem' }}
						value={shopData?.address?.area}
						onChange={({ target: { value } }) => {
							setShopData({
								...shopData,
								areas: [],
								address: {
									...shopData.address,
									area: value,
								},
							});
						}}
					/>
				</div>
			</div>

			<h3 className='mt-3'>
				Κάντε κλικ στον χάρτη για να επιλέξετε την τοποθεσία του καταστήματος
			</h3>
			<h3 className='mb-6'>
				Επειτα ζωγραφίστε ένα πολύγωνο για να καθορίσετε την περιοχή παράδοσης
			</h3>

			<Map
				data={shopData}
				// data={{
				// 	...shopData,
				// 	areas: !shopData.areas
				// 		? shopData.areas
				// 		: shopData.areas.map?.(a => ({
				// 				...a,
				// 				// minimumOrder: a.minimumOrder / 100,
				// 			})),
				// }}
				setData={setShopData}
			/>
		</div>
	);
};

export default Address;
