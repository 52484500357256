import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { X } from 'lucide-react';
import Info from './components/Info';
import ItemCategoriesHOC from '../../../containers/shopOwner/shop/itemCategories/ItemCategoriesHOC';
import ItemsHOC from '../../../containers/shopOwner/shop/items/ItemsHOC';
import ModifiersHOC from '../../../containers/shopOwner/shop/modifiers/ModifiersHOC';
import ModifiersOptionsHOC from '../../../containers/shopOwner/shop/modifierOptions/ModifierOptionsHOC';
import CouponsHOC from '../../../containers/shopOwner/shop/coupons/CouponsHOC';
import { getRandomColor } from '@/utils/getRandomColor';
import { useMainStore } from '@/store/main.store';
import HistoryOrders from '../HistoryOrders';
import { ResponsiveTabs } from '@/components/UI/responsive-tabs';
import { useKeyQuery } from '@/utils/hooks/useKeyQuery';

const initialState = {
	user: {},
	address: {},
	name: '',
	phone: '',
	cuisines: [],
	files: [],
	comment: '',
	schedule: [
		['08:00', '22:00'],
		['08:00', '22:00'],
		['08:00', '22:00'],
		['08:00', '22:00'],
		['08:00', '22:00'],
		['08:00', '22:00'],
		['08:00', '22:00'],
	],
};

export const AdminShopOverview = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	// const { user } = useSelector(state => state);
	const {
		selectedShopID,
		setSelectedShopID,
		prevSelectedShopID,
		setPrevSelectedShopID,
		adminTabShop,
		setAdminTabShop,
	} = useMainStore();
	// const dispatch = useDispatch();

	const [shopData, setShopData] = useState(() => initialState);

	const isNew = window.location.pathname.split('/')[2] === 'add';

	const [loadingInit, setLoadingInit] = useState(!isNew);
	const queryClient = useQueryClient();

	useEffect(() => {
		if (id) {
			// setUser({ ...user, selectedShopID: parseInt(id) });
			setSelectedShopID(parseInt(id));
		}
		if (isNew) {
			return;
		}

		// getInitialData();

		return () => {
			setAdminTabShop('1');
		};
	}, []);

	useEffect(() => {
		if (selectedShopID && selectedShopID !== prevSelectedShopID) {
			const queriesToRemove = [
				[''], // shop data
				['item-categories'],
				['items'],
				['modifiers'],
				['modifier-options'],
				['coupons'],
				['history-orders'],
			];
			queriesToRemove.forEach(key =>
				queryClient.removeQueries({ queryKey: key, exact: true }),
			);
		}
	}, [selectedShopID]);

	useEffect(() => {
		return () => {
			setPrevSelectedShopID(useMainStore.getState().selectedShopID);
			setSelectedShopID(null);
		};
	}, []);

	const {
		data: shopDataFetched = {},
		isError,
		isPending,
		invalidateKeyQueries,
	} = useKeyQuery({
		key: `shops/${id}`,
		url: '/',
		global: false,
		enabled: !isNew,
	});

	useEffect(() => {
		if (!shopDataFetched?.id) {
			return;
		}

		setShopData({
			...shopDataFetched,
			schedule: shopDataFetched.schedule.map(item =>
				item.length === 0 ? ['0000', '0000'] : item,
			),
			scheduleOpen: shopDataFetched.schedule.map(item => item.length !== 0),
			areas: shopDataFetched.areas.map(item => ({
				...item,
				minimumOrder: !item.minimumOrder ? item.minimumOrder : item.minimumOrder / 100,
				deliveryCost: !item.deliveryCost ? item.deliveryCost : item.deliveryCost / 100,
				fillColor: getRandomColor(),
			})),
		});
		setLoadingInit(false);
	}, [shopDataFetched]);
	// const getInitialData = async () => {
	// 	try {
	// 		const d1 = new Date();
	// 		console.log('date before get shop data', d1.getSeconds(), d1.getMilliseconds());
	// 		const res = await get('');
	// 		const d2 = new Date();
	// 		console.log('date THEN get shop data', d2.getSeconds(), d2.getMilliseconds());

	// 		if (!res?.data) {
	// 			throw Error('-');
	// 		}
	// 		setShopData({
	// 			...res.data,
	// 			schedule: res.data.schedule.map(item =>
	// 				item.length === 0 ? ['0000', '0000'] : item,
	// 			),
	// 			scheduleOpen: res.data.schedule.map(item => item.length !== 0),
	// 			areas: res.data.areas.map(item => ({
	// 				...item,
	// 				minimumOrder: !item.minimumOrder ? item.minimumOrder : item.minimumOrder / 100,
	// 				deliveryCost: !item.deliveryCost ? item.deliveryCost : item.deliveryCost / 100,
	// 				fillColor: getRandomColor(),
	// 			})),
	// 		});
	// 		setLoadingInit(false);
	// 	} catch (err) {
	// 		const d3 = new Date();

	// 		console.log('date CATCH get shop data', d3.getSeconds(), d3.getMilliseconds());

	// 		console.log('effect shopdata err', err);
	// 		toast.error(err);
	// 	}
	// };

	const tabItems =
		window.location.pathname.split('/')[2] === 'add'
			? [
					{
						value: '1',
						label: 'Πληροφορίες',
						children: <Info />,
					},
				]
			: [
					{
						value: '1',
						label: 'Πληροφορίες',
						children: !loadingInit && <Info existingShopData={shopData} />,
					},
					{
						value: '3',
						label: 'Κατηγορίες',
						children: <ItemCategoriesHOC />,
					},
					{
						value: '4',
						label: 'Προϊόντα',
						children: <ItemsHOC />,
					},
					{
						value: '5',
						label: 'Γκρουπ Υλικών',
						children: <ModifiersHOC />,
					},
					{
						value: '6',
						label: 'Υλικά',
						children: <ModifiersOptionsHOC />,
					},
					{
						value: '7',
						label: 'Κουπόνια',
						children: <CouponsHOC />,
					},
					{
						value: '8',
						label: 'Ιστορικό',
						children: <HistoryOrders />,
					},
				];

	return (
		<>
			<div className='flex flex-row items-center h-12'>
				{shopData.name && (
					<div
						onClick={() => {
							navigate('/shops');
							// setSelectedShopID(null);
						}}
						// className='cursor-pointer flex flex-row items-center  gap-x-1 border-1 rounded-sm border-transparent hover:border-[oklch(70.83%_0.1877_13.07)] transition-colors duration-100 p-2'
						className='group cursor-pointer flex flex-row items-center p-2 '
					>
						<h1 className='text-[black] font-semibold text-[1.6rem]'>
							{shopData.name}
						</h1>
						<div className='p-2 pt-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200'>
							<X size={28} color='oklch(70.83% 0.1877 13.07)' />
						</div>
					</div>
				)}
			</div>
			<ResponsiveTabs
				// defaultActiveKey={adminTabShop}
				selectedKey={adminTabShop}
				onChange={key => {
					setAdminTabShop(key);
				}}
				tabList={tabItems}
			/>
		</>
	);
};
