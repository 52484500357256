import { Annoyed } from 'lucide-react';
import { Spinner } from '../UI/Spinner';

export const TableLoadingError = ({ isError, isLoading, children }) => {
	if (isError) {
		return (
			<div className='flex flex-col justify-center items-center mt-14'>
				<Annoyed size={52} color='#ff5533' />
				<div className='mt-4 text-xl'>Χμμμ, κάτι δεν πήγε καλά</div>
			</div>
		);
	}
	if (isLoading) {
		return <Spinner full />;
	}
	return children;
};
