import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import React, { useState } from 'react';
import { postGlobal } from '@/utils/http';
import { Button } from '../Button/index.jsx';
import { toast } from '@/utils/toast';
import { Image } from '@unpic/react';

const UploadImage = ({ parentData, setParentData, array }) => {
	const [loading, setLoading] = useState(false);

	const beforeUpload = async (file, type) => {
		setLoading(true);
		const formData = new FormData();
		formData.append('file', file);
		formData.append('type', type);

		try {
			const data = await postGlobal('/files', formData);
			if (!data?.data?.id) {
				return;
			}
			toast.success(`${file.name} file uploaded successfully`);
			setLoading(false);
			const obj = {
				id: data.data.id,
				name: data.data.name,
				type,
			};
			setParentData({
				...parentData,
				files: parentData.files?.length > 0 ? [...parentData.files, obj] : [obj],
			});
		} catch (err) {
			console.log('upload img err', err);
			toast.error(err);
		} finally {
			setLoading(false);
		}

		return false;
	};

	return (
		<div className='flex justify-center items-center' style={{ width: '100%' }}>
			{!loading ? (
				<div className='flex justify-center items-center'>
					{array.map((type, index) => (
						<div className='flex ' key={type}>
							{parentData?.files?.find(el => el.type === type)?.name ? (
								<div className='flex flex-row'>
									<div
										className='flex justify-between items-center'
										style={{ maxWidth: '90%' }}
									>
										<DeleteOutlined
											style={{ fontSize: 18 }}
											onClick={() => {
												setParentData({
													...parentData,
													files: parentData.files.filter(
														el => el.type !== type,
													),
												});
											}}
										/>
									</div>
									<Image
										// key={index}
										src={`${import.meta.env.VITE_SPACE_URL}${
											parentData.files.find(el => el.type === type).name
										}`}
										className='max-h-14'
										// layout='fixed'
										objectFit='contain'
										// width={200}
										// height={100}
										aspectRatio={1}
										alt='shop'
										style={{
											// width: '20svw',
											objectFit: 'cover',
											objectPosition: 'center',
											marginRight: 12,
										}}
									/>
								</div>
							) : (
								<div className='flex flex-col' style={{ marginRight: 8 }}>
									<Upload
										name='file'
										beforeUpload={file => beforeUpload(file, type)}
										showUploadList={false}
										progress={{ strokeWidth: 2 }}
									>
										<Button type='primary'>
											<span style={{ marginRight: 8 }}>{type}</span>
											<UploadOutlined />
										</Button>
									</Upload>
								</div>
							)}
						</div>
					))}
				</div>
			) : (
				<div className='my-spinner' />
			)}
		</div>
	);
};

export default UploadImage;
