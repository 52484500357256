import { Button } from '@/components/UI/Button/index.jsx';
import { Clock, MapPinHouse, MessageSquareText, User, Wallet } from 'lucide-react';

const Order = ({ order, onComplete }) => {
	const { id, createdAt, user, address, price, items, paymentType, comment, deliveryCost } =
		order || {};
	// TODO THELO CLIENT STOIXEIA
	// TODO THELO CLIENT STOIXEIA
	// TODO THELO CLIENT STOIXEIA
	// TODO THELO CLIENT STOIXEIA
	// den usaro:
	// estimatedMinutes

	const date = new Date(createdAt);
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const formattedTime = `${hours}:${minutes}`;

	// console.log('order:', order?.id, order);

	return (
		<div className='flex flex-col p-2 justify-between gap-y-1 border border-color-[#e8e8e8] rounded-xl bg-amber-500 mb-3'>
			<div className='opacity-30'>#{id}</div>

			<div className='flex flex-row gap-x-2'>
				<Clock size={22} />
				<div>{formattedTime}</div>
			</div>

			<div className='flex flex-row gap-x-2'>
				<MapPinHouse size={22} />
				<div>
					{address?.street} {address?.streetNumber}
				</div>
			</div>

			<div className='flex flex-row gap-x-2'>
				<User size={24} />
				<div>
					{user?.firstName} {user?.lastName}
				</div>
			</div>

			<div className='flex flex-row gap-x-2 mb-2'>
				<Wallet size={22} />
				<div>{paymentType === 0 ? 'Μετρητά' : 'Κάρτα'}</div>
				<div>
					{`${price / 100}`}
					<span className='text-xs'>€</span> + {deliveryCost / 100 || 0}
					{deliveryCost ? <span className='text-xs'>€</span> : 0}
				</div>
			</div>

			<div className='flex flex-col gap-y-2 mb-2'>
				{items?.map?.((item, index) => (
					<div key={index} className='flex flex-col  text-xl'>
						{item.quantity}x {item.name}
						{item.modifiers?.map(mod => (
							<div key={mod.id} className='flex flex-col gap-y-1'>
								<div className='text-xl'>{mod.name}</div>
								<div className='flex flex-col gap-y-1'>
									{mod.options.map(opt => (
										<div key={opt.id} className='text-base'>
											{opt.name}
										</div>
									))}
								</div>
							</div>
						))}
					</div>
				))}
			</div>

			{comment && typeof comment === 'string' && comment.trim() && (
				<div className='flex flex-row gap-x-1'>
					<MessageSquareText size={24} />
					<div>{comment.trim()}</div>
				</div>
			)}

			{onComplete && (
				<Button className='self-start' onClick={() => onComplete(order.id)}>
					Ολοκλήρωση παραγγελίας
				</Button>
			)}
		</div>
	);
};

export default Order;
