import React, { useEffect, useRef, useState } from 'react';
import ModifierOptionsView from '../../../../components/store/shop/modifierOptions/ModifierOptionsView';
import { toast } from '@/utils/toast';
import { useMainStore } from '@/store/main.store';
import { post, put } from '@/utils/http';
import { useKeyQuery } from '@/utils/hooks/useKeyQuery';

const initialState = {
	name: '',
	internalName: '',
	available: false,
	price: 0,
};

const ModifiersOptionsHOC = ({
	hideTable,
	itemId,
	open: customOpen,
	setOpen: customSetOpen,
	onFinishSuccessCb,
}) => {
	const { user } = useMainStore();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(486);
	const [totalPages, setTotalPages] = useState(0);
	const [item, setItem] = useState(initialState);
	const [open, setOpen] = useState(false);

	const savingRef = useRef(false);

	// const queryClient = useQueryClient();

	// const {
	// 	isPending,
	// 	error,
	// 	data: dataModifierOptions,
	// } = useQuery({
	// 	queryKey: [QUERY_KEY],
	// 	queryFn: () =>
	// 		get('/modifier-options', {
	// 			pageNumber,
	// 			pageSize,
	// 			language: user.language,
	// 		}),
	// });

	// const { data: modifierOptions } = dataModifierOptions?.data || {};

	const {
		data: { data: modifierOptions } = {},
		isError,
		isPending,
		invalidateKeyQueries,
	} = useKeyQuery({
		key: 'modifier-options',
		global: false,
		pageSize,
	});

	useEffect(() => {
		if (itemId && modifierOptions) {
			const item = modifierOptions.find(it => it.id === itemId);
			if (!item) {
				return;
			}
			setItem({ ...item, price: item.price / 100 });
		}
	}, [itemId, modifierOptions]);

	useEffect(() => {
		if (open && !item?.id) {
			setItem(initialState);
		}
	}, [open]);

	// const onFinish = () => {
	// 	if (!item?.name) {
	// 		toast.error('Το όνομα είναι κενό');
	// 		return;
	// 	}
	// 	// if (!item?.price && item?.price != 0) {
	// 	// 	toast.error('Η τιμή είναι κενή');
	// 	// 	return;
	// 	// }

	// 	const p = Number(`${item.price}`?.replaceAll(',', '.'));
	// 	if (!isFinite(p)) {
	// 		toast.error('Η τιμή πρέπει να είναι νούμερο');
	// 		return;
	// 	}
	// 	const action = item?.id ? put : post;

	// 	const data = {
	// 		name: item.name,
	// 		price: Math.round(p * 100),
	// 		// additionalTax: item.additionalTax * 100 || 0,
	// 		available: !!item.available,
	// 		shop: user.shop,
	// 		language: user.language,
	// 	};
	// 	action(`/modifier-options/${item?.id || ''}`, data)
	// 		.then(response => {
	// 			setOpen(false);
	// 			setItem({});
	// 			toast.success('Success!');
	// 		})
	// 		.catch(err => {
	// 			toast.error(err?.response?.data?.message || 'Κάποιο πρόβλημα προέκυψε');
	// 			console.log(err);
	// 		});
	// };

	const onFinish = () => {
		if (savingRef.current) {
			return;
		}

		if (!item?.name) {
			toast.error('Το όνομα είναι κενό');
			return;
		}

		try {
			const p = Number(`${item.price}`?.replaceAll(',', '.'));
			if (!isFinite(p)) {
				toast.error('Η τιμή πρέπει να είναι νούμερο');
				return;
			}

			const hideLoading = () => (savingRef.current = false);

			toast.promise(
				async () => {
					savingRef.current = true;

					const data = {
						name: item.name,
						internalName: item.internalName,
						price: Math.round(p * 100),
						// additionalTax: item.additionalTax * 100 || 0,
						available: !!item.available,
						// shop: user.shop,
						language: user.language,
					};

					const action = item?.id ? put : post;
					const url = `/modifier-options/${item?.id || ''}`;

					await action(url, data);
					setOpen(false);
					setItem({ initialState });

					if (typeof onFinishSuccessCb === 'function') {
						onFinishSuccessCb();
					}

					// queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

					invalidateKeyQueries();

					hideLoading();
				},
				{ errorCb: hideLoading },
			);
		} catch (err) {
			console.log('modifier options err', err);
			toast.error(err);
		}
	};

	const onOpenNew = () => {
		setItem(initialState);
		setOpen(true);
	};

	return (
		<ModifierOptionsView
			hideTable={hideTable}
			data={
				Array.isArray(modifierOptions)
					? modifierOptions.map(it => ({ ...it, price: it.price / 100 }))
					: []
			}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
			item={item}
			setItem={setItem}
			onFinish={onFinish}
			onOpenNew={onOpenNew}
			open={customOpen || open}
			setOpen={customSetOpen || setOpen}
			isError={isError}
			isLoading={isPending}
		/>
	);
};

export default ModifiersOptionsHOC;
