/* eslint-disable react/display-name */
import { useState, forwardRef, useEffect } from 'react';
import { cva } from 'class-variance-authority';
import { CheckIcon, XCircle, ChevronDown, XIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Separator } from '@/components/UI/Separator';
import { Button } from '@/components/UI/Button';
import { Badge } from '@/components/UI/badge';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/UI/popover';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from '@/components/UI/command';
import { getSearchFilteredData } from '@/utils/filterFunctions';

/**
 * Variants for the multi-select component to handle different styles.
 * Uses class-variance-authority (cva) to define different styles based on "variant" prop.
 */
const multiSelectVariants = cva(
	'm-1 transition ease-out delay-0 hover:-translate-y-0.5 hover:scale-103 duration-100',
	{
		variants: {
			variant: {
				default: 'border-foreground/10 text-foreground bg-card hover:bg-card/80',
				secondary:
					'border-foreground/10 bg-secondary text-secondary-foreground hover:bg-secondary/80',
				destructive:
					'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
				inverted: 'inverted',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	},
);

export const MultiSelect = forwardRef(
	(
		{
			options,
			onValueChange,
			variant,
			defaultValue = [],
			placeholder = 'Επιλογές',
			animation = 0,
			maxCount = 3,
			modalPopover = false,
			asChild = false,
			className,
			...props
		},
		ref,
	) => {
		const [selectedValues, setSelectedValues] = useState(defaultValue);
		const [isPopoverOpen, setIsPopoverOpen] = useState(false);

		const [searchText, setSearchText] = useState('');

		useEffect(() => {
			if (!isPopoverOpen) {
				setSearchText('');
			}
		}, [isPopoverOpen]);

		const handleInputKeyDown = event => {
			setSearchText(event);
			if (event.key === 'Enter') {
				setIsPopoverOpen(true);
			} else if (event.key === 'Backspace' && !event.currentTarget.value) {
				const newSelectedValues = [...selectedValues];
				newSelectedValues.pop();
				setSelectedValues(newSelectedValues);
				onValueChange(newSelectedValues);
			}
		};

		const toggleOption = option => {
			const newSelectedValues = selectedValues.includes(option)
				? selectedValues.filter(value => value !== option)
				: [...selectedValues, option];
			setSelectedValues(newSelectedValues);
			onValueChange(newSelectedValues);
		};

		const handleClear = () => {
			setSelectedValues([]);
			onValueChange([]);
		};

		const handleTogglePopover = () => {
			setIsPopoverOpen(prev => !prev);
		};

		const clearExtraOptions = () => {
			const newSelectedValues = selectedValues.slice(0, maxCount);
			setSelectedValues(newSelectedValues);
			onValueChange(newSelectedValues);
		};

		// const toggleAll = () => {
		// 	if (selectedValues.length === options.length) {
		// 		handleClear();
		// 	} else {
		// 		const allValues = options.map(option => option.value);
		// 		setSelectedValues(allValues);
		// 		onValueChange(allValues);
		// 	}
		// };

		const filteredOptions = !searchText
			? options
			: getSearchFilteredData(options, 'label', searchText);

		return (
			<Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen} modal={modalPopover}>
				<PopoverTrigger asChild>
					<Button
						ref={ref}
						{...props}
						onClick={handleTogglePopover}
						className={cn(
							'cursor-pointer flex w-full p-1 rounded-md border min-h-10 h-auto items-center justify-between  hover:bg-green-100 [&_svg]:pointer-events-auto bg-white ',
							className,
						)}
					>
						{selectedValues.length > 0 ? (
							<div className='h-full min-h-9 max-h-15 flex justify-between items-center w-full'>
								<div className='flex flex-wrap items-center'>
									{selectedValues.slice(0, maxCount).map(value => {
										const option = options?.find(o => o.value === value);
										const IconComponent = option?.icon;
										return (
											<Badge
												key={value}
												className={cn(multiSelectVariants({ variant }))}
												style={{ animationDuration: `${animation}s` }}
											>
												{IconComponent && (
													<IconComponent className='h-4 w-4 mr-2' />
												)}
												{option?.label}
												<div>
													<XCircle
														className='ml-2 h-4 w-4 cursor-pointer'
														onClick={event => {
															event.stopPropagation();
															toggleOption(value);
														}}
													/>
												</div>
											</Badge>
										);
									})}
									{selectedValues.length > maxCount && (
										<Badge
											className={cn(
												'bg-transparent text-foreground border-foreground/1 hover:bg-transparent',
												multiSelectVariants({ variant }),
											)}
											style={{ animationDuration: `${animation}s` }}
										>
											<div className='text-black'>
												{`+ ${selectedValues.length - maxCount}`}
											</div>
											{/* <XCircle
												color='red'
												className='ml-2 h-4 w-4 cursor-pointer'
												onClick={event => {
													event.stopPropagation();
													clearExtraOptions();
												}}
											/> */}
										</Badge>
									)}
								</div>
								<div className='flex items-center justify-between'>
									<XIcon
										className='h-4 mx-2 cursor-pointer text-muted-foreground text-black'
										onClick={event => {
											console.log('closing');
											event.stopPropagation();
											handleClear();
										}}
									/>
									<Separator
										orientation='vertical'
										className='flex min-h-6 h-full'
									/>
									<ChevronDown
										color='black'
										className='h-4 mx-2 cursor-pointer text-muted-foreground'
									/>
								</div>
							</div>
						) : (
							<div className='h-full min-h-9 max-h-15 flex items-center justify-between w-full mx-auto '>
								<span className='text-sm text-muted-foreground mx-3 text-black'>
									{placeholder}
								</span>
								<ChevronDown
									color='black'
									className='h-4 cursor-pointer text-muted-foreground mx-2'
								/>
							</div>
						)}
					</Button>
				</PopoverTrigger>
				<PopoverContent
					className='w-auto p-0'
					align='start'
					onEscapeKeyDown={() => setIsPopoverOpen(false)}
				>
					<Command filter={v => filteredOptions.some(fit => fit.label === v)}>
						<CommandInput
							value={searchText}
							placeholder='Αναζήτηση...'
							onValueChange={handleInputKeyDown}
						/>
						<CommandList>
							<CommandEmpty>No results found.</CommandEmpty>
							<CommandGroup>
								{/* <CommandItem
									key='all'
									onSelect={toggleAll}
									className='cursor-pointer'
								>
									<div
										className={cn(
											'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
											selectedValues.length === options.length
												? 'bg-primary text-primary-foreground'
												: 'opacity-50 [&_svg]:invisible',
										)}
									>
										<CheckIcon color='black' className='h-4 w-4' />
									</div>
									<span>(Select All)</span>
								</CommandItem> */}
								{options.map(option => {
									const isSelected = selectedValues.includes(option.value);
									return (
										<CommandItem
											key={option.value}
											value={option.value}
											onSelect={() => toggleOption(option.value)}
											className={cn(
												'cursor-pointer my-1',
												'data-[selected=true]:bg-green-100 ',
												// '!hover:bg-amber-100',
												isSelected ? '!bg-green-300' : '',
											)}
										>
											<div
												className={cn(
													'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary bg',
													isSelected
														? 'bg-primary text-primary-foreground'
														: 'opacity-50 [&_svg]:invisible',
												)}
											>
												<CheckIcon className='h-4 w-4' />
											</div>
											{option.icon && (
												<option.icon className='mr-2 h-4 w-4 text-muted-foreground' />
											)}
											<span>{option.label}</span>
										</CommandItem>
									);
								})}
							</CommandGroup>
							<CommandSeparator />
							<CommandGroup>
								<div className='flex items-center justify-between'>
									{selectedValues.length > 0 && (
										<>
											<CommandItem
												onSelect={handleClear}
												className='flex-1 justify-center cursor-pointer'
											>
												Καθαρισμός
											</CommandItem>
											<Separator
												orientation='vertical'
												className='flex min-h-6 h-full'
											/>
										</>
									)}
									<CommandItem
										onSelect={() => setIsPopoverOpen(false)}
										className='flex-1 justify-center cursor-pointer max-w-full'
									>
										Κλείσιμο
									</CommandItem>
								</div>
							</CommandGroup>
						</CommandList>
					</Command>
				</PopoverContent>
			</Popover>
		);
	},
);
