export const APP_VERSION = 0.1;

export const ORDER_DECLINE_REASONS = {
	NO_REASON: {
		key: 0,
		label: 'Χωρίς λόγο',
	},
	WORK: {
		key: 1,
		label: 'Φόρτος εργασίας',
	},
	MISSING_PRODUCTS: {
		key: 2,
		label: 'Ελλειψη προϊόντων',
	},
};

export const ORDER_DECLINE_REASONS_OPTIONS = [
	{
		key: ORDER_DECLINE_REASONS.NO_REASON.key,
		label: ORDER_DECLINE_REASONS.NO_REASON.label,
	},
	{
		key: ORDER_DECLINE_REASONS.WORK.key,
		label: ORDER_DECLINE_REASONS.WORK.label,
	},
	{
		key: ORDER_DECLINE_REASONS.MISSING_PRODUCTS.key,
		label: ORDER_DECLINE_REASONS.MISSING_PRODUCTS.label,
	},
];

export const ORDER_DELIVERY_ESTIMATE_OPTIONS = [
	{
		label: '15',
		value: 15,
	},
	{
		label: '20',
		value: 20,
	},
	{
		label: '25',
		value: 25,
	},
	{
		label: '30',
		value: 30,
	},
	{
		label: '35',
		value: 35,
	},
	{
		label: '40',
		value: 40,
	},
	{
		label: '45',
		value: 45,
	},
	{
		label: '50',
		value: 50,
	},
];
