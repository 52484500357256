import { useEffect } from 'react';

export const useApplyPadding = ({ type }) => {
	const applyPadding = () => {
		if (type === 'login') {
			document.body.style.padding = '0';
		} else {
			if (window.matchMedia('(min-width: 740px)').matches) {
				document.body.style.paddingTop = '50px';
				document.body.style.paddingBottom = '';
			} else {
				document.body.style.paddingBottom = '64px';
				document.body.style.paddingTop = '';
			}
		}
	};

	useEffect(() => {
		applyPadding();
		window.addEventListener('resize', applyPadding);

		return () => {
			window.removeEventListener('resize', applyPadding);
		};
	}, [type]);

	return {};
};
