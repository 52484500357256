import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { storage } from '../utils/storage';

const initStateData = {
	user: null,
	// FOR ADMIN ONLY
	selectedShopID: null,
	prevSelectedShopID: null, // used to check if we enter the same shop as before and in the case don't clear queries
	// FOR REFRESH PAGE PURPOSES
	adminTabShop: '1',
	ownerTabShop: 'preview',
	isRefreshing: false,
	isLoggingOut: false,
};

const excludedKeys = [];

export const useMainStore = create(
	persist(
		(set, get) => ({
			...initStateData,

			setUser: data =>
				set(() => {
					return {
						user: data,
					};
				}),

			setSelectedShopID: data =>
				set(() => {
					return {
						selectedShopID: data,
					};
				}),

			setPrevSelectedShopID: data =>
				set(() => {
					return {
						prevSelectedShopID: data,
					};
				}),

			setAdminTabShop: data =>
				set(() => {
					return {
						adminTabShop: data,
					};
				}),

			setOwnerTabShop: data =>
				set(() => {
					return {
						ownerTabShop: data,
					};
				}),

			setIsRefreshing: data =>
				set(() => {
					return {
						isRefreshing: data,
					};
				}),

			setIsLoggingOut: data =>
				set(() => {
					return {
						isLoggingOut: data,
					};
				}),

			resetAll: () => {
				set(() => {
					const newState = {};
					Object.keys(initStateData).forEach(key => {
						newState[key] = initStateData[key];
					});

					return {
						...newState,
					};
				});
			},
		}),
		{
			name: 'main',
			// storage: createJSONStorage(() => localStorage),
			storage: createJSONStorage(() => storage),
			// storage: () => AsyncStorage,
			// partialize: state => ({ registrationInfo: state.registrationInfo }),
			partialize: state =>
				Object.fromEntries(
					Object.entries(state).filter(
						// list of keys to NOT persist
						([key]) => !excludedKeys.includes(key),
						// ['registrationInfo']
						// .includes(key),
					),
				),
		},
	),
);
