import React, { useState } from 'react';
import ShopsView from '../../components/shops/ShopView';
import { useKeyQuery } from '@/utils/hooks/useKeyQuery';

const ShopsHOC = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(181);

	const [totalPages, setTotalPages] = useState(0);

	// useEffect(() => {
	// 	getGlobal('/shops', { pageNumber, pageSize })
	// 		.then(response => {
	// 			if (response?.data?.data?.length > 0) {
	// 				dispatch(setShops(response.data.data));
	// 			}
	// 			setTotalPages(response?.data?.totalPages);
	// 		})
	// 		.catch(err => {
	// 			toast.error(err.response?.data.message);
	// 		});
	// }, [pageNumber, pageSize]);

	// const {
	// 	isPending,
	// 	error,
	// 	data: dataShops,
	// } = useQuery({
	// 	queryKey: [QUERY_KEY],
	// 	queryFn: () => {
	// 		return getGlobal('/shops', {
	// 			pageNumber,
	// 			pageSize,
	// 			// language: user.language,
	// 		});
	// 	},
	// });

	// const { data: shops } = dataShops?.data || {};

	const {
		data: { data: shops } = {},
		isError,
		isPending,
	} = useKeyQuery({
		key: 'shops',
		global: true,
	});

	return (
		<ShopsView
			data={shops}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
			isError={isError}
			isLoading={isPending}
		/>
	);
};

export default ShopsHOC;
