import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/UI/tabs';
import { cn } from '@/lib/utils';

export const MyTabs = ({
	defaultActiveKey,
	currentValue,
	cnTabs,
	cnTabsList,
	cnTabsTrigger,
	cnTabsTriggerSelected,
	onChange,
	tabList,
	withChildren,
	hideList,
}) => {
	return (
		<Tabs defaultValue={defaultActiveKey} onValueChange={e => onChange(e)} className={cnTabs}>
			{!hideList && (
				<TabsList className={cn('gap-x-2 ', cnTabsList)}>
					{tabList.map(({ value, label }) => (
						<TabsTrigger
							key={value}
							className={cn(
								cnTabsTrigger,
								currentValue === value && cnTabsTriggerSelected,
							)}
							value={value}
							onClick={() => onChange(value)} // to vazo giati allios an clickaro sto idio item den kanei tipota
						>
							{label}
						</TabsTrigger>
					))}
				</TabsList>
			)}
			{withChildren &&
				tabList.map(({ value, children }) => (
					<TabsContent key={value} value={value}>
						{children}
					</TabsContent>
				))}
		</Tabs>
	);
};
