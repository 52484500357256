// import { useEffect, useRef, useState } from 'react';
import { CircleCheck, TriangleAlert } from 'lucide-react';
import { useLiveOrders } from '../../../utils/hooks/useLiveOrders';
import { LiveOrderRequest } from './LiveOrderRequest';
// import { useSound } from '../../../utils/hooks/useSound';
import { SingleFullScreenOrder } from './SingleFullScreenOrder';
import { useWindowSize } from '@/utils/hooks/useWindowSize';
import { OnlineStatus } from '@/components/online-status';
// import boopSfx from '../../../assets/sounds/boop.mp3';

export const LiveOrdersRequests = () => {
	const {
		liveOrdersRequests,
		visibleIds,
		setVisibleIds,
		onRemoveOrderRequest,
		onAccept,
		onDecline,
		onOpenFullScreen,
		onSee,
	} = useLiveOrders();

	const { width, height } = useWindowSize();
	// const { play, stop, isPlayingRef } = useSound(boopSfx);

	// const [selectedOrder, setSelectedOrder] = useState(null);

	// useEffect(() => {
	// 	if (!selectedOrder) {
	// 		if (liveOrdersRequests?.length) {
	// 			setSelectedOrder(liveOrdersRequests[0]);
	// 			return;
	// 		}
	// 	} else {
	// 	}
	// }, [liveOrdersRequests]);

	// const onOpen = order => {
	// 	setSelectedOrder(order);
	// };

	// const onClose = (id, refOrders) => {
	// 	const orders = refOrders || liveOrdersRequests;
	// 	console.log('will close', orders?.length);
	// 	if (orders?.length === 1) {
	// 		setSelectedOrder(null);
	// 		return;
	// 	}
	// 	if (orders?.length > 1) {
	// 		const ord = orders.find(lo => lo.id !== id);
	// 		console.log('found ', ord || null);

	// 		setSelectedOrder(ord || null);
	// 	}
	// };

	// const dummyOrder = {
	// 	address: {
	// 		id: 95,
	// 		createdAt: '2025-01-08T20:45:55.943Z',
	// 		updatedAt: '2025-01-08T21:01:56.537Z',
	// 		area: 'Καστοριά',
	// 		createdAt: '2025-01-08T20:45:55.943Z',
	// 		doorName: 'Παπαδόπουλος',
	// 		floor: '2',
	// 		formattedAddress: 'Λεωφόρος Κύκνων, 521 00 Καστοριά, Ελλάδα',
	// 		lat: 40.51351,
	// 		lng: 21.25391,
	// 		nickname: '',
	// 		notes: '',
	// 		postalCode: '521 00',
	// 		street: 'Θουκυδίδου',
	// 		streetNumber: '2',
	// 	},
	// 	area: 'Καστοριά',
	// 	city: { id: 3 },
	// 	createdAt: '2025-01-08T20:45:55.943Z',
	// 	doorName: 'Παπαδόπουλος',
	// 	floor: '2',
	// 	formattedAddress: 'Λεωφόρος Κύκνων, 521 00 Καστοριά, Ελλάδα',
	// 	id: 95,
	// 	lat: 40.51351,
	// 	lng: 21.25391,
	// 	nickname: '',
	// 	notes: '',
	// 	postalCode: '521 00',
	// 	street: 'Θουκυδίδου',
	// 	streetNumber: '2',
	// 	updatedAt: '2025-01-08T21:01:56.537Z',
	// 	comment: '',
	// 	createdAt: '2025-03-20T11:56:45.417Z',
	// 	deliveryCost: 100,
	// 	id: 680,
	// 	items: [
	// 		{
	// 			available: true,
	// 			category: 38,
	// 			comment: '',
	// 			createdAt: '2025-01-07T11:32:57.638Z',
	// 			description:
	// 				'Με φιλέτο κοτόπουλο, βουτηγμένο σε σάλτσα BBQ, cheddar, ντομάτα & μαρούλι σε ψωμί με παπαρουνόσπορο. Συνοδεύεται από πατάτες τηγανητές\n',
	// 			id: 122,
	// 			modifiers: [],
	// 			name: 'The glam sandwich',
	// 			order: 0,
	// 			price: 50500,
	// 			quantity: 1,
	// 			shop: 8,
	// 			updatedAt: '2025-03-19T13:41:34.119Z',
	// 		},
	// 	],
	// 	orderIgnoredDate: '2025-03-20T11:57:25.417Z',
	// 	paymentType: 0,
	// 	price: 50500,
	// 	shop: { id: 8, address: 80, createdAt: '2025-01-05T16:09:43.796Z' },
	// 	status: 0,
	// 	tip: 0,
	// 	updatedAt: '2025-03-20T11:56:45.417Z',
	// 	user: 3,
	// };

	return (
		<>
			<div
				className='fixed left-0 right-0 h-[60px] flex flex-row gap-x-3 items-center px-3 overflow-x-auto bg-gray-800 '
				style={{ bottom: width < 740 ? 64 : 0 }}
			>
				<div className='flex flex-row gap-x-2 '>
					{liveOrdersRequests?.length > 0 ? (
						<>
							<TriangleAlert size={32} className='text-orange-400' />
							<div className='text-2xl text-amber-100'>
								{liveOrdersRequests.length}
							</div>
						</>
					) : (
						<CircleCheck className='text-green-400' />
					)}
				</div>

				<div className='flex-1 flex flex-row ml-5'>
					{/* <LiveOrderRequest order={{ id: 1534, price: 300 }} /> */}
					{liveOrdersRequests.map(lor => (
						<LiveOrderRequest
							key={lor.id}
							order={lor}
							// liveOrdersRequests={liveOrdersRequests}
							onRemoveOrderRequest={onRemoveOrderRequest}
							onOpenFullScreen={onOpenFullScreen}
						/>
					))}
				</div>
				<OnlineStatus />
			</div>

			{visibleIds?.length > 0 && (
				<SingleFullScreenOrder
					order={liveOrdersRequests.find(it => it.id === visibleIds[0])}
					onAccept={onAccept}
					onDecline={onDecline}
					onSee={onSee}
					onRemoveOrderRequest={onRemoveOrderRequest}
					// visibleIds={visibleIds}
					// liveOrdersRequests={liveOrdersRequests}
				/>
			)}
		</>
	);
};
