import { useEffect, useRef, useState } from 'react';
import { MyDialog } from '../UI/MyDialog';
import { toast } from '@/utils/toast';
import { patchGlobal } from '@/utils/http';
import SimpleSortableList from '../UI/sortable-list/SortableList';

export const ReorderModalCuisines = ({ isOpen, onOpenChange, onSuccessCb, data }) => {
	const [modifiedCopy, setModifiedCopy] = useState(data);

	const savingRef = useRef(false);

	useEffect(() => {
		if (isOpen) {
			setModifiedCopy(data);
		}
	}, [isOpen]);

	const onSaveReorder = async () => {
		if (savingRef.current) {
			return;
		}
		try {
			// console.log('modifiedCopy ', modifiedCopy);

			const itemsMapped = modifiedCopy.map(c => c.id);

			// await patchGlobal('/cuisines/reorder', {
			// 	cuisines: itemsMapped,
			// });

			// getTreeData();

			const hideLoading = () => (savingRef.current = false);

			toast.promise(
				async () => {
					savingRef.current = true;
					await patchGlobal('/cuisines/reorder', {
						cuisines: itemsMapped,
					});

					if (typeof onSuccessCb === 'function') {
						onSuccessCb();
					}
					hideLoading();

					onOpenChange(false);
				},
				{ errorCb: hideLoading },
			);
		} catch (err) {
			toast.error(err);
			console.log('onSaveReorder err', err);
		}
	};
	// console.log('original ', children);
	// console.log('modifiedCopy ', modifiedCopy);

	return (
		<MyDialog
			// open={isOpen}
			open
			onOpenChange={() => onOpenChange(false)} // this is ok
			title='Αλλαγή σειράς κουζινών'
			onSubmit={onSaveReorder}
		>
			{/* <Text>{item?.label}</Text> */}

			{/* styles={entity} */}
			<SimpleSortableList
				items={modifiedCopy}
				setItems={setModifiedCopy}
				imgType='main'
				// styles={entityProperties[type]?.styles || {}}
			/>
		</MyDialog>
	);
	// return (
	// 	<MyDialog
	// 		open={isOpen}
	// 		title='Αλλαγή σειράς κουζινών'
	// 		onOpenChange={onOpenChange}
	// 		onSubmit={onSaveReorder}
	// 	>
	// 		<div className='flex flex-col items-stretch pl-2 pr-2 mt-4'>
	// 			{modifiedCopy?.map?.((it, idx) => {
	// 				return (
	// 					<div
	// 						key={it.id}
	// 						style={{
	// 							height: 30,
	// 							display: 'flex',
	// 							flexDirection: 'row',
	// 							justifyContent: 'space-between',
	// 						}}
	// 					>
	// 						{idx !== 0 ? (
	// 							<UpOutlined onClick={() => moveTop(idx)} style={{ fontSize: 20 }} />
	// 						) : (
	// 							<div style={{ width: 20 }} />
	// 						)}
	// 						<Text style={{ flex: 1, textAlign: 'center' }}>
	// 							{it.name || it.label}
	// 						</Text>
	// 						{idx !== data.length - 1 ? (
	// 							<DownOutlined
	// 								onClick={() => moveBottom(idx)}
	// 								style={{ fontSize: 20 }}
	// 							/>
	// 						) : (
	// 							<div style={{ width: 20 }} />
	// 						)}
	// 					</div>
	// 				);
	// 			})}
	// 		</div>
	// 	</MyDialog>
	// );
};
