import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/UI/accordion';

export const MyAccordion = ({ className, trigger, children }) => {
	return (
		<Accordion type='single' collapsible className={className}>
			<AccordionItem value='item-1'>
				<AccordionTrigger className='cursor-pointer'>{trigger}</AccordionTrigger>
				<AccordionContent>{children}</AccordionContent>
			</AccordionItem>
		</Accordion>
	);
};
