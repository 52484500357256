import { generatePassword } from '@/utils/generateRandomPassword';
import { Form, Input } from 'antd';
import { useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import { Button } from '../UI/Button';

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
	required: 'Το ${label} είναι υποχρεωτικό',
	types: {
		email: 'Το email δεν είναι σωστό!',
	},
	number: {
		range: 'Το τηλέφωνο δεν είναι σωστό ή δεν έχει 10 ψηφία',
	},
};
/* eslint-enable no-template-curly-in-string */

const UserForm = ({ item, setItem, resendWelcomeEmailForPassword }) => {
	// const [form] = Form.useForm();

	useEffect(() => {
		if (item?.firstName) {
			return;
		}
		const password = generatePassword();
		setItem({ ...item, password });
	}, []);

	return (
		// <div className='flex flex-col justify-center items-stretch px-10 py-5 bg-white border-2 border-stone-400 rounded-md'>
		<Form
			name='User'
			// form={form}
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				alignItems: 'center',
			}}
			initialValues={{
				firstName: item?.firstName,
				lastName: item?.lastName,
				email: item?.email,
				phone: item?.phone,
			}}
			validateMessages={validateMessages}
		>
			<div className='w-full flex flex-col mt-3'>
				<span>Όνομα</span>
				<Input
					onChange={e => {
						setItem({
							...item,
							firstName: e.target.value,
						});
					}}
				/>
			</div>

			<div className='w-full flex flex-col mt-3'>
				<span>Επίθετο</span>
				<Input
					onChange={e => {
						setItem({
							...item,
							lastName: e.target.value,
						});
					}}
				/>
			</div>

			<div className='w-full flex flex-col mt-3'>
				<span>Email</span>
				<Input
					onChange={e => {
						setItem({
							...item,
							email: e.target.value,
						});
					}}
				/>
			</div>

			<div className='w-full flex flex-col mt-3'>
				<span>Τηλέφωνο</span>
				<PhoneInput
					defaultCountry='GR'
					onChange={value => {
						setItem({
							...item,
							phone: value,
						});
					}}
					style={{
						border: '1px solid #cecece',
						borderRadius: 6,
						padding: 6,
						height: 24,
					}}
				/>
			</div>

			<div className='w-full flex flex-col mt-3'>
				<span>Ρόλος: {`${item.role}`}</span>
			</div>

			{item?.id ? (
				<Button variant='outline' onClick={resendWelcomeEmailForPassword}>
					Αποστολή κωδικού
				</Button>
			) : (
				<div className='w-full flex flex-col mt-3'>
					<span>Κωδικός: {`${item.password}`}</span>
				</div>
			)}
		</Form>
		// </div>
	);
};
export default UserForm;
