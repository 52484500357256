import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesNames } from '../../router/RouteNames';
import { NewShopsTable } from '@/components/tables/NewShopsTable';

const ShopsView = ({
	data,
	pageNumber,
	setPageNumber,
	pageSize,
	setPageSize,
	totalPages,
	isError,
	isLoading,
}) => {
	const navigate = useNavigate();

	return (
		<div className='flex flex-col'>
			<NewShopsTable
				data={data}
				isError={isError}
				isLoading={isLoading}
				onOpenNew={() => {
					navigate(RoutesNames.ADD_SHOP);
				}}
				onRowClick={row => {
					navigate('/shop/' + row.original.id);
				}}
			/>
		</div>
	);
};

export default ShopsView;
