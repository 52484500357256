import { Routes } from 'react-router-dom';
import { useRouteStuff } from '@/utils/hooks/useRouteStuff';

const RoutesWrapper = ({ withLiveOrders, children }) => {
	const { style, dvh, innerHeight } = useRouteStuff({ type: 'logged-in' });

	return (
		<div style={{ ...style, paddingBottom: withLiveOrders ? 60 : 0 }}>
			{/* <div className='absolute top-8 left-2'>
				<span>{dvh}</span>-<span>{innerHeight}</span>
			</div> */}
			<div
				className='py-3 px-6 overflow-auto'
				style={{
					scrollbarGutter: 'stable',
				}}
			>
				<Routes>{children}</Routes>
			</div>
		</div>
	);
};

export default RoutesWrapper;
