export const tagColors = {
	blue: {
		color: '#0958d9',
		background: '#e6f4ff',
		borderColor: '#91caff',
	},

	green: {
		color: '#389e0d',
		background: '#f6ffed',
		borderColor: '#b7eb8f',
	},

	red: {
		color: '#cf1322',
		background: '#fff1f0',
		borderColor: '#ffa39e',
	},

	purple: {
		color: '#531dab',
		background: '#f9f0ff',
		borderColor: '#d3adf7',
	},

	lightgrey: {
		color: 'grey',
		background: 'rgb(232, 232, 232)',
		borderColor: 'rgb(200, 180, 219)',
	},
};
