import { SuperTable } from './SuperTable';
import { useMemo } from 'react';
import { TableLoadingError } from './TableLoadingError';
import { Checkbox } from '../UI/checkbox';
import { HeaderTable } from './HeaderTable';
import { useMainStore } from '@/store/main.store';

export const NewModifiersTable = ({ data, isError, isLoading, onOpenNew, onRowClick }) => {
	const { user } = useMainStore();

	const columns = useMemo(() => {
		return [
			{
				id: 'select',
				header: ({ table }) => (
					<div className='flex justify-center items-center '>
						<Checkbox
							checked={
								table.getIsAllPageRowsSelected() ||
								(table.getIsSomePageRowsSelected() && 'indeterminate')
							}
							onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
							aria-label='Select all'
						/>
					</div>
				),
				cell: ({ row }) => (
					<div
						onClick={e => e.stopPropagation()}
						className='flex justify-center items-center '
					>
						<Checkbox
							checked={row.getIsSelected()}
							onCheckedChange={value => row.toggleSelected(!!value)}
							aria-label='Select row'
						/>
					</div>
				),
				enableSorting: false,
				enableHiding: false,
			},
			{
				header: 'ID',
				accessorKey: 'id',
			},
			{
				header: 'Όνομα',
				cell: ({ row }) => (
					<div>
						{row.original.internalName
							? `**${row.original.internalName}`
							: row.original.name}
					</div>
				),
			},
		];
	}, []);

	return (
		<TableLoadingError isError={isError} isLoading={isLoading}>
			<HeaderTable onOpenNew={onOpenNew} title={user?.role !== 0 && 'Γκρουπ'} />

			<SuperTable
				data={data}
				columns={columns}
				searchKeys={['name']}
				deletePath='/modifiers'
				onRowClick={onRowClick}
			/>
		</TableLoadingError>
	);
};
