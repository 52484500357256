import React from 'react';
import { Form, Input } from 'antd';
import Logo from '../../assets/Logo';
import { Button } from '../UI/Button/index.jsx';

const LoginForm = ({ setLoginForm, loginForm, onFinish }) => {
	return (
		<div className='h-screen overflow-y-hidden flex flex-col justify-center items-center'>
			<div
				className='max-w-md h-[520px] m-auto self-center flex flex-col justify-center items-center'
				style={{ marginTop: '10svh' }}
			>
				<div className='animate-login-form-logo'>
					<Logo />
				</div>

				<Form
					name='basic'
					className='w-84 pb-20 animate-login-form opacity-0'
					initialValues={{ remember: true }}
					onFinish={onFinish}
					layout='vertical'
					autoComplete='on'
				>
					<div className='flex flex-col justify-center items-stretch px-10 py-5 bg-white border-2 border-stone-400 rounded-md'>
						<Form.Item
							label='Email'
							name='email'
							rules={[{ required: true, message: 'Παρακαλώ συμπληρώστε το email' }]}
						>
							<Input
								type='email'
								onChange={({ target: { value } }) =>
									setLoginForm({ ...loginForm, userName: value })
								}
							/>
						</Form.Item>

						<Form.Item
							label='Κωδικός'
							name='password'
							rules={[{ required: true, message: 'Παρακαλώ συμπληρώστε τον κωδικό' }]}
						>
							<Input.Password
								onChange={({ target: { value } }) =>
									setLoginForm({ ...loginForm, password: value })
								}
							/>
						</Form.Item>
					</div>

					<Form.Item className='w-full flex flex-row justify-center self-center'>
						<Button
							// style={{ width: 120, marginTop: 24 }}
							className='w-26 mt-5 self-center'
							type='primary'
							htmlType='submit'
						>
							Σύνδεση
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
};

export default LoginForm;
