import React, { useEffect, useRef, useState } from 'react';
import CouponView from '../../../../components/store/shop/Coupons/CouponView';
import { toast } from '@/utils/toast';
import { useMutation } from '@tanstack/react-query';
import { patch, post } from '@/utils/http';
import { useKeyQuery } from '@/utils/hooks/useKeyQuery';
import { queryClient } from '@/utils/query-client';

// const QUERY_KEY = 'coupons';

const initialState = {};

const CouponsHOC = () => {
	// const { user } = useSelector(state => state);

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [totalPages, setTotalPages] = useState(0);
	const [item, setItem] = useState(initialState);
	const [open, setOpen] = useState(false);

	const savingRef = useRef(false);

	// const queryClient = useQueryClient();

	// const {
	// 	isPending,
	// 	error,
	// 	data: dataCoupons,
	// } = useQuery({
	// 	queryKey: [QUERY_KEY],
	// 	queryFn: () => {
	// 		return get('/coupons', {
	// 			pageNumber,
	// 			pageSize,
	// 			language: user.language,
	// 		});
	// 	},
	// });

	// const { data: coupons } = dataCoupons?.data || {};

	const {
		data: { data: coupons } = {},
		isError,
		isPending,
		invalidateKeyQueries,
	} = useKeyQuery({
		key: 'coupons',
		global: false,
	});

	useEffect(() => {
		if (open && !item?.id) {
			setItem(initialState);
		}
	}, [open]);

	const onActiveChangeMutation = useMutation({
		mutationFn: id => {
			return patch(`/coupons/status?id=${id}`);
		},
		onSuccess: (updatedData, id) => {
			// TODO edo na check auto giati to invalidate mallon ta krivei gia ligo, eno sto cuisines oxi
			// queryClient.invalidateQueries({ queryKey: ['coupons'] });

			// return;
			queryClient.setQueryData(['coupons'], oldData => {
				const coupons = oldData?.data?.data;
				console.log(oldData);
				if (!coupons?.length) {
					return updatedData;
				}
				const newCoupons = [...coupons];
				const idx = newCoupons.findIndex(coupon => coupon.id === id);
				if (idx === -1) {
					return newCoupons;
				}
				newCoupons[idx].active = !newCoupons[idx].active;

				console.log(oldData.data, newCoupons);
				return {
					...oldData,
					data: {
						...oldData.data,
						data: newCoupons,
					},
				};
			});
		},
	});

	const onActiveChange = async id => {
		toast.promise(async () => await onActiveChangeMutation.mutateAsync(id));
	};

	// const onActiveChange = id => {
	// 	if (savingRef.current) {
	// 		return;
	// 	}
	// 	const hideLoading = () => (savingRef.current = false);

	// 	toast.promise(
	// 		async () => {
	// 			savingRef.current = true;

	// 			await patch(`/coupons/status?id=${id}`);
	// 			// await post(`/coupons`, {
	// 			// 	minimumOrder: Math.round(item.minimumOrder * 100),
	// 			// 	discount: Math.round(item.discount * 100),
	// 			// 	type: item.type ? 1 : 0,
	// 			// });

	// 			// setOpen(!open);
	// 			// setItem({});
	// 			queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
	// 			hideLoading();
	// 		},
	// 		{ errorCb: hideLoading },

	// 		// await onActiveChangeMutation.mutateAsync(id);
	// 	);
	// };

	const onFinish = () => {
		if (savingRef.current) {
			return;
		}
		const hideLoading = () => (savingRef.current = false);

		toast.promise(
			async () => {
				savingRef.current = true;

				await post(`/coupons`, {
					minimumOrder: Math.round(item.minimumOrder * 100),
					discount: Math.round(item.discount * 100),
					type: item.type ? 1 : 0,
				});

				setOpen(!open);
				setItem({});

				// queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

				invalidateKeyQueries();

				hideLoading();
			},
			{ errorCb: hideLoading },
		);
	};

	const onOpenNew = () => {
		setItem(initialState);
		setOpen(true);
	};

	return (
		<CouponView
			data={coupons?.map?.(it => ({
				...it,
				minimumOrder: it.minimumOrder / 100,
				discount: it.discount / 100,
			}))}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
			item={item}
			setItem={setItem}
			onFinish={onFinish}
			onOpenNew={onOpenNew}
			open={open}
			setOpen={setOpen}
			onActiveChange={onActiveChange}
			isError={isError}
			isLoading={isPending}
		/>
	);
};

export default CouponsHOC;
