import React, { useState } from 'react';
import Order from '../order/Order';
import { socketSingleton } from '../../../utils/hooks/useSockets';
import { toast } from '@/utils/toast';
import { Switch } from '@/components/UI/Switch';
import { useLiveStore } from '@/store/live.store';
import { patch } from '@/utils/http';

const LiveOrders = () => {
	// console.log('LiveOrders');

	// const { liveOrders } = useSelector(s => s);

	const { liveOrders, setLiveOrders, shopOpen, setShopOpen } = useLiveStore();

	// console.log('userddd', shopOpen);
	const [loading, setLoading] = useState(null);

	const toggle = async value => {
		// console.log('toogle', value);
		try {
			if (loading) {
				return;
			}
			setLoading(true);

			const res = await patch(`/${value ? 'open' : 'close'}`);
			if (res?.status === 200) {
				// console.log('will setShopOpen', value);
				// dispatch(setShopOpen(value));
				setShopOpen(value);
				socketSingleton?.[value ? 'connect' : 'disconnect']?.();
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const onRemoveOrderRequest = id => {
		const idx = liveOrders.findIndex(lor => lor.id === id);
		if (idx === -1) {
			return;
		}
		const newLiveOrders = [...liveOrders];
		newLiveOrders.splice(idx, 1);
		setLiveOrders(newLiveOrders);
	};

	const onComplete = async id => {
		try {
			await patch(`/orders/${id}/complete`);

			// console.log(response);
			onRemoveOrderRequest(id);

			// setOpen(!open);
			// setItem({});
			toast.success('Success!');
		} catch (err) {
			console.log('ERRORRRR', err);

			if (err.response?.data?.code === 404) {
				toast.error('Η παραγγελία δεν υπάρχει ή έχει απαντηθεί');
				onRemoveOrderRequest(id);
				return;
			}
			console.log('ERRORRRR onComplete', err);
			toast.error(err);
		}
	};
	// useSockets({ onAccept, onDecline });

	// useFocusPage(() => toast.info("Live Orders Page is focused"));

	return (
		<div className='flex flex-col gap-y-4 bg-[#f7f7f7] '>
			<div className='h-8 flex flex-row items-center gap-x-3'>
				<Switch checked={shopOpen} onCheckedChange={toggle} disabled={loading} />
				{!loading && (
					<div className='text-xl'>{`Το κατάστημα είναι ${shopOpen ? 'ανοιχτό' : 'κλειστο'}`}</div>
				)}
			</div>
			{liveOrders.map(lo => (
				<Order key={lo.id} order={lo} onComplete={onComplete} />
			))}
		</div>
	);
};

export default LiveOrders;
