import { removeSentryUser } from './sentry';
import { useMainStore } from '@/store/main.store';
import { useLiveStore } from '@/store/live.store';
import { queryClient } from './query-client';
import { postGlobal } from './http';

export const logout = reason => {
	console.log('--- LOGOUT REASON ----> ', reason);
	const { isLoggingOut, setIsLoggingOut } = useMainStore.getState();
	if (isLoggingOut) {
		removeLocalStuff();
		return;
	}
	setIsLoggingOut(true);
	try {
		postGlobal(
			'/auth/logout',
			{
				token: useMainStore.getState().user?.refreshToken,
			},
			undefined,
			true,
		).finally(() => {
			removeLocalStuff();
		});
	} catch (err) {
		console.log('logout API err: ', err);
	}
};

const removeLocalStuff = () => {
	try {
		removeSentryUser();
		useMainStore.getState().resetAll();
		useLiveStore.getState().resetAll();
		queryClient.removeQueries();
	} catch (err) {
		console.log('logout err: ', err);
	}
};
