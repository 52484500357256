import React, { useEffect, useState } from 'react';
import { Tree as TreeAntd } from 'antd';
import { ReorderModalTree } from './ReorderModalTree';
import { Button } from '../UI/Button/index.jsx';
import ModifiersHOC from '@/containers/shopOwner/shop/modifiers/ModifiersHOC';
import ItemCategoriesHOC from '@/containers/shopOwner/shop/itemCategories/ItemCategoriesHOC';
import ModifiersOptionsHOC from '@/containers/shopOwner/shop/modifierOptions/ModifierOptionsHOC';
import ItemsHOC from '@/containers/shopOwner/shop/items/ItemsHOC';
import { Spinner } from '../UI/Spinner';
// import { useMainStore } from '@/store/main.store';

const Tree = ({
	data,
	// open,
	setOpen,
	selectedID,
	depth,
	// setDepth,
	// addData,
	// setAddData,
	refetch,
	openedMobileReorderNode,
	closeMobileReorderNode,
	openCategoriesMobileReorder,
}) => {
	const [gData, setGData] = useState([]); // Local state to handle reordering
	const [expandedKeys, setExpandedKeys] = useState([]);
	const [autoExpandParent, setAutoExpandParent] = useState(true);

	useEffect(() => {
		setGData(data); // Sync local gData state with data from props
		setExpandedKeys(data.map(it => it.key));
	}, [data]);

	const onSelect = (selectedKeys, info) => {
		const key = info.node.key;
		const newExpandedKeys = expandedKeys.includes(key)
			? expandedKeys.filter(k => k !== key)
			: [...expandedKeys, key];

		setExpandedKeys(newExpandedKeys);
		setAutoExpandParent(false);
	};

	const onExpand = expandedKeys => {
		setExpandedKeys(expandedKeys);
		setAutoExpandParent(false);
	};

	const onFinishSuccessCb = () => {
		// setAddData({});
		setOpen(false);
		refetch();
	};

	// const onFinishLocal = async () => {
	// 	return;
	// 	try {
	// 		const apiMethod = selectedID ? put : patch;
	// 		const actionUrl = `${apiCall[depth]}/${selectedID || ''}`;

	// 		let dataPayload = { language: user.language };

	// 		switch (depth) {
	// 			case 2:
	// 				dataPayload = {
	// 					...dataPayload,
	// 					name: addData.name,
	// 					category: addData.category,
	// 					available: !!addData.available,
	// 					modifiers: addData?.modifiers?.[0]?.id
	// 						? addData?.modifiers?.map(modifier => modifier.id)
	// 						: addData?.modifiers || [],
	// 					description: addData.description || '',
	// 				};
	// 				break;
	// 			case 3:
	// 				dataPayload = {
	// 					...dataPayload,
	// 					name: addData.name,
	// 					type: !!addData.type ? 0 : 1,
	// 					required: !!addData.required,
	// 					options: addData.options?.[0].id
	// 						? addData.options.map(option => option.id)
	// 						: addData?.options || [],
	// 				};
	// 				break;
	// 			case 4:
	// 				dataPayload = {
	// 					...dataPayload,
	// 					name: addData.name,
	// 					available: addData.available || false,
	// 				};
	// 				break;
	// 		}

	// 		dataPayload.files = !addData.files ? [] : addData.files?.map(file => file.id);
	// 		// if (addData.files) {
	// 		// 	dataPayload.files = addData.files.map(x => x.id);
	// 		// }
	// 		// if (addData.price || addData.price == 0) {

	// 		if (depth === 2 || depth === 4) {
	// 			const p = Number(`${addData.price}`?.replaceAll(',', '.'));
	// 			if (!isFinite(p)) {
	// 				toast.error('Η τιμή πρέπει να είναι νούμερο');
	// 				return;
	// 			}
	// 			dataPayload.price = Math.round(p * 100); // (addData.price || 0) * 100;
	// 		}

	// 		// itan me dispatch(
	// 		apiMethod(actionUrl, dataPayload)
	// 			.then(response => {
	// 				setAddData({});
	// 				setOpen(false);
	// 				refresh();
	// 			})
	// 			.catch(err => {
	// 				toast.error(err?.response?.data?.message || 'Κάποιο πρόβλημα προέκυψε');
	// 				console.log(err);
	// 			});
	// 	} catch (err) {
	// 		toast.error(err?.response?.data?.message || 'Κάποιο πρόβλημα προέκυψε');
	// 		console.log(err);
	// 	}
	// };

	// const onDragEnter = info => {
	// 	// console.log('onDragEnter', info);
	// };

	// const findNodeByKey = (nodes, key, parent = null) => {
	// 	for (let i = 0; i < nodes.length; i++) {
	// 		if (nodes[i].key === key) {
	// 			return { node: nodes[i], index: i, parent };
	// 		}
	// 		if (nodes[i].children) {
	// 			const found = findNodeByKey(nodes[i].children, key, nodes[i]);
	// 			if (found) return found;
	// 		}
	// 	}
	// 	return null;
	// };

	// const onDrop = info => {
	// 	const dropKey = info.node.key;
	// 	const dragKey = info.dragNode.key;
	// 	const dropPositionRelative = info.dropPosition - Number(info.node.pos.split('-').pop());

	// 	// Helper to find node by key, including its index and parent

	// 	const dataClone = [...gData];

	// 	const {
	// 		node: dragNode,
	// 		index: dragIndex,
	// 		parent: dragParent,
	// 	} = findNodeByKey(dataClone, dragKey) || {};
	// 	const {
	// 		node: dropNode,
	// 		index: dropIndex,
	// 		parent: dropParent,
	// 	} = findNodeByKey(dataClone, dropKey) || {};

	// 	// console.log(dragParent, dropParent, dragNode, dropNode);
	// 	console.log(dragParent?.key, dropParent?.key);
	// 	console.log(dragNode?.key, dropNode?.key);
	// 	// Only reorder if nodes have the same parent
	// 	if (dragParent !== dropParent || !dragNode || !dropNode) {
	// 		toast.error(translations.toast.error.itemMove);
	// 		return;
	// 	}

	// 	// Remove dragNode from its original position
	// 	const siblings = dragParent ? dragParent.children : dataClone;
	// 	siblings.splice(dragIndex, 1);

	// 	// Insert dragNode at the new position within the same level
	// 	const newIndex = dropPositionRelative === -1 ? dropIndex : dropIndex + 1;
	// 	siblings.splice(newIndex, 0, dragNode);

	// 	setDraggedKeys([...draggedKeys, dragParent?.key || 'categories']); // an den exei value tote exei kounisei category
	// 	setGData(dataClone); // Update local gData state
	// };

	// const onSaveDrag = async () => {
	// 	try {
	// 		// console.log(gData);
	// 		// console.log('draggged', draggedKeys);
	// 		const ma = new Map(draggedKeys.map(item => [item, true]));
	// 		const arr = Array.from(ma.keys());

	// 		const toSendData = { items: [], modifiers: [], modifierOptions: [] };

	// 		const shouldSendCategories = arr.find(it => it === 'categories');

	// 		arr.forEach(dk => {
	// 			const split = dk.split('-');

	// 			if (dk.startsWith('category')) {
	// 				console.log('dkkkk', dk);

	// 				const cat = gData.find(cat => cat.key === `category-${split[1]}`);
	// 				// const item = cat.children.find(item => item.key === `item-${split[1]}-${split[2]}`);

	// 				// const modifier = item.children.find(
	// 				// 	mod => mod.key === `modifier-${split[1]}-${split[2]}-${split[3]}`,
	// 				// );
	// 				// if (!modifier.children?.length) {
	// 				// 	return;
	// 				// }
	// 				toSendData.items.push({
	// 					items: cat.children.map(c => {
	// 						const split = c.key.split('-');
	// 						return Number(split[split.length - 1]);
	// 					}),
	// 					categoryId: Number(split[split.length - 1]),
	// 				});
	// 			} else if (dk.startsWith('item')) {
	// 				const cat = gData.find(cat => cat.key === `category-${split[1]}`);
	// 				const item = cat.children.find(
	// 					item => item.key === `item-${split[1]}-${split[2]}`,
	// 				);

	// 				// const modifier = item.children.find(
	// 				// 	mod => mod.key === `modifier-${split[1]}-${split[2]}-${split[3]}`,
	// 				// );
	// 				// if (!modifier.children?.length) {
	// 				// 	return;
	// 				// }
	// 				toSendData.modifiers.push({
	// 					modifiers: item.children.map(mod => {
	// 						const split = mod.key.split('-');
	// 						return Number(split[split.length - 1]);
	// 					}),
	// 					itemId: Number(split[split.length - 1]),
	// 				});
	// 			} else if (dk.startsWith('modifier')) {
	// 				// EXO PEIRAKSEI OPTION DILADI
	// 				const cat = gData.find(cat => cat.key === `category-${split[1]}`);
	// 				const item = cat.children.find(
	// 					item => item.key === `item-${split[1]}-${split[2]}`,
	// 				);

	// 				const modifier = item.children.find(
	// 					mod => mod.key === `modifier-${split[1]}-${split[2]}-${split[3]}`,
	// 				);
	// 				if (!modifier.children?.length) {
	// 					return;
	// 				}
	// 				toSendData.modifierOptions.push({
	// 					options: modifier.children.map(mo => {
	// 						const split = mo.key.split('-');
	// 						return Number(split[split.length - 1]);
	// 					}),
	// 					modifierId: Number(split[split.length - 1]),
	// 				});
	// 			}
	// 		});

	// 		console.log('toSendData', toSendData);

	// 		const promises = [];

	// 		if (shouldSendCategories) {
	// 			const itemCategories = gData.map(it => {
	// 				const split = it.key.split('-');
	// 				return Number(split[split.length - 1]);
	// 			});
	// 			// toSendData.items.push({
	// 			// 	items: cat.children.map(c => {
	// 			// 		const split = c.key.split('-');
	// 			// 		return Number(split[split.length - 1]);
	// 			// 	}),
	// 			// 	categoryId: Number(split[split.length - 1]),
	// 			// });
	// 			promises.push(
	// 				async () => await patch('/item-categories/reorder', { itemCategories }),
	// 			);
	// 		}

	// 		for (let i = 0; i < toSendData.items.length; i++) {
	// 			promises.push(async () => await patch('/items/reorder', toSendData.items[i]));
	// 		}
	// 		for (let i = 0; i < toSendData.modifiers.length; i++) {
	// 			promises.push(
	// 				async () => await patch('/modifiers/reorder', toSendData.modifiers[i]),
	// 			);
	// 		}
	// 		for (let i = 0; i < toSendData.modifierOptions.length; i++) {
	// 			promises.push(
	// 				async () =>
	// 					await patch('/modifier-options/reorder', toSendData.modifierOptions[i]),
	// 			);
	// 		}

	// 		// const itemCategories = gData.map(it => {
	// 		// 	const split = it.key.split('-');
	// 		// 	return Number(split[split.length - 1]);
	// 		// });
	// 		// console.log(itemCategories);
	// 		// const action = await patch('/item-categories/order', { itemCategories });

	// 		// console.log('gamo');
	// 		setDragAllowed(false);
	// 		await Promise.all(promises.map(p => p()));
	// 		toast.success('Επιτυχία!');
	// 		// const apiCall = selectedID ? apiCallEdit[depth] : apiCallADD[depth];
	// 		// const dataPayload = {
	// 		// 	...addData,
	// 		// 	...(addData.files && { files: addData.files.map(x => x.id) }),
	// 		// 	...(addData.price && { price: addData.price * 100 }),
	// 		// 	...(addData.additionalTax >= 0 && {
	// 		// 		additionalTax: addData.additionalTax * 100,
	// 		// 	}),
	// 		// 	language: user.language,
	// 		// };

	// 		// dispatch(apiMethod(patch, dataPayload))
	// 		// 	.then(response => {
	// 		// 		setAddData({});
	// 		// 		setOpen(false);
	// 		// 	})
	// 		// 	.catch(error => {
	// 		// 		toast.error(error.response.data.message);
	// 		// 	});
	// 	} catch (err) {
	// 		toast.success('Κάποιο πρόβλημα προέκυψε');

	// 		console.log('err onSaveDrag', err);
	// 	}
	// };

	const modalComponent = {
		1: (
			<ItemCategoriesHOC
				hideTable
				itemId={selectedID}
				setOpen={setOpen}
				onFinishSuccessCb={onFinishSuccessCb}
			/>
		),
		2: (
			<ItemsHOC
				hideTable
				itemId={selectedID}
				setOpen={setOpen}
				onFinishSuccessCb={onFinishSuccessCb}
			/>
		),
		3: (
			<ModifiersHOC
				hideTable
				itemId={selectedID}
				setOpen={setOpen}
				onFinishSuccessCb={onFinishSuccessCb}
			/>
		),
		4: (
			<ModifiersOptionsHOC
				hideTable
				itemId={selectedID}
				setOpen={setOpen}
				onFinishSuccessCb={onFinishSuccessCb}
			/>
		),
	};

	return (
		<div className='flex flex-col'>
			{/* <div className='flex flex-col justify-center items-center' style={{ width: '100%' }}>
				<Text>Νέα Προσθήκη</Text>
				<MyNewSelect
					onSelect={value => {
						console.log(value);
						setDepth(value);
						setOpen(true);
					}}
					// value={loadingItemCategories ? null : item?.category}
					// value={depth}
					options={[
						{ value: 1, label: 'Κατηγορία Προϊόντος' },
						{ value: 2, label: 'Προϊόν' },
						{ value: 3, label: 'Γκρουπ Υλικών' },
						{ value: 4, label: 'Υλικό' },
					]}
				/>
			</div> */}

			<Button
				variant='outline'
				className='self-start mt-2 mb-5'
				onClick={openCategoriesMobileReorder}
			>
				Αλλαγή σειράς κατηγοριών
			</Button>

			{/* <div className='h-1/2 max-h-1/5'> */}
			<div className=' max-w-[95vw] overflow-x-hidden h-full max-h-[calc(100dvh-250px)] overflow-y-auto -translate-x-5 sm:-translate-x-0 '>
				{gData?.length ? (
					<TreeAntd
						treeData={gData}
						onSelect={onSelect}
						onExpand={onExpand}
						expandedKeys={expandedKeys}
						autoExpandParent={autoExpandParent}
						// onDragEnter={onDragEnter}
						// onDrop={onDrop}
						// draggable={dragAllowed}
						showLine
						style={{ background: 'transparent' }}
					/>
				) : (
					<div className='flex flex-col justify-center items-center translate-x-5 sm:-translate-x-0'>
						{/* <div className='my-spinner' /> */}
						<Spinner />
					</div>
				)}
			</div>
			{/* </div> */}

			{openedMobileReorderNode && (
				<ReorderModalTree
					openedMobileReorderNode={openedMobileReorderNode}
					refetch={refetch}
					close={closeMobileReorderNode}
				/>
			)}

			{modalComponent[depth]}
		</div>
	);
};

export default Tree;
